import { FormikErrors } from "formik";
import { FC } from "react";
import { FormLabel } from "./FormLabel";
import classNames from "classnames";
import { inputClassName } from "./FormText";

interface FormTextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  id: string;
  name: string;
  label?: string;
  isRequired?: boolean;
  labelClassname?: string;
  containerClassname?: string;
  labelContainerClassname?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  ref?: React.Ref<HTMLInputElement>;
}
export const FormTextArea: FC<FormTextAreaProps> = ({
  label = "",
  isRequired,
  name,
  type,
  error,
  className,
  labelClassname,
  containerClassname,
  labelContainerClassname,
  disabled,
  ref,
  style,
  ...props
}) => {
  return (
    <div className={containerClassname}>
      <FormLabel
        label={label}
        htmlFor={name}
        isRequired={isRequired}
        className={labelClassname}
        containerClassname={labelContainerClassname}
      />

      <div className="flex flex-1 flex-col">
        <div className="relative flex flex-1 items-center">
          <textarea
            {...props}
            name={name}
            disabled={disabled}
            className={classNames(
              inputClassName,
              "h-auto",
              !!error && "ring-red-500",
              disabled && "bg-gray-100 text-gray-500",
              className
            )}
            style={{ minHeight: "2.5rem", ...style }}
          />
        </div>

        {typeof error == "string" && !!error && (
          <span className="mt-2 text-sm text-red-500">{error}</span>
        )}
        {Array.isArray(error) && (
          <span className="mt-2 text-sm text-red-500">{error.join(", ")}</span>
        )}
      </div>
    </div>
  );
};
