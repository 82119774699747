import { FC } from "react";
import { useNavigate } from "react-router-dom";
import {  ChevronLeftIcon } from "@heroicons/react/24/outline";

interface PageTitleProps {
  title: string;
  /**
   * If true, the page will show a arrow back button
   */
  back?: boolean;
  onReload?: () => void;
}
export const PageTitle: FC<PageTitleProps> = ({ title, back, onReload }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex flex-1 items-center gap-2">
        {back && (
          <div
            onClick={() => navigate(-1)}
            className="cursor-pointer bg-blue-100 rounded-full p-1.5 hover:bg-blue-200"
          >
            <ChevronLeftIcon className="w-6 h-6 text-main-500" />
          </div>
        )}

        <h1 className="text-xl text-main-500 font-bold">{title}</h1>

        {/* {!!onReload && (
          <div className="px-2 cursor-pointer">
            <ArrowPathIcon className="w-6 h-6 text-main-500" />
          </div>
        )} */}
      </div>

      <hr className="border-gray-300 my-3" />
    </div>
  );
};

export const PageTitleMin: FC<PageTitleProps> = ({ title, back, onReload }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex flex-1 items-center gap-2">
        {back && (
          <div
            onClick={() => navigate(-1)}
            className="cursor-pointer bg-blue-100 rounded-full p-1.5 hover:bg-blue-200"
          >
            <ChevronLeftIcon className="w-6 h-6 text-main-500" />
          </div>
        )}

        <h1 className="text-sm text-main-500 font-bold">{title}</h1>

      </div>

      <hr className="border-gray-300 my-3" />
    </div>
  );
};
