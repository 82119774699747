import axios from "axios";
import { ListResponse } from "../interfaces/Shipment/ListResponseDto";
import { UserPasswordUpdateRequestDto } from "../interfaces/Account/UserPasswordUpdateRequestDto";

const {
  REACT_APP_URL_PATH_API,
  REACT_APP_URL_USER_TOKEN,
  REACT_APP_URL_PASSWORD_TOKEN,
} = process.env;

export const AuthenticationServices = async (): Promise<string | undefined> => {
  try {
    const response = await axios.post(
      `${REACT_APP_URL_PATH_API}/Account/Login`,
      {
        Login: `${REACT_APP_URL_USER_TOKEN}`,
        Password: `${REACT_APP_URL_PASSWORD_TOKEN}`,
      }
    );
    if (response.status === 200) {
      var datamap = response.data.token;
      return datamap;
    } else {
      console.log("Error: ", response.status);
    }
  } catch (error) {
    console.log("Error: ", error);
  }

  return "";
};

export const forgotPassword = async (
  email: string,
  appCode: string
): Promise<ListResponse<null>> => {
  const body = JSON.stringify({
    Login: email,
  });
  const uri = `${REACT_APP_URL_PATH_API}/Users/ResetPasswordUsers?user=${email}&applicationCode=${appCode}`;

  try {
    const response = await axios.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    }
    return {
      model: null,
      didError: true,
      message: `Server status: ${response.status}`,
      errorMessage: "Server error",
    };
  } catch (error) {
    return {
      model: null,
      didError: true,
      message: `Error: ${error}`,
      errorMessage: "Application error",
    };
  }
};

export const changePassword = async (
  request: UserPasswordUpdateRequestDto
  // token: string
): Promise<ListResponse<null>> => {
  const body = JSON.stringify(request);
  const uri = `${REACT_APP_URL_PATH_API}/Users/UpdatePassword`;

  try {
    const response = await axios.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    }
    return {
      model: null,
      didError: true,
      message: `Server status: ${response.status}`,
      errorMessage: "Server error",
    };
  } catch (error) {
    return {
      model: null,
      didError: true,
      message: `Error: ${error}`,
      errorMessage: "Application error",
    };
  }
};
