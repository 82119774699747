import axios from "axios";
import { SimpleResponse } from "../interfaces/Shipment/ListResponseDto";
import { businessUnitMapper, mapBUDtoToBUInterface } from "../utils/mappers";
import {
  Nullable,
  BusinessUnitDTO,
  BusinessUnitInterface,
} from "../interfaces";
import { AuthenticationServices } from "./AuthenticationServices";

const { REACT_APP_URL_PATH_API } = process.env;

export const getBusinessUnits = async (): Promise<
  Nullable<BusinessUnitInterface[]>
> => {
  const uri = `${REACT_APP_URL_PATH_API}/BusinessUnit/BusinessUnitV2?status=1&IsNational=false&excludeOperationLocked=true`;
  try {
    const response = await axios.get(uri);
    if (response.status === 200) {
      const storeDto = response.data;
      var datamap = storeDto?.map((dto: BusinessUnitDTO) =>
        businessUnitMapper(dto)
      );
      return datamap;
    }
  } catch (error) {}
  return null;
};

export const getBusinessUnitsPolly = async (): Promise<
  Nullable<BusinessUnitInterface[]>
> => {
  const uri = `${REACT_APP_URL_PATH_API}/BusinessUnit/BusinessUnitV2?status=1&IsNational=false&withPolygonsList=true&excludeOperationLocked=true`;
  try {
    const response = await axios.get(uri);
    if (response.status === 200) {
      const storeDto = response.data;
      var datamap = storeDto?.map((dto: BusinessUnitDTO) =>
        businessUnitMapper(dto)
      );
      return datamap.filter((bu: BusinessUnitInterface) => bu.buCode != "8110");
    }
  } catch (error) {}

  return null;
};

export const getBusinessUnitByTerritory = async (
  longitude: number,
  latitude: number
): Promise<SimpleResponse<BusinessUnitInterface>> => {
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

  const uri = `${REACT_APP_URL_PATH_API}/BusinessUnit/GetBusinessUnitByTerritory?longitude=${longitude}&latitude=${latitude}`;

  try {
    const response = await axios.get(uri);

    if (response.status === 200) {
      const storeDto = response.data.model;
      if (storeDto != null) {
        response.data.model = mapBUDtoToBUInterface(storeDto);
      }
      return response.data;
    }
  } catch (error) {}

  return {
    model: null,
    didError: true,
    message: "No se pudo obtener la tienda de cobertura del punto especificado",
    errorMessage:
      "No se pudo obtener la tienda de cobertura del punto especificado",
  };
};
