import { FC, useState, useEffect } from 'react';
import { XMarkIcon } from "@heroicons/react/24/outline";
import { PreShipmentInterface } from "../../interfaces";
import { PrimaryButton } from "../Atoms/Buttons";
import { ModalWidthFull } from "../Atoms/ModalWidthFull";
import { DowloadShipments } from '../../services/ShipmentServices';

interface PshipmentPrintModalProps {
  open: boolean;
  shipment: PreShipmentInterface;
  onPrint: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ShipmentPrintModal: FC<PshipmentPrintModalProps> = ({
  open,
  shipment,
  onPrint,
  setOpen,
}) => {
   const [pdfUrl, setPdfUrl] = useState("");

  const fetchShipments =  () => {
    if((shipment.shipmentNumber !== undefined && shipment.shipmentNumber !== "" && shipment.shipmentNumber !== null) || (shipment.number !== undefined && shipment.number !== 0 && shipment.number !== null) ) {
      DowloadShipments(shipment.shipmentNumber??(shipment.number.toString() ?? ""))
      .then((response) => response.blob())
      .then((blob) => {              
            const file = window.URL.createObjectURL(blob);
            setPdfUrl(file);
      });
    }   
  };

  useEffect(() => {
    fetchShipments()
  },[])

  return (
    <ModalWidthFull
      open={open}
      setOpen={() => {}}
      className="flex flex-1 mx-4 my-8"
      style={{ maxWidth: "90rem", height: "50rem"}}
    >
      <div className="flex flex-1 flex-col">
        {/* Header */}
        <div className="flex flex-1 items-center justify-between">
          <h2 className="text-xl font-bold">
            <span className="text-gray-700 hidden sm:inline">
              Impresión de la Guía:{" "}
            </span>
            <span className="text-main-500">
              {shipment.shipmentNumber}
            </span>
          </h2>

          <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-main-500"
                onClick={() => setOpen(false)}
              >

              <span className="absolute -inset-2.5" />
              <span className="sr-only">Close panel</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>

        <hr className="border-gray-300 my-3" />


        {/* Shipment Data */}
        <embed src={pdfUrl} type="application/pdf"  width="100%" height="100%" />
        <hr className="border-gray-300 my-6" />

        {/* Footer */}
        <div className="flex flex-1 justify-between items-center">
          <PrimaryButton onClick={() => setOpen(false)}>
            <span className="w-20">Cerrar</span>
          </PrimaryButton>
        </div>
      </div>
    </ModalWidthFull>
  );
};
