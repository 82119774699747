import { FC, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Icon } from '@iconify/react';
import { useAppSelector } from '../../store/hooks';
import filePdfO from '@iconify/icons-fa/file-pdf-o';
import { DispatcherCCWInterface } from '../../interfaces';
import fileTextO from '@iconify/icons-fa/file-text-o';
import fileExcelO from '@iconify/icons-fa/file-excel-o';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  exportDispatchPDF,
  exportDispatchTXT,
  exportDispatchXLSX,
} from '../../utils/exports';
import {
  Popover,
  FormText,
  MainPage,
  PageTitle,
  FormSelect,
  buttonClassname,
  PaginationFooter,
  DispatchTable,
  DispatchField,
  ConfirmModalStatus,
} from '../../components';
import { getAllDispatchers } from '../../services/DispatchServices';
import { ModalLoading } from '../../components/Atoms/ModalLoading';

const ExportButton = (
  <div
    className={classNames(
      buttonClassname,
      'bg-main-500 hover:bg-main-400 hover:border-main-400 text-white focus-visible:outline-main-400'
    )}
  >
    <div className="flex gap-1 items-center">
      <span>Exportar</span>

      <ChevronDownIcon className="w-4 h-4" />
    </div>
  </div>
);

const ConsultDispatch: FC = () => {
  const user = useAppSelector((state) => state.user);

  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [registersNumber, setRegistersNumber] = useState(10);
  const [disptachs, setDispatchs] = useState<DispatcherCCWInterface[]>([]);
  const [selecteds, setSelecteds] = useState<DispatcherCCWInterface[]>([]);

  let [loading, setLoading] = useState(false);
  let [loadingStatus, setLoadingStatus] = useState(
    ConfirmModalStatus.PROCESSING
  );
  let [loadingTitle, setLoadingTitle] = useState('Cargando...');

  const [sortBy, setSortBy] = useState({
    field: DispatchField.DATE,
    asc: false,
  });

  const filteredDispatch = useMemo(() => {
    setPage(0);
    setSelecteds([]);
    const tokens = search?.toLowerCase().split(' ');
    return disptachs.filter((disptach) => {
      return tokens.every((token) => {
        return (
          disptach.masterShipmentHeaderCode
            ?.toString()
            .toLowerCase()
            .includes(token) ||
          disptach.buCodeSource.toLowerCase().includes(token) ||
          disptach.buCodeDestination
            ?.toString()
            .toLowerCase()
            .includes(token) ||
          disptach.route?.toString().toLowerCase().includes(token) ||
          disptach.transport?.toString().toLowerCase().includes(token) ||
          disptach.seal1?.toString().toLowerCase().includes(token) ||
          disptach.brandName?.toString().toLowerCase().includes(token)
        );
      });
    });
  }, [disptachs, search]);

  const paginatedDispatch = useMemo(() => {
    const customCompare = (a: any, b: any) => {
      const regex = /(\d+)/; // Expresión regular para encontrar números
      const aNumber = parseInt(a.match(regex)[0]); // Extraer el número de 'a'
      const bNumber = parseInt(b.match(regex)[0]); // Extraer el número de 'b'

      // Comparar los números primero
      if (aNumber < bNumber) {
        return -1;
      } else if (aNumber > bNumber) {
        return 1;
      }

      // Si los números son iguales, comparar alfabéticamente
      return a.localeCompare(b);
    };
    const orderedDispatch = filteredDispatch.sort((a, b) => {
      if (sortBy.field === DispatchField.CODE) {
        return sortBy.asc
          ? customCompare(
              a.masterShipmentHeaderCode,
              b.masterShipmentHeaderCode
            )
          : customCompare(
              a.masterShipmentHeaderCode,
              b.masterShipmentHeaderCode
            );
      } else if (sortBy.field === DispatchField.ORIGEN) {
        return sortBy.asc
          ? a.buCodeSource.localeCompare(b.buCodeSource)
          : b.buCodeSource.localeCompare(a.buCodeSource);
      } else if (sortBy.field === DispatchField.DESTINY) {
        return sortBy.asc
          ? a.buCodeDestination.localeCompare(b.buCodeDestination)
          : b.buCodeDestination.localeCompare(a.buCodeDestination);
      } else if (sortBy.field === DispatchField.TRANSPORT) {
        return sortBy.asc
          ? a.transport.localeCompare(b.transport)
          : b.transport.localeCompare(a.transport);
      } else if (sortBy.field === DispatchField.DRIVER) {
        return sortBy.asc
          ? a.brandName.localeCompare(b.brandName)
          : b.brandName.localeCompare(a.brandName);
      } else if (sortBy.field === DispatchField.DATE) {
        return (
          (sortBy.asc
            ? a.creationDate?.localeCompare(b.creationDate ?? '')
            : b.creationDate?.localeCompare(a.creationDate ?? '')) ?? 0
        );
      }

      return 0;
    });

    const start = page * registersNumber;
    const end = start + registersNumber;

    return orderedDispatch.slice(start, end);
  }, [filteredDispatch, sortBy, page, registersNumber]);

  const handleSortByChange = (field: DispatchField) => {
    if (sortBy.field === field) {
      setSortBy({ ...sortBy, asc: !sortBy.asc });
    } else {
      setSortBy({ field, asc: true });
    }
  };

  useEffect(() => {
    const customercode = user?.client?.accountCode ?? undefined;
    const customercId = user?.client?.id?.toString() ?? undefined;
    if (!customercode && !customercId) return;

    const fetchShipments = async () => {
      setLoadingStatus(ConfirmModalStatus.PROCESSING);
      setLoadingTitle('Cargando...');
      setLoading(true);

      const dispatch = await getAllDispatchers(customercode, customercId);
      var listDispatch = dispatch?.model as DispatcherCCWInterface[];

      if (!dispatch?.didError) {
        setDispatchs(listDispatch);
        setLoading(false);
      } else {
        setLoadingStatus(ConfirmModalStatus.ERROR);
        setLoadingTitle('Error: ' + dispatch?.errorMessage);
        setLoading(true);
      }
    };

    fetchShipments();
  }, [user?.client?.id]);

  return (
    <MainPage>
      <PageTitle title="Despacho" />

      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 justify-between gap-2 md:gap-6 flex-col-reverse md:flex-row">
          <div className="flex flex-1" style={{ maxWidth: '45rem' }}>
            <FormText
              id="search"
              name="search"
              value={search}
              placeholder="Busqueda rapida..."
              containerClassname="flex flex-1"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="flex flex-1 justify-between sm:items-center flex-col-reverse gap-6 sm:gap-2 sm:flex-row">
            <div className="flex items-center text-xs">
              <span>Mostrar</span>
              <div className="inline mx-1">
                <FormSelect
                  id="registersNumber"
                  name="registersNumber"
                  label=""
                  selected={registersNumber}
                  options={[10, 25, 50, 100]}
                  className="!w-20 !h-8 !px-2"
                  optionString={(option) => option.toString()}
                  onSelectOption={(option) => setRegistersNumber(option)}
                />
              </div>
              <span>registros</span>
            </div>

            <Popover button={ExportButton}>
              <div className="flex flex-col font-semibold cursor-pointer">
                <div
                  onClick={() =>
                    exportDispatchPDF(user.user!, filteredDispatch)
                  }
                  className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                >
                  <div className="flex  gap-2 py-2 px-4 w-32">
                    <Icon icon={filePdfO} className="w-5 h-5" />
                    <span>PDF</span>
                  </div>
                </div>

                <hr />

                <div
                  onClick={() => exportDispatchXLSX(filteredDispatch)}
                  className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                >
                  <div className="flex gap-2 py-2 px-4 w-32">
                    <Icon icon={fileExcelO} className="w-5 h-5" />
                    <span>EXCEL</span>
                  </div>
                </div>

                <hr />

                <div
                  onClick={() => exportDispatchTXT(filteredDispatch)}
                  className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                >
                  <div className="flex gap-2 py-2 px-4 w-32">
                    <Icon icon={fileTextO} className="w-5 h-5" />
                    <span>TXT</span>
                  </div>
                </div>
              </div>
            </Popover>
          </div>
        </div>

        <div className="flex flex-1 flex-col">
          <DispatchTable
            sortBy={sortBy}
            dispatchs={paginatedDispatch}
            selectedDispatchs={selecteds}
            selectedAll={
              selecteds.length == filteredDispatch.length &&
              selecteds.length > 0
            }
            onChangeSortBy={handleSortByChange}
            onSelectDispatch={(dispatch) => {
              if (selecteds.includes(dispatch)) {
                setSelecteds(selecteds.filter((s) => s !== dispatch));
              } else {
                setSelecteds([...selecteds, dispatch]);
              }
            }}
            onSelectAll={() => {
              if (selecteds.length == filteredDispatch.length) {
                setSelecteds([]);
              } else {
                setSelecteds([...filteredDispatch]);
              }
            }}
          />

          <PaginationFooter
            currentPage={page}
            itemsPerPage={registersNumber}
            totalItems={filteredDispatch.length}
            onPageChange={setPage}
          />
        </div>
      </div>
      <ModalLoading
        open={loading}
        title={loadingTitle}
        status={loadingStatus}
        setOpen={setLoading}
        onPrint={() => {}}
      />
    </MainPage>
  );
};

export default ConsultDispatch;
