import { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { getDestinationByCode } from "../../services";
import { DestinationInterface } from "../../interfaces";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import {
  FormText,
  MainPage,
  PageTitle,
  PrimaryButton,
  DestinationForm,
  SecondaryButton,
} from "../../components";

const CreateDestination: FC = () => {
  const location = useLocation();
  const taxIdentificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const [destinationCode, setDestinationCode] = useState("");
  const [destinationFound, setDestinationFound] = useState<boolean>();
  const [destination, setDestination] = useState<DestinationInterface>();

  const handleSearch = async () => {
    const response = await getDestinationByCode(destinationCode);

    if (!response) {
      setDestinationFound(false);
      return;
    } else {
      setDestination(response);
      setDestinationFound(true);
    }
  };

  return (
    <MainPage>
      <PageTitle title="Crear Destinatario" back={location.state?.back} />

      {destinationFound == undefined && (
        <div className="flex flex-1 flex-col sm:flex-row sm:items-center sm:gap-8 px-8 sm:px-0">
          <FormText
            id="account-contact-code"
            name="account-contact-code"
            label="Código"
            autoComplete="off"
            value={destinationCode}
            onChange={(e) => setDestinationCode(e.target.value)}
            labelClassname="text-md"
            labelContainerClassname="flex sm:!justify-end sm:w-52"
            containerClassname="flex sm:ml-8 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: "35rem" }}
          />
          <PrimaryButton
            onClick={handleSearch}
            className="px-4 mt-8 sm:mt-0 w-full sm:w-16"
          >
            Buscar
          </PrimaryButton>
        </div>
      )}

      {destinationFound !== undefined && (
        <SecondaryButton
          onClick={() => {
            setDestinationFound(undefined);
            setDestination(undefined);
          }}
          className="text-sm flex items-center gap-0 mb-6 ml-8 "
        >
          <ChevronLeftIcon className="h-4 w-4 mr-2" />
          Nuevo Destinatario
        </SecondaryButton>
      )}

      {destinationFound == true && destination && (
        <DestinationForm
          isUpdate
          initialValues={{
            code: destination.location.code ?? "",
            abbreviationName:
              taxIdentificationTypes.find(
                (t) => t.abbreviationName == destination.account.abbreviationName
              )?.abbreviationName ?? "V-",
            identificationNumber: destination.account.identificationNumber,
            name: destination.location.name,
            alias: destination.location.name,
            destination: "CCS",
            store: destination.businessUnit?.buCode,
            address: destination.location.address,
            contact: destination.account.accountFullName,
            phone1: destination.account.listAccountPhone[0]?.phoneNumber ?? "",
            phone2: destination.account.listAccountPhone[1]?.phoneNumber ?? "",
            email: destination.account.listAccountEmail[0]?.email ?? "",
          }}
        />
      )}

      {destinationFound == false && (
        <DestinationForm
          initialValues={{
            code: destinationCode,
            abbreviationName: "V-",
            identificationNumber: "",
            name: "",
            alias: "",
            destination: "",
            store: "",
            address: "",
            contact: "",
            phone1: "",
            phone2: "",
            email: "",
          }}
        />
      )}
    </MainPage>
  );
};

export default CreateDestination;
