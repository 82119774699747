import { FC } from "react";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { PrimaryButton } from "../Atoms/Buttons";
import { FormText } from "../FormFields/FormText";

export interface ResetPasswordFormValues {
  passwordOld: string;
  password: string;
  confirmPassword: string;
}
interface FormInterface {
  formik: FormikProps<ResetPasswordFormValues>;
}
const Form: FC<FormInterface> = ({ formik }) => {
  return (
    <form onSubmit={formik.handleSubmit} className="flex h-full flex-col gap-6">
      <div className="flex h-full flex-col gap-4">
        <div style={{ maxWidth: "35rem" }}>
          <FormText
            id="passwordOld"
            name="passwordOld"
            type="password"
            placeholder="Contraseña anterior"
            value={formik.values.passwordOld}
            error={
              formik.touched.passwordOld && formik.errors.passwordOld
                ? formik.errors.passwordOld
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-64"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
          />
        </div>

        <div style={{ maxWidth: "35rem" }}>
          <FormText
            id="password"
            name="password"
            type="password"
            placeholder="Contraseña"
            value={formik.values.password}
            error={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-64"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
          />
        </div>

        <div style={{ maxWidth: "35rem" }}>
          <FormText
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            placeholder="Confirmar Contraseña"
            value={formik.values.confirmPassword}
            error={
              formik.touched.confirmPassword && formik.errors.confirmPassword
                ? formik.errors.confirmPassword
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-64"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
          />
        </div>

        <div className="ml-0 sm:ml-64 w-full sm:w-auto">
          <PrimaryButton type="submit" className="ml-0 sm:ml-8 w-full sm:w-32">
            Cambiar
          </PrimaryButton>
        </div>
      </div>
    </form>
  );
};

interface ResetPasswordFormProps {
  onSubmit?: (prop: ResetPasswordFormValues) => void;
}
export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({
  onSubmit = () => {},
}) => {
  // Validations with Yup for Formik form
  const validationSchema = Yup.object().shape({
    passwordOld: Yup.string().required("La contraseña anterior es obligatoria"),
    password: Yup.string().required("Este campo es requerido"),
    confirmPassword: Yup.string()
      .required("Este campo es requerido")
      .oneOf([Yup.ref("password")], "Las contraseñas no coinciden"),
  });

  return (
    <Formik
      initialValues={{ password: "", confirmPassword: "", passwordOld: "" }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values);
        resetForm();
      }}
    >
      {(formik) => <Form formik={formik} />}
    </Formik>
  );
};
