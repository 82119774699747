import * as Yup from "yup";
import { PrimaryButton } from "../Atoms/Buttons";
import { PieceInterface } from "../../interfaces";
import { FormText } from "../FormFields/FormText";
import { useAppSelector } from "../../store/hooks";
import { FormSelect } from "../FormFields/FormSelect";
import { PlusIcon } from "@heroicons/react/24/outline";
import { handleNumberChange } from "../../utils/format";
import { FormikErrors, FormikProps, FormikTouched } from "formik";
import { useEffect } from 'react';
import { PackageTypeEnum } from "../../interfaces/Shipment/PackageTypeEnum";

export interface PieceFormValues {
  category: string;
  height: string;
  width: string;
  length: string;
  weight: string;
  declaredValue: string;
  amount: number;
  type?:string;
}
interface PieceField {
  shipment: {
    pieces: PieceInterface[];
    packageType: string;
    valueDeclarate: string;
  };
  newPiece: PieceFormValues;
}
interface PieceFormProps<T> {
  formik: FormikProps<T & PieceField>;
}

const validationSchema = Yup.object().shape({
  category: Yup.string().required("Este campo es requerido"),
  weight: Yup.string()
    .test("min-weight", "Debe ser mayor a 0", (value) => {
      if (!value) return false;
      return +value > 0;
    })
    .test("max-weight", "Debe ser menor a 999kg", (value,
      object) => {
      if (!value) return false;
      if(object.parent.type === "10")
        return true;

      return +value < 1000;
    })
    .test("max-weight", "Debe ser menor a 0.500kg", (value,
      object) => {
      if (!value) return false;
      if(object.parent.type === "20" || object.parent.type === undefined)
        return true;      
      return +value < 0.501;
    })
    .required("Este campo es requerido"), 
  height: Yup.string()
    .test("max-height", "No puede superar los 300 cm", (value) => {
      if (!value) return false;
      return +value <= 300;
    })
    .test("min-height", "Debe ser mayor a 0", (value) => {
      if (!value) return false;
      return +value > 0;
    })
    .required("Este campo es requerido"),
  width: Yup.string()
    .test("max-width", "No puede superar los 300 cm", (value) => {
      if (!value) return false;
      return +value <= 300;
    })
    .test("min-width", "Debe ser mayor a 0", (value) => {
      if (!value) return false;
      return +value > 0;
    })
    .required("Este campo es requerido"),
  length: Yup.string()
    .test("max-length", "No puede superar los 300 cm", (value) => {
      if (!value) return false;
      return +value <= 300;
    })
    .test("min-length", "Debe ser mayor a 0", (value) => {
      if (!value) return false;
      return +value > 0;
    })
    .required("Este campo es requerido"),
  amount: Yup.string()
    .test("min-amount", "Debe ser mayor a 0", (value) => {
      if (!value) return false;
      return +value > 0;
    })
    .required("Este campo es requerido"),
});

export const PieceForm = <T extends any>({ formik }: PieceFormProps<T>) => {
  const errors = formik.errors.newPiece as FormikErrors<PieceFormValues> | undefined;
  const touched = formik.touched.newPiece as FormikTouched<PieceFormValues> | undefined;
  const pieceCategories = useAppSelector((state) => state.inmutable.PieceCategories);
  
useEffect(() =>{
  formik.setFieldValue("newPiece.type", formik.values.shipment.packageType ?? PackageTypeEnum.BOX)
},[formik.values.shipment.packageType]);

  const validate = () => {
    try {
      validationSchema.validateSync(formik.values.newPiece, { abortEarly: false });
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors: FormikErrors<T & PieceField> = error.inner.reduce((errors, err) => {
          return { ...errors, [err.path!]: err.message };
        }, {});
        formik.setFormikState({
          ...formik,
          errors: { ...formik.errors, newPiece: errors },
          touched: {
            ...formik.touched,
            newPiece: {
              category: true,
              weight: true,
              declaredValue: true,
              height: true,
              width: true,
              length: true,
              amount: true,
            },
          },
        });
      }
      return false;
    }
  };

  return (
    <div className="flex h-full flex-col gap-6">
      <div className="flex w-full flex-col sm:flex-row gap-4">
        <div className="flex flex-1">
          <FormSelect
            id="newPiece.category"
            name="newPiece.category"
            label="Categoría"
            selected={formik.values.newPiece.category}
            options={pieceCategories.map((category) => category.id)}
            error={touched?.category && errors?.category ? errors?.category : undefined}
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs mb-1"
            containerClassname="flex-1"
            optionString={(option) =>
              pieceCategories.find((c) => c.id == option)?.name ?? ""
            }
            onSelectOption={(option) => formik.setFieldValue("newPiece.category", option)}
          />
        </div>

        <div className="w-full sm:w-24">
          <FormText
            id="newPiece.weight"
            name="newPiece.weight"
            label="Peso en kg."
            autoComplete="off"
            value={formik.values.newPiece.weight}
            error={touched?.weight && errors?.weight ? errors?.weight : undefined}
            onChange={(e) => handleNumberChange(e, formik, true)}
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs mb-1"
          />
        </div>

        <div className="w-full sm:w-40 hidden">
          <FormText
            id="newPiece.declaredValue"
            name="newPiece.declaredValue"
            label="Valor declarado en dólares"
            autoComplete="off"
            value={formik.values.newPiece.declaredValue}
            error={
              touched?.declaredValue && errors?.declaredValue
                ? errors?.declaredValue
                : undefined
            }
            onChange={(e) => handleNumberChange(e, formik, true)}
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs mb-1"
          />
        </div>
      </div>

      <div className="flex w-full flex-col gap-1">
        <span className="text-xs font-bold text-gray-700">Dimensiones</span>

        <div className="flex w-full flex-col sm:flex-row gap-4">
          <div className="flex-1">
            <FormText
              id="newPiece.height"
              name="newPiece.height"
              label="Alto"
              autoComplete="off"
              value={formik.values.newPiece.height}
              error={touched?.height && errors?.height ? errors?.height : undefined}
              onChange={(e) => handleNumberChange(e, formik, false)}
              onBlur={formik.handleBlur}
              className="!h-8"
              labelClassname="!text-xs mb-1"
            />
          </div>

          <div className="flex-1">
            <FormText
              id="newPiece.width"
              name="newPiece.width"
              label="Ancho"
              autoComplete="off"
              value={formik.values.newPiece.width}
              error={touched?.width && errors?.width ? errors?.width : undefined}
              onChange={(e) => handleNumberChange(e, formik, false)}
              onBlur={formik.handleBlur}
              className="!h-8"
              labelClassname="!text-xs mb-1"
            />
          </div>

          <div className="flex-1">
            <FormText
              id="newPiece.length"
              name="newPiece.length"
              label="Largo"
              autoComplete="off"
              value={formik.values.newPiece.length}
              error={touched?.length && errors?.length ? errors?.length : undefined}
              onChange={(e) => handleNumberChange(e, formik, false)}
              onBlur={formik.handleBlur}
              className="!h-8"
              labelClassname="!text-xs mb-1"
            />
          </div>
        </div>
      </div>

      <div className="flex w-full justify-between items-end gap-6">
        <FormText
          id="newPiece.amount"
          name="newPiece.amount"
          label="Cantidad"
          autoComplete="off"
          value={formik.values.newPiece.amount}
          error={touched?.amount && errors?.amount ? errors?.amount : undefined}
          onChange={(e) => handleNumberChange(e, formik, false)}
          onBlur={formik.handleBlur}
          className="!h-8"
          labelClassname="!text-xs mb-1"
          style={{ maxWidth: "10rem" }}
        />

        <PrimaryButton
          type="button"
          onClick={() => {
            if (!validate()) return;
            let piecesList= formik.values.shipment.pieces;
            let countPieces = piecesList.length +1;

            let piecesData = formik.values.shipment.valueDeclarate;
            piecesData = piecesData == undefined ? "0" : piecesData;
            let declareValue = 0; 
            declareValue= parseFloat(piecesData ?? "0")  / countPieces ?? 0;
            declareValue = Number.isNaN(declareValue) ? 0 : declareValue;

            piecesList.map((pieces) => {     
              pieces.declaredValue = declareValue;
            });

            const piece: PieceInterface = {
              id: "",
              category: pieceCategories.find(
                (c) => c.id == formik.values.newPiece.category
              )!,
              weight: +formik.values.newPiece.weight,
              declaredValue: +declareValue,
              height: +formik.values.newPiece.height,
              width: +formik.values.newPiece.width,
              length: +formik.values.newPiece.length,
            };
            const newPieces = new Array(+formik.values.newPiece.amount).fill(piece);

            formik.setFormikState({
              ...formik,
              errors: { ...formik.errors, newPiece: {}, preShipment: { pieces: "" } },
              touched: {
                ...formik.touched,
                newPiece: {
                  category: false,
                  weight: false,
                  declaredValue: false,
                  height: false,
                  width: false,
                  length: false,
                  amount: false,
                  type: false
                },
              },
              values: {
                ...formik.values,
                newPiece: {
                  category: "",
                  height: "",
                  width: "",
                  length: "",
                  weight: "",
                  declaredValue: "",
                  amount: 1,
                  type: formik.values.shipment.packageType
                },
                shipment: {
                  ...formik.values.shipment,
                  pieces: [...formik.values.shipment.pieces, ...newPieces],
                },
              },
            });
          }}
          className="flex items-center gap-1"
        >
          <PlusIcon className="h-4 w-4" />
          Agregar
        </PrimaryButton>
      </div>
    </div>
  );
};
