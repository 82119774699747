import axios from "axios";
import { CategoryDto, PieceCategoryInterface } from "../interfaces";
import { AuthenticationServices } from "./AuthenticationServices";
import { ListResponse } from "../interfaces/Shipment/ListResponseDto";
import { CategoryMapper } from "../utils/mappers";

const CATEGORIES = [
  { id: "1", name: "ACCESORIOS DEL HOGAR", code:"" },
  { id: "2", name: "ACCESORIOS PARA ELECTRONICOS", code:"" },
  { id: "3", name: "ALIMENTOS", code:"" },
  { id: "4", name: "ALIMENTOS PARA MASCOTAS", code:"" },
  { id: "5", name: "ARTICULOS DE ASEO", code:"" },
  { id: "6", name: "ARTICULOS DE BEBE", code:"" },
  { id: "7", name: "ARTICULOS DE BELLEZA", code:"" },
  { id: "8", name: "ARTICULOS DE FIESTA", code:"" },
  { id: "9", name: "ARTICULOS DE OFICINA", code:"" },
  { id: "10", name: "ARTICULOS DEPORTIVOS", code:"" },
  { id: "11", name: "ARTICULOS ESCOLARES", code:"" },
  { id: "12", name: "ARTICULOS MEDICOS", code:"" },
  { id: "13", name: "ARTICULOS PARA MASCOTAS", code:"" },
  { id: "14", name: "CALZADO", code:"" },
  { id: "15", name: "CORRESPONDENCIA", code:"" },
  { id: "16", name: "ELECTRODOMESTICOS", code:"" },
  { id: "17", name: "ELECTRONICOS", code:"" },
  { id: "18", name: "EQUIPOS PROFESIONALES", code:"" },
  { id: "19", name: "HERRAMIENTAS", code:"" },
  { id: "20", name: "HIGIENE Y ASEO PERSONAL", code:"" },
  { id: "21", name: "JUGUETES", code:"" },
  { id: "22", name: "MAQUILLAJE", code:"" },
  { id: "23", name: "MEDICINAS Y SUPLEMENTOS", code:"" },
  { id: "24", name: "MISCELANEOS", code:"" },
  { id: "25", name: "PARTES ELECTRONICAS", code:"" },
  { id: "26", name: "REPUESTOS ELECTRONICOS", code:"" },
  { id: "27", name: "REPUESTOS VEHICULOS", code:"" },
  { id: "28", name: "ROPA", code:"" },
  { id: "29", name: "PREPARED BY CUSTOMER", code:"" },
];

const {REACT_APP_URL_PATH_API} = process.env;

export const getPieceCategories = async (): 
Promise<PieceCategoryInterface[] | undefined> => {
  
  try {
    const token = await AuthenticationServices();  
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    const response = await axios.get(`${REACT_APP_URL_PATH_API}/v1/Products/GetCategory`);
    if (response.status == 200) {      
      var categoryresponse = response.data as ListResponse<CategoryDto[]>; 
      if(categoryresponse.didError == false){
        var datamap = categoryresponse.model?.map((dto: CategoryDto) => CategoryMapper(dto));
        return datamap;
    }
      return CATEGORIES;
    }    
  } catch (error) {}
  return [];
};
