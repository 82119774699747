import axios from "axios";
import { ListResponse } from "../interfaces/Shipment/ListResponseDto";
import { LocationDto } from "../interfaces/Dto/LocationDto";
import { BUByLocationDto, Nullable } from "../interfaces";
import { AuthenticationServices } from "./AuthenticationServices";

// const CITIES = [
//   "Acarigua",
//   "Aeropuerto",
//   "Aguas Calientes",
//   "Anaco",
//   "Araira",
//   "Arapuey",
//   "Altagracia",
//   "Araure",
//   "Bachaquero",
//   "Borburata",
//   "Barcelona",
//   "Bocono",
//   "Bejuma",
//   "Bailadores",
//   "Barinas",
//   "Barinitas",
//   "Betijoque",
//   "Barquisimeto",
//   "Buena Vista",
//   "Cagua",
//   "Caja Seca",
//   "Caña De Azucar",
//   "Cabudare",
//   "Ciudad Bolivar",
//   "Cabimas",
//   "Caracas",
//   "Caraballeda",
//   "Cordero",
//   "Central Tacarigua",
//   "Caucagua",
//   "Chiguara",
//   "Ciudad Alianza",
//   "Calabozo",
//   "Charallave",
//   "Catia La Mar",
//   "El Callao",
//   "Carenero",
//   "Clarines",
//   "Caneyes",
//   "Chivacoa",
//   "Ciudad Ojeda",
//   "Corozo",
//   "Capacho",
//   "Carora",
//   "Coro",
//   "Carupano",
//   "Corozo",
//   "Cantaura",
//   "Cua",
//   "Cumana",
//   "Carrizal",
//   "Dividive",
//   "El Cambur",
//   "El Consejo",
//   "Ejido",
//   "El Vigia",
//   "El Maco",
//   "El Palito",
//   "El Palmar",
//   "El Espinal",
//   "El Rodeo",
//   "El Tigrito",
//   "El Valle Espiritu S",
//   "Filas De Mariche",
//   "Furrial",
//   "Guigue",
//   "El Guamache",
//   "Guanare",
//   "Guanta",
//   "Granados",
//   "Guasipati",
//   "Guataparo",
//   "Guarenas",
//   "Guacara",
//   "Guatire",
//   "Higuerote",
//   "Isnotu",
//   "Judibana",
//   "San Juan De Los Morr",
//   "Juan Griego",
//   "San Joaquin",
//   "Criogenico De Jose",
//   "La Guaira",
//   "La Asuncion",
//   "La Victoria",
//   "La Candelaria",
//   "Lecheria",
//   "La Cumaca",
//   "La Fuente",
//   "Lagunillas(M)",
//   "Lagunillas(Z)",
//   "Los Guayos",
//   "El Limon",
//   "Las Morochas",
//   "Los Montones",
//   "Santa Rita",
//   "Los Teques",
//   "La Vela De Coro",
//   "Macuto",
//   "Maracaibo",
//   "Maracay",
//   "Maiquetia",
//   "Miranda",
//   "Mampote",
//   "Merida",
//   "Mariara",
//   "Moron",
//   "La Morita",
//   "Montalban",
//   "Motatan",
//   "Maturin",
//   "Manzanillo",
//   "Naguanagua",
//   "Nueva Bolivia",
//   "Nirgua",
//   "Ocumare Del Tuy",
//   "Puerto Cabello",
//   "Playa Cardon",
//   "Paraguachi",
//   "Puerto Cumarebo",
//   "Pedregales",
//   "Punta De Piedra",
//   "Playa El Agua",
//   "Playa El Yaque",
//   "Punta Gorda",
//   "Playa Guacuco",
//   "Pedro Gonzalez",
//   "Piritu",
//   "Puerto La Cruz",
//   "Palotal",
//   "Punta De Mata",
//   "Porlamar",
//   "Palo Negro",
//   "Puerto Ordaz",
//   "Playa Parguito",
//   "Pampatar",
//   "Palmira",
//   "Pariaguan",
//   "Paracotos",
//   "Punto Fijo",
//   "Puerto Piritu",
//   "Patiecitos",
//   "Playa El Tirano",
//   "Quibor",
//   "Rubio",
//   "Rio Chico",
//   "Sabana Mendoza",
//   "San Carlos",
//   "Socopo",
//   "San Cristobal",
//   "Santa Cruz",
//   "San Antonio De Los A",
//   "San Diego",
//   "San Diego De Altos",
//   "San Felix",
//   "San Felipe",
//   "San Fernando De A",
//   "Sabana Grande",
//   "San Juan Bautista",
//   "Sabana Libre",
//   "Santa Lucia",
//   "Simon Bolivar",
//   "San Mateo",
//   "Santa Cruz De Mora",
//   "Santa Teresa",
//   "San Tome",
//   "San Vicente",
//   "San Vicente Zona Ind.",
//   "San Antonio Del Tach",
//   "Tabay",
//   "Tocuyito",
//   "Tienditas",
//   "Tejerias",
//   "El Tigre",
//   "Tia Juana",
//   "Toiko",
//   "Tinaco",
//   "Trujillo",
//   "Tumeremo",
//   "El Tocuyo",
//   "Tinaquillo",
//   "Tariba",
//   "Tamare",
//   "Turmero",
//   "Tovar",
//   "Unare",
//   "Upata",
//   "Ureña",
//   "Villa De Cura",
//   "Valle De La Pascua",
//   "Valencia",
//   "Valera",
//   "Yagua",
//   "Yaritagua",
//   "Zea",
//   "Ospino",
//   "Colon",
//   "Coloncito",
//   "La Fria",
//   "Lobatera",
//   "Michelena",
//   "Tucupita",
//   "Villa Del Rosario",
//   "Machiques",
//   "Aeropuerto Ccs",
//   "Aeropuerto Maiquetia",
//   "Agua Viva",
//   "Anare",
//   "Boconoito",
//   "Borota",
//   "Bramon",
//   "Campo Carabobo",
//   "Camuri Chico",
//   "Camuri Grande",
//   "Camuribe",
//   "Carayaca",
//   "Caribe",
//   "Carretera Pan. Km 0 Al 23",
//   "Pan Km 24 Al 30",
//   "Central Rio Turbio",
//   "Ciudad Bolivia",
//   "Ciudad Guayana",
//   "Cumbre Roja",
//   "Curbati",
//   "El Amparo",
//   "El Corozo",
//   "El Menito",
//   "El Prado",
//   "El Retiro",
//   "El Tambor",
//   "El Tejero",
//   "Esquque",
//   "Estanquez",
//   "Flor De Patria",
//   "Fuerte Tiuna",
//   "Granja Las Mercedes",
//   "Invepal",
//   "Km 1 Al 9 Via Perija",
//   "Km 10-50 Via Perija",
//   "La Beatriz",
//   "La Aparicion Ospino",
//   "La Camaruca",
//   "La Campina",
//   "La Cejita",
//   "La Concepción",
//   "La Esperanza",
//   "La Guardia",
//   "La Plazuela",
//   "La Sabana De Guacuco",
//   "Las Salinas Cabimas",
//   "Lagunetica",
//   "Las Giles",
//   "Las Piedras",
//   "Los Bagres",
//   "Los Caracas",
//   "Los Cerritos",
//   "Los Corales",
//   "Los Millanes",
//   "Los Rastrojos",
//   "Los Robles",
//   "Marapa Marina",
//   "Mataruca",
//   "Mendoza Fria",
//   "Monte Sano",
//   "Naiguata",
//   "Nueva Cua",
//   "Palaciero",
//   "Palavecino",
//   "Palma Sola",
//   "Pampan",
//   "Pampanito",
//   "Papelon",
//   "Pedraza",
//   "Pequiven",
//   "Picure",
//   "Playa El Angel",
//   "Playa Grande",
//   "Punta Cardon",
//   "San Antonio Del Golfo",
//   "San Antonio Del Yare",
//   "San Fco De Asis",
//   "San Francisco Del Yare",
//   "San Jose De Guanipa",
//   "San Jose De Los Altos",
//   "San Juan De Colon",
//   "San Pedro De Los Altos",
//   "San Pedro Del Rio",
//   "San Sebastian",
//   "Santa Cruz De Mara",
//   "Santa Maria De Ipire",
//   "Sarare",
//   "Siquisique",
//   "Taborda",
//   "Tacarigua",
//   "Tacarigua N Esparta",
//   "Tacarigua Mamporal",
//   "Tacoa",
//   "Taparito",
//   "Taratara",
//   "Tunitas",
//   "Valle Pedro Gonzalez",
//   "Villa Tamare",
//   "Zona Ind La Cumaca",
//   "Santa Barbara",
//   "Sabaneta",
//   "Barrancas",
//   "Temblador",
//   "Guasdualito",
//   "El Pinal",
//   "La Pedrera",
//   "Santa Rita (Mcy)",
//   "Achaguas",
//   "Biruaca",
//   "Dos Caminos",
//   "El Sombrero",
//   "Ortiz",
//   "Barbacoa",
//   "Gran Caracas",
//   "Libertad",
//   "Santa Barbara De Barinas",
//   "Tucani",
//   "Bobure",
//   "Cumarebo",
//   "Los Anaucos",
//   "San Jose De Barlovento",
//   "Bobures",
//   "Palmarito",
//   "Tucanizon",
//   "San Antonio",
//   "Playa Grande",
//   "Tucani",
//   "Sector Caño De Agua",
//   "Sector La Florida",
//   "Aguacil",
//   "Las Virtudes",
//   "Muyapa",
//   "Agua",
//   "Boscan",
//   "Gibraltar",
//   "La Grita",
//   "El Junquito",
//   "Magdaleno",
//   "Biscucuy",
//   "Timotes",
//   "Ciudad Piar",
//   "Ciudad Piar",
//   "Tejero",
//   "Barinas",
//   "Boca De Rio",
//   "Ciudad Bolivar",
//   "Cumana",
//   "Caja Seca",
//   "Costa Oriental",
//   "El Mojan",
//   "El Tigre",
//   "El Venado",
//   "Goaigoaza",
//   "Gañango",
//   "Juan Griego",
//   "Maracay",
//   "Mene Grande",
//   "Macanao",
//   "Mampote",
//   "El Menito",
//   "Puerto Cabello",
//   "Puerto Escondido",
//   "Punto Fijo",
//   "Palmarejo",
//   "Porlamar",
//   "Quibor",
//   "San Juan",
//   "San Cristobal Oeste",
//   "San Felipe",
//   "San Joaquin",
//   "Trincheras",
//   "Valencia",
//   "El Vigia",
//   "Villa De Cura",
//   "Puerto Ayacucho",
//   "El Mojan",
//   "Guanarito",
//   "Mucuchies",
//   "Chama",
//   "Santo Dominto",
//   "San Silvestre",
//   "Canagua",
//   "Aragua De Barcelona",
//   "Batatuy",
//   "Altagracia Del Orituco",
//   "Zaraza",
//   "Tucupido",
//   "La Miel",
//   "Caripe",
//   "Caripito",
//   "Tucacas",
//   "Las Mercedes Del Llano",
//   "El Socorro",
//   "Santa Ana (Nueva Esparta)",
//   "Turen",
//   "Chabasquen",
//   "Boca De Rio",
//   "La Vecindad ",
//   "El Cercado",
//   "Las Cabreras",
// ];

const { REACT_APP_URL_PATH_API } = process.env;

export const getCities = async (): Promise<Nullable<LocationDto[]>> => {
  const uri = `${REACT_APP_URL_PATH_API}/BUByLocation/GetAllLocation?CountryId=236&TypeId=3`;
  try {
    const token = await AuthenticationServices();    
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

    const response = await axios.get(uri);
    if (response.status === 200) {
      var categoryresponse = response.data as ListResponse<LocationDto[]>;
      if (categoryresponse.didError === false) {
        var datamap = categoryresponse.model;
        return datamap;
      }
    }
  } catch (error) {}

  return [];
};

export const getCitiesByBu = async (): Promise<Nullable<BUByLocationDto[]>> => {
  const uri = `${REACT_APP_URL_PATH_API}/BUByLocation/`;
  try {
    const token = await AuthenticationServices();    
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

    const response = await axios.get(uri);
    if (response.status === 200) {
      var categoryresponse = response.data as BUByLocationDto[];
      return categoryresponse;
    }
  } catch (error) {}
  return [];
};
