import axios from 'axios';
import {
  AccountFavoriteDestinationResponseDto,
  FavoriteDestinationsInterfase,
  FavoriteDestinationsSpDto,
} from '../interfaces';
import {
  ListResponse,
  SimpleResponse,
} from '../interfaces/Shipment/ListResponseDto';
import { AuthenticationServices } from './AuthenticationServices';

const { REACT_APP_URL_PATH_API } = process.env;

export const getAllFavoriteDestinations = async (
  accountID: string,
  accountFavoriteID?: string
): Promise<ListResponse<FavoriteDestinationsInterfase[]> | undefined> => {
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  var response = await axios
    .get(
      `${REACT_APP_URL_PATH_API}/GetAllFavDestination?AccountID=${accountID}&AccountFavoriteId=${
        accountFavoriteID ?? ''
      }`
    )
    .then(function (response) {
      var responsedto = response.data;
      return responsedto as ListResponse<FavoriteDestinationsInterfase[]>;
    })
    .catch(function (error) {
      //dirError= true;
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //let  errorData = error.response.data;
        //errorMessage = errorData.message ?? "";
        console.log(error.message ?? '');
        return error.response as ListResponse<FavoriteDestinationsInterfase[]>;
      } else {
        // Something happened in setting up the request that triggered an Error
        //errorMessage = error.message ?? "";
        console.log(error.message ?? '');
      }
      return [] as unknown as ListResponse<FavoriteDestinationsInterfase[]>;
    });

  return response;
};

export const createFavoriteDestinationsApi = async (
  consigne: FavoriteDestinationsSpDto
): Promise<SimpleResponse<AccountFavoriteDestinationResponseDto[]>> => {
  let dirError = false;
  let errorMessage = '';
  try {
    const token = await AuthenticationServices();
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    var response = await axios
      .post(
        `${REACT_APP_URL_PATH_API}/Account/InsertFavoriteDestination`,
        consigne
      )
      .then(function (response) {
        var data =
          response.data as ListResponse<AccountFavoriteDestinationResponseDto>;
        if (!data.didError) {
          dirError = false;
        } else {
          alert('Error al crear : ' + data.errorMessage);
        }
        return data;
      })
      .catch(function (error) {
        dirError = true;
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          let errorData = error.response
            .data as ListResponse<AccountFavoriteDestinationResponseDto>;
          errorMessage = errorData.errorMessage ?? '';
          console.log('Error generando guia:', errorData.errorMessage);

          return errorData;
        } else {
          // Something happened in setting up the request that triggered an Error
          errorMessage = error.message ?? '';
          var data = {} as ListResponse<AccountFavoriteDestinationResponseDto>;
          data.didError = true;
          data.errorMessage = 'Error generando guia:' + error.message;
          console.log('Error generando guia:', error.message);
          return data;
        }

        return error;
      });

    return response as ListResponse<AccountFavoriteDestinationResponseDto[]>;
  } catch (error) {
    console.log('Error generando guia:', error);
  }

  return {} as ListResponse<AccountFavoriteDestinationResponseDto[]>;
};
