import { useMemo } from "react";
import classNames from "classnames";
import { FormikProps } from "formik";
import { FormText } from "../FormFields/FormText";
import { FormDatePicker } from "../FormFields/FormDatePicker";
import moment from "moment";

export interface LoadPODFormValues {
  date: Date | undefined;
  identificationNumber: string;
  client: string;
}
interface LoadPODFormProps<T> {
  formik: FormikProps<T & LoadPODFormValues>;
}

export const LoadPODForm = <T extends any>({ formik }: LoadPODFormProps<T>) => {
  const date = useMemo(() => {
    return {
      startDate: formik.values.date ?? null,
      endDate: formik.values.date ?? null,
    };
  }, [formik.values.date]);

  return (
    <div className="flex h-full flex-col gap-4">
      <FormDatePicker
        id="date"
        name="date"
        label="Fecha de Entrega"
        value={date}
        className="!h-8"
        labelClassname="!text-xs"
        labelContainerClassname="flex sm:!justify-end sm:w-32"
        toggleClassName={(oldClassname) => classNames(oldClassname, "text-main-500")}
        containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
        
        useRange={false}
        onChange={(e) => {
          formik.setFieldValue("date", moment(e?.startDate).toDate());
        }}
        configs={{
          shortcuts: {
            today: "Hoy",
            yesterday: "Ayer",
            currentMonth: "Mes actual",
            pastMonth: "Mes anterior",
            past: (period: number) => `Hace ${period} días`,
          },
        }}
      />

      <FormText
        id="identificationNumber"
        name="identificationNumber"
        label="Cédula"
        value={formik.values.identificationNumber}
        error={
          formik.touched?.identificationNumber && formik.errors?.identificationNumber
            ? formik.errors?.identificationNumber
            : undefined
        }
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="!h-8"
        labelClassname="!text-xs"
        labelContainerClassname="flex sm:!justify-end sm:w-32"
        containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
        style={{ maxWidth: "15rem" }}
      />

      <FormText
        id="client"
        name="client"
        label="Persona que recibe"
        value={formik.values.client}
        error={
          formik.touched?.client && formik.errors?.client
            ? formik.errors?.client
            : undefined
        }
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="!h-8"
        labelClassname="!text-xs"
        labelContainerClassname="flex sm:!justify-end sm:w-32"
        containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
        style={{ maxWidth: "15rem" }}
      />
    </div>
  );
};
