import { FC, useState } from "react";
import classNames from "classnames";
import { Popover } from "../Atoms/Popover";
import { PreShipmentInterface } from "../../interfaces";
import {
  PrinterIcon,
  ChevronDownIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import { PresipmentImpresionModal } from "./PreshipmentImpresionModal";

export enum PreShipmentField {
  PRE_SHIPMENT,
  CONSIGNEE_NAME,
  CONSIGNEE_ID,
  CITY,
  ADDRESS,
  PIECES,
  WEIGHT,
}

const TableOptionsButton = (
  <div className="flex w-8 h-8 items-center justify-center">
    <EllipsisVerticalIcon className="w-5 h-5" />
  </div>
);

interface PreShipmentTableProps {
  preShipments: PreShipmentInterface[];
  sortBy: { field: PreShipmentField; asc: boolean };
  onChangeSortBy: (field: PreShipmentField) => void;
}

export const PreShipmentTable: FC<PreShipmentTableProps> = ({
  preShipments,
  sortBy,
  onChangeSortBy,
}) => {

  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState<PreShipmentInterface>();

  
  return (
    <div className="relative w-full table-fix-head mt-4 overflow-auto">
      <table className="table-auto w-full text-gray-600 text-sm text-left border border-gray-300">
        <thead className="select-none font-semibold border-b border-gray-300 bg-gray-50">
          <tr>
            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(PreShipmentField.PRE_SHIPMENT)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Preguía</span>
                <div className="h-4 w-4">
                  {sortBy.field == PreShipmentField.PRE_SHIPMENT && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(PreShipmentField.CONSIGNEE_NAME)}
              style={{ minWidth: "13rem" }}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Nombre Destinatario</span>
                <div className="h-4 w-4">
                  {sortBy.field == PreShipmentField.CONSIGNEE_NAME && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(PreShipmentField.CONSIGNEE_ID)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Identificación Destinatario</span>
                <div className="h-4 w-4">
                  {sortBy.field == PreShipmentField.CONSIGNEE_ID && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(PreShipmentField.CITY)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Ciudad Destino</span>
                <div className="h-4 w-4">
                  {sortBy.field == PreShipmentField.CITY && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(PreShipmentField.ADDRESS)}
              style={{ minWidth: "15rem" }}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Dirección Destino</span>
                <div className="h-4 w-4">
                  {sortBy.field == PreShipmentField.ADDRESS && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(PreShipmentField.PIECES)}
            >
              <div className="flex gap-2 items-center cursor-pointer truncate">
                <span>Total Piezas</span>
                <div className="h-4 w-4">
                  {sortBy.field == PreShipmentField.PIECES && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(PreShipmentField.WEIGHT)}
            >
              <div className="flex gap-2 items-center cursor-pointer truncate">
                <span>Total Kilos</span>
                <div className="h-4 w-4">
                  {sortBy.field == PreShipmentField.WEIGHT && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th className="px-4 py-2" />
          </tr>
        </thead>
        <tbody>
          {preShipments.map((preShipment, index) => (
            <tr
              key={preShipment.id}
              className={classNames(
                "hover:bg-gray-200",
                index % 2 == 0 && "bg-gray-100"
              )}
            >
              <td className="px-4 py-1 font-semibold">{preShipment.shipmentNumber}</td>
              <td className="px-4 py-1">{preShipment.consignee.accountFullName}</td>
              <td className="px-4 py-1">
                {preShipment.consignee.abbreviationName}
                {preShipment.consignee.identificationNumber}
              </td>
              <td className="px-4 py-1">{preShipment.consigneeLocation?.name}</td>
              <td className="px-4 py-1">{preShipment.consignee.fiscalAddress}</td>
              <td className="px-4 py-1">{preShipment.totalPieces}</td>
              <td className="px-4 py-1">{preShipment.totalWeight}</td>
              <td className="px-4">
                <Popover button={TableOptionsButton} containerClassname="!-mt-3">
                  <div className="flex flex-col font-semibold cursor-pointer">
                    <div className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white">
                      <div className="flex gap-2 py-2 px-4 w-32 items-center"
                      onClick={() => {
                        setSelected(preShipment);
                        setOpenModal(true);
                      }}
                      >
                        <PrinterIcon className="w-5 h-5" />
                        <span>Imprimir</span>
                      </div>
                    </div>
                  </div>
                </Popover>
              </td>
            </tr>
          ))}

          {preShipments.length == 0 && (
            <tr>
              <td colSpan={7} className="px-4 py-4 text-center">
                Parece que aún no tienes preguías.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {!!selected && (
        <PresipmentImpresionModal
          open={openModal}
          shipment={selected}
          setOpen={setOpenModal}
          onPrint={() => {}}
        />
      )}


    </div>
    
  );
};
