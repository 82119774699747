import { FC, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { FormikErrors } from "formik";
import { inputClassName } from "./FormText";
import {
  CloudArrowUpIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { FormLabel } from "./FormLabel";
import { Combobox } from "@headlessui/react";
import React from "react";
import LoadingIcon from "../LoadingIcon";
import { PrimaryButton } from "../Atoms/Buttons";

interface FormFileProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  label?: string;
  selected?: File;
  description?: string;
  containerClassname?: string;
  labelContainerClassname?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  onSelectFile: (file?: File) => void;
}
export const FormFile: FC<FormFileProps> = ({
  label = "",
  name,
  error,
  disabled,
  selected,
  description,
  className,
  containerClassname,
  labelContainerClassname,
  onSelectFile,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);

  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();

    // Verify that the mouse is not over the input
    const rect = labelRef.current?.getBoundingClientRect();
    if (
      !!rect &&
      e.clientX >= rect.left &&
      e.clientX <= rect.right &&
      e.clientY >= rect.top &&
      e.clientY <= rect.bottom
    ) {
      return;
    }
    setIsDraggingOver(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setIsDraggingOver(false);

    const file = e.dataTransfer.files?.[0];
    if (!!file && !!inputRef.current) {
      inputRef.current.files = e.dataTransfer.files;
      onSelectFile(file);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = (e.target as HTMLInputElement).files?.[0];
    if (!!file) {
      onSelectFile(file);
    }
  };

  const handleClick = (event: React.MouseEvent) => {
    // event.preventDefault();
    event.stopPropagation();
    inputRef.current?.click();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key == "Enter") {
      inputRef.current?.click();
    }
  };

  return (
    <div
      className={classNames("flex flex-col gap-4 w-full", containerClassname)}
    >
      <label
        tabIndex={0}
        ref={labelRef}
        htmlFor="dropzone-file"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        className={classNames(
          isDraggingOver ? "bg-gray-200" : "bg-gray-50",
          "flex flex-col items-center justify-center w-full p-6 border-2 border-main-400 border-dashed rounded-lg cursor-pointer hover:bg-gray-200",
          labelContainerClassname
        )}
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <CloudArrowUpIcon className="w-12 h-12 mb-4 text-main-500" />
          <p className="mb-2 text-sm text-gray-500 text-center">
            <span className="font-semibold text-main-500">{label}</span>
            <br />
            <span>
              <span className="font-semibold">Haz click</span> para subir o
              arrastra y suelta tu archivo aquí
            </span>
          </p>
          <p className="text-xs text-gray-500 text-center">{description}</p>
        </div>
      </label>

      <div>
        <input
          {...props}
          type="file"
          id="dropzone-file"
          name={name}
          ref={inputRef}
          disabled={disabled}
          onChange={handleChange}
          className="hidden"
        />

        <div
          tabIndex={0}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          className={classNames(
            inputClassName,
            !!error && "ring-red-500",
            disabled && "bg-gray-100 text-gray-500",
            "flex items-center relative px-4 pr-11 cursor-pointer hover:bg-gray-100",
            className
          )}
        >
          {!!selected?.name ? (
            <span className="truncate">{selected.name}</span>
          ) : (
            <span className="text-gray-400 truncate">
              No se ha seleccionado ningún archivo
            </span>
          )}

          {!selected && (
            <MagnifyingGlassIcon
              className="cursor-pointer absolute right-3 h-5 w-5 text-gray-400 hover:text-main-500"
              aria-hidden="true"
              onClick={handleClick}
              style={{ top: "calc(50% - 0.6rem)" }}
            />
          )}

          {selected && (
            <XMarkIcon
              className="cursor-pointer absolute right-3 h-5 w-5 text-gray-400 hover:text-main-500"
              aria-hidden="true"
              onClick={(event) => {
                event.stopPropagation();
                onSelectFile(undefined);
              }}
              style={{ top: "calc(50% - 0.6rem)" }}
            />
          )}
        </div>

        {typeof error == "string" && !!error && (
          <span className="mt-2 text-sm text-red-500">{error}</span>
        )}
        {Array.isArray(error) && (
          <span className="mt-2 text-sm text-red-500">{error.join(", ")}</span>
        )}
      </div>
    </div>
  );
};

interface SearchProps<T> extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string;
  label?: string;
  name?: string;
  disabled?: boolean;
  placeholder?: string;
  notFound?: string;
  isRequiredLabel?: boolean;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeFocus?: (focus: boolean) => void;
  onSelectOption?: (opt: T) => void;
  filter?: (opt: T, value: string) => boolean;
  onClick?: () => void;
  RenderOption: ({ option }: { option: T }) => JSX.Element;
  LastOption?: () => JSX.Element;
}

interface FormGroupSearchProps<T> extends SearchProps<T> {
  optionGroups: { title: string; loader?: boolean; options: T[] }[];
  clickeable?: boolean;
}
export const FormGroupSearch = <T extends any>({
  value,
  optionGroups,
  label,
  name,
  error,
  isRequiredLabel,
  disabled = false,
  clickeable = false,
  placeholder = "Buscar...",
  notFound = "Elementos no encontrados",
  onChange,
  onClick = () => {},
  onSelectOption = () => {},
  onChangeFocus = () => {},
  filter = () => true,
  RenderOption,
  LastOption = undefined,
  ...props
}: FormGroupSearchProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [focused, setFocused] = useState(false);
  const [searching, setSearching] = useState(false);

  const filteredOptionGroups = useMemo(() => {
    return optionGroups
      .map((options) => ({
        title: options.title,
        loader: options.loader,
        options: options.options.filter((option) => filter(option, value)),
      }))
      .filter(
        (optionGroup) => !!optionGroup.loader || optionGroup.options.length > 0
      );
  }, [optionGroups, value, filter]);

  const displayDropdown = useMemo(() => {
    const show = focused && (!clickeable || searching);

    return (
      (filteredOptionGroups.length > 0 && show) ||
      (show && filteredOptionGroups.length == 0) ||
      (show && !!LastOption)
    );
  }, [filteredOptionGroups, value, focused, LastOption]);

  const handleClick = () => {
    if (clickeable) {
      onClick();
      setFocused(true);
      setSearching(true);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setFocused(false);
        setSearching(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setFocused]);

  useEffect(() => {
    onChangeFocus(focused);
  }, [focused, onChangeFocus]);

  return (
    <div ref={ref}>
      {!!label && (
        <FormLabel
          label={label}
          htmlFor={props.id}
          isRequired={isRequiredLabel}
        />
      )}

      <div
        className={classNames(
          "divide-y divide-gray-100 rounded-xl bg-white shadow-sm ring-1 ring-black ring-opacity-5",
          displayDropdown && "rounded-b-none"
        )}
      >
        <Combobox
          onChange={(option: T) => {
            onSelectOption(option);
            setFocused(false);
            setSearching(false);
          }}
        >
          <div className="relative flex flex-1 flex-col items-center">
            <input
              {...props}
              value={value}
              disabled={disabled}
              placeholder={disabled ? "" : placeholder}
              onChange={onChange}
              onFocus={handleClick}
              className={classNames(
                inputClassName,
                "w-full placeholder:text-gray-400 ring-0 focus:ring-0 h-10 !h-8 ",
                displayDropdown && "rounded-b-none",
                disabled && "bg-gray-100 text-gray-500",
                clickeable ? "px-4" : "pl-5 pr-4",
                !!error && "ring-red-500"
              )}
              autoComplete="off"
            />
          </div>

          <div
            className={classNames(
              "w-full absolute z-40 divide-y divide-gray-100 overflow-hidden rounded-b-xl bg-white shadow-sm ring-1 ring-black ring-opacity-5",
              !displayDropdown && "hidden"
            )}
          >
            <div className="max-h-80 scroll-py-2 overflow-y-auto overflow-x-hidden ">
              {filteredOptionGroups.length > 0 &&
                filteredOptionGroups.map((optionGroup, index) => (
                  <React.Fragment key={index}>
                    <h2 className="bg-gray-100 px-4 py-4 text-xs font-semibold text-gray-900">
                      {optionGroup.title}
                    </h2>

                    <Combobox.Options
                      static
                      className="py-2 text-sm text-gray-800"
                    >
                      {!optionGroup.loader &&
                        optionGroup.options.map((option, index) => (
                          <Combobox.Option
                            key={index}
                            value={option}
                            className={({ active }) =>
                              classNames(
                                "cursor-pointer px-4 py-2",
                                active && "bg-main-500 text-white"
                              )
                            }
                          >
                            <RenderOption option={option} />
                          </Combobox.Option>
                        ))}

                      {!!optionGroup.loader && (
                        <div className="flex flex-1 items-center p-4">
                          <LoadingIcon size="2rem" />
                        </div>
                      )}
                    </Combobox.Options>
                  </React.Fragment>
                ))}

              {focused &&
                (!clickeable || searching) &&
                value !== "" &&
                filteredOptionGroups.length == 0 && (
                  <p className="p-4 text-sm text-gray-500">{notFound}</p>
                )}
            </div>

            {!!LastOption && focused && (!clickeable || searching) && (
              <Combobox.Options
                static
                className="max-h-72 scroll-py-2 text-sm text-gray-800"
              >
                <Combobox.Option
                  value={null}
                  className={({ active }) =>
                    classNames(
                      "cursor-pointer px-4",
                      active && "bg-main-500 text-white"
                    )
                  }
                >
                  <LastOption />
                </Combobox.Option>
              </Combobox.Options>
            )}
          </div>
        </Combobox>
      </div>

      {typeof error == "string" && !!error && (
        <span className="mt-2 text-sm text-red-500">{error}</span>
      )}
      {Array.isArray(error) && (
        <span className="mt-2 text-sm text-red-500">{error.join(", ")}</span>
      )}
    </div>
  );
};
