import { FC } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { PreShipmentInterface } from '../../interfaces';
import { PrimaryButton } from '../Atoms/Buttons';
import { ModalWidthFull } from '../Atoms/ModalWidthFull';
import { DowloadShipmentsMultiples } from '../../services/ShipmentServices';

interface shipmentMultiPrintModalProps {
  open: boolean;
  shipment: PreShipmentInterface[];
  onPrint: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  url: string;
  type: number;
}
interface ListShipmentDeclaredDocumentRequestInterfase {
  shipmentNumber: string;
  serviceID: string;
  bUCodeSource: string;
}

export const ShipmentMultiPrintModal: FC<shipmentMultiPrintModalProps> = ({
  open,
  shipment,
  onPrint,
  setOpen,
  url,
  type,
}) => {
  const fetchShipments = () => {
    if (shipment.length > 0) {
      var plusTen = [] as ListShipmentDeclaredDocumentRequestInterfase[];
      shipment.forEach((items) => {
        plusTen.push({
          shipmentNumber: items.number.toString(),
          serviceID: items?.service.toString() ?? '60',
          bUCodeSource: items?.businessUnitOrigin?.buCode.toString() ?? '',
        });
      });
      DowloadShipmentsMultiples(plusTen, url, type)
        .then((response) => response.blob())
        .then((blob) => {
          const file = window.URL.createObjectURL(blob);
          const iframe = document.querySelector('iframe');
          if (iframe?.src) iframe.src = file;
        });
    }
  };

  fetchShipments();

  return (
    <ModalWidthFull
      open={open}
      setOpen={setOpen}
      className="flex flex-1 mx-4 my-8"
      style={{ maxWidth: '90rem', height: '50rem' }}
    >
      <div className="flex flex-1 flex-col">
        {/* Header */}
        <div className="flex flex-1 items-center justify-between">
          <h2 className="text-xl font-bold">
            <span className="text-gray-700 hidden sm:inline">
              Impresión de guías:{' '}
            </span>
          </h2>

          <div className="ml-3 flex h-7 items-center">
            <button
              type="button"
              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-main-500"
              onClick={() => setOpen(false)}
            >
              <span className="absolute -inset-2.5" />
              <span className="sr-only">Close panel</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>

        <hr className="border-gray-300 my-3" />

        {/* Shipment Data */}

        <iframe src="" width="100%" height="100%"></iframe>
        <hr className="border-gray-300 my-6" />

        {/* Footer */}
        <div className="flex flex-1 justify-between items-center">
          <PrimaryButton onClick={() => setOpen(false)}>
            <span className="w-20">Cerrar</span>
          </PrimaryButton>
        </div>
      </div>
    </ModalWidthFull>
  );
};
