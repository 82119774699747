import { UserInterface } from "../interfaces";
import axios from "axios";
import { alertService } from "./alertService";
const baseUrl = process.env.REACT_APP_URL_PATH_API;

export const loginService = async (
  login: string,
  password: string,
  setError: React.Dispatch<React.SetStateAction<string>>
): Promise<UserInterface | undefined> => {
  let user: UserInterface = {
    id: "",
    number: 0,
    abbreviationName: "",
    identificationNumber: "",
    name: "",
    lastName: "",
    email: "",
    phone: "",
    login: "",
    profileName: "",
    profileID: 0,
    businessUnits: [],
  };

  const uri = `${baseUrl}/Account/Login?withUserDetails=true&applicationCode=${"020"}`;
  const body = JSON.stringify({ login, password });
  try {
    const response = await axios.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status == 200) {
      const responseUser = response.data;
      user = {
        id: responseUser.userID,
        number: 1,
        abbreviationName: "V-",
        identificationNumber: "15976398",
        name: responseUser.userName,
        lastName: responseUser.userLastName,
        email: responseUser.userEmail,
        phone: "0",
        login: login,
        profileName: responseUser.roleName,
        profileID: responseUser.profileID,
        businessUnits: [],
      };
    }
  } catch (error: any) {
    if (error.response.data?.error.length == 1) {
      setError(error.response.data?.error[0]);
    }
    return undefined;
  }

  return user;
};
