import { FC } from "react";
import moment from "moment";
import playIcon from "@iconify/icons-mdi/play";
import { Icon, IconifyIcon } from "@iconify/react";
import twitterIcon from "@iconify/icons-mdi/twitter";
import youtubeIcon from "@iconify/icons-mdi/youtube";
import facebookIcon from "@iconify/icons-mdi/facebook";
import instagramIcon from "@iconify/icons-mdi/instagram";
import contactUs from "../../assets/images/contact-us-banner.jpg";

interface ContactUsIconProps {
  ContactIcon: string | IconifyIcon;
  href?: string;
}

const { REACT_APP_VERSION } = process.env;

const ContactUsIcon: FC<ContactUsIconProps> = ({ ContactIcon, href }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className="rounded-full border-2 border-main-500 text-main-500 hover:bg-main-500 hover:text-white bg-white p-1.5 cursor-pointer"
    >
      <Icon icon={ContactIcon} className="h-5 w-5 sm:h-7 sm:w-7" />
    </a>
  );
};

export const Footer: FC = () => {
  return (
    <div className="flex flex-1 py-3 px-2 sm:px-6 justify-between bg-gray-100 border gap-6">
      {/* App information */}
      <div>
        <span className="text-sm text-gray-500 font-semibold">
          © Tealca - {new Date().getFullYear()}
        </span>

        <div className="flex flex-col text-xs text-gray-500 truncate">
          <span>Versión CCW: {REACT_APP_VERSION}</span>
          <span>Versión Servicios: 2.0.0.0</span>
          <span>{moment(new Date()).format("DD/MM/YYYY")}</span>
        </div>
      </div>

      {/* Contact us */}
      <div className="flex items-center justify-end gap-4">
        <div className="flex gap-1 flex-wrap justify-center">
          <ContactUsIcon
            ContactIcon={twitterIcon}
            href="https://twitter.com/tealcavenezuela"
          />
          <ContactUsIcon
            ContactIcon={facebookIcon}
            href="https://www.facebook.com/Tealca/"
          />
          <ContactUsIcon
            ContactIcon={youtubeIcon}
            href="https://www.youtube.com/channel/UCDJvENro9ovPB0WYUVK7H8w"
          />
          <ContactUsIcon
            ContactIcon={instagramIcon}
            href="https://www.instagram.com/tealcavenezuela/"
          />
          <ContactUsIcon
            ContactIcon={playIcon}
            href="https://www.youtube.com/embed?&controls=1&showinfo=0&rel=0&listType=user_uploads&list=TealcaEnvios"
          />
        </div>

        <div className="hidden lg:block">
          <img src={contactUs} alt="Contáctanos" width="550" />
        </div>
      </div>
    </div>
  );
};
