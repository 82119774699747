import { FC } from "react";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { FormText } from "../FormFields/FormText";
import { LinkText, PrimaryButton } from "../Atoms/Buttons";

export interface LoginFormValues {
  id: string;
  password: string;
}
interface FormInterface {
  onRecoversPassword?: (id: string) => void;
  formik: FormikProps<LoginFormValues>;
}
const Form: FC<FormInterface> = ({ onRecoversPassword, formik }) => {
  return (
    <form onSubmit={formik.handleSubmit} className="flex h-full flex-col gap-6">
      <div className="flex h-full flex-col gap-4">
        <FormText
          id="id"
          name="id"
          label="Usuario"
          value={formik.values.id}
          error={
            formik.touched.id && formik.errors.id ? formik.errors.id : undefined
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <div>
          <FormText
            id="password"
            name="password"
            type="password"
            label="Contraseña"
            value={formik.values.password}
            error={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <LinkText
            text="Recordar Contraseña"
            onClick={() => {
              if (onRecoversPassword) {
                onRecoversPassword(formik.values.id);
              }
            }}
            className="text-sm"
          />
        </div>

        <div className="w-full">
          <PrimaryButton type="submit" className="w-full">
            Ingresar
          </PrimaryButton>
        </div>
      </div>
    </form>
  );
};

interface LoginFormProps {
  initialValues?: LoginFormValues;
  onRecoversPassword?: (id: string) => void;
  onSubmit?: (prop: LoginFormValues) => void;
}
export const LoginForm: FC<LoginFormProps> = ({
  initialValues = { id: "", password: "" },
  onRecoversPassword = (id) => {},
  onSubmit = () => {},
}) => {
  // Validations with Yup for Formik form
  const validationSchema = Yup.object().shape({
    id: Yup.string().required("Este campo es requerido"),
    password: Yup.string().required("Este campo es requerido"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values);
        resetForm();
      }}
    >
      {(formik) => (
        <Form formik={formik} onRecoversPassword={onRecoversPassword} />
      )}
    </Formik>
  );
};
