import { FC } from "react";
import { MainPage, PageTitle } from "../../components";

const SentMessages: FC = () => {
  return (
    <MainPage>
      <PageTitle title="Mensajes Enviados" />
    </MainPage>
  );
};

export default SentMessages;
