import { FC, Fragment } from "react";
import { Popover as PopoverHeadless, Transition } from "@headlessui/react";
import classNames from "classnames";

interface PopoverProps {
  button: React.ReactNode;
  containerClassname?: string;
  children: React.ReactNode;
}
export const Popover: FC<PopoverProps> = ({
  button,
  containerClassname,
  children,
}) => {
  return (
    <PopoverHeadless.Group className="flex gap-x-12">
      <PopoverHeadless>
        <PopoverHeadless.Button className="flex items-center focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main-400 rounded-md">
          {button}
        </PopoverHeadless.Button>

        <div className="relative">
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <PopoverHeadless.Panel
              className={classNames(
                "absolute right-0 top-full z-10 mt-2 max-w-md overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-gray-900/5",
                containerClassname
              )}
            >
              <div>{children}</div>
            </PopoverHeadless.Panel>
          </Transition>
        </div>
      </PopoverHeadless>
    </PopoverHeadless.Group>
  );
};
