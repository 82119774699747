import {
  DeliveryEnum,
  ItemDTO,
  ItemInterface,
  ItemsRateDTO,
  LocationInterface,
  PaymentModeId,
  PieceInterface,
  ShipmentInterface,
  ShipmentResponseDto,
  ShipmentResponseSearchDto,
} from '../interfaces';
import { ShipmentMapperDto, ShipmentSearchMapperDto } from '../utils/mappers';
import { AuthenticationServices } from './AuthenticationServices';
import axios from 'axios';
import { ListResponse } from '../interfaces/Shipment/ListResponseDto';
import { ServiceEnum } from '../interfaces/Services/ServicesEnum';
const { REACT_APP_URL_PATH_API } = process.env;

export const getUserShipments = async (
  accountCode: string,
  accountId?: string,
  min: number = 10,
  max?: number,
  search?: string,
  DateFrom?: string,
  DateEnd?: string,
  typeSearch?: number
): Promise<ShipmentInterface[]> => {
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  const response =
    await axios.get(`${REACT_APP_URL_PATH_API}/v2/Shipment/SearchShipmentFilter?accountCode=${
      accountCode ?? ''
    }
  &MaxRow=${max}&Search=${search}&DateFrom=${DateFrom ?? ''}&DateEnd=${
      DateEnd ?? ''
    }&AccountID=${accountId ?? ''}&typeSearch=${typeSearch ?? ''}`);
  if (
    response.status === 200 &&
    !response.data.didError &&
    !!response.data.model
  ) {
    var datamap = response.data.model.map((dto: ShipmentResponseSearchDto) =>
      ShipmentSearchMapperDto(dto)
    );
    return datamap;
  }
  return [];
};

export const createShipmentApi = async (
  preShipment: ShipmentInterface[]
): Promise<ShipmentInterface> => {
  let shipmentId = '';
  let shipmentNumber = '';
  let dirError = false;
  let errorMessage = '';
  try {
    let datamap = preShipment.map((dto: ShipmentInterface) =>
      ShipmentMapperDto(dto)
    );
    const token = await AuthenticationServices();
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    await axios
      .post(`${REACT_APP_URL_PATH_API}/v2/Shipment`, datamap[0])
      .then(function (response) {
        var data = response.data as ListResponse<ShipmentResponseDto>;
        if (!data.didError) {
          shipmentId = data.model?.shipmentHeaderID ?? '';
          shipmentNumber = data.model?.shipmentNumber ?? '';
        } else {
          alert('Error al crear guia ' + data.errorMessage);
        }
      })
      .catch(function (error) {
        dirError = true;
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          let errorData = error.response
            .data as ListResponse<ShipmentResponseDto>;
          errorMessage = errorData.errorMessage ?? '';
          console.log('Error generando guia:', errorData.errorMessage);
        } else {
          // Something happened in setting up the request that triggered an Error
          errorMessage = error.message ?? '';
          console.log('Error generando guia:', error.message);
        }
      });
  } catch (error) {
    console.log('Error generando guia:', error);
  }

  return {
    ...preShipment[0],

    id: shipmentId,
    number: Math.floor(100 + Math.random() * 900),
    shipmentNumber: shipmentNumber,
    didError: dirError,
    errorMessage: errorMessage,
    date: new Date().toISOString(),
  };
};

export const createShipmentMasiveApi = async (
  preShipment: ShipmentInterface[]
): Promise<ListResponse<ShipmentResponseDto[]>> => {
  let dirError = false;
  let errorMessage = '';
  try {
    let datamap = preShipment.map((dto: ShipmentInterface) =>
      ShipmentMapperDto(dto)
    );

    const token = await AuthenticationServices();
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    var response = await axios
      .post(`${REACT_APP_URL_PATH_API}/v2/Shipment/Masive`, { list: datamap })
      .then(function (response) {
        var data = response.data as ListResponse<ShipmentResponseDto>;
        if (!data.didError) {
          dirError = false;
        } else {
          alert('Error al importar las guias: ' + data.errorMessage);
        }
        return data;
      })
      .catch(function (error) {
        dirError = true;
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          let errorData = error.response
            .data as ListResponse<ShipmentResponseDto>;
          errorMessage = errorData.errorMessage ?? '';
          console.log('Error generando guia:', errorData.errorMessage);

          return errorData;
        } else {
          // Something happened in setting up the request that triggered an Error
          errorMessage = error.message ?? '';
          var data = {} as ListResponse<ShipmentResponseDto>;
          data.didError = true;
          data.errorMessage = 'Error generando guia:' + error.message;
          console.log('Error generando guia:', error.message);
          return data;
        }

        return error;
      });

    return response as ListResponse<ShipmentResponseDto[]>;
  } catch (error) {
    console.log('Error generando guia:', error);
  }

  return {} as ListResponse<ShipmentResponseDto[]>;
};

export const DowloadShipments = async (
  ShipmentNumber: string
): Promise<any> => {
  const token = await AuthenticationServices();
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ ShipmentNumber: ShipmentNumber }),
  };
  return fetch(
    `${REACT_APP_URL_PATH_API}/v1/Shipment/DownloadDocumentDeclared`,
    requestOptions
  );
};

export const DowloadShipmentsPieces = async (
  ShipmentNumber: number,
  serviceId: number,
  buCodeSource: string,
  type: number
): Promise<any> => {
  const token = await AuthenticationServices();
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      ShipmentNumber: ShipmentNumber.toString(),
      ServiceID: serviceId.toString(),
      BUCodeSource: buCodeSource.toString(),
      type,
    }),
  };
  return fetch(
    `${REACT_APP_URL_PATH_API}/v1/Shipment/DownloadDocumentPieces`,
    requestOptions
  );
};

export const DowloadShipmentsMultiples = async (
  ShipmentNumberList: any[],
  url: string,
  type: number
): Promise<any> => {
  const token = await AuthenticationServices();
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ List: ShipmentNumberList, type }),
  };
  return fetch(`${REACT_APP_URL_PATH_API}${url}`, requestOptions);
};

export const getShipmentsExists = async (
  shipmentCode: string
): Promise<string> => {
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  const response = await axios.get(
    `${REACT_APP_URL_PATH_API}/v2/Shipment/SearchShipmentExists?numbersGuides=${shipmentCode}`
  );
  if (
    response.status === 200 &&
    !response.data.didError &&
    !!response.data.model
  ) {
    return response.data.model;
  }
  return '';
};

export const getShipmentRates = async (
  service: ServiceEnum,
  paymentMode: PaymentModeId,
  deliveryType: DeliveryEnum,
  buCodeConsignee: string,
  sourceLocation: LocationInterface,
  consigneeLocation: LocationInterface,
  items: ItemInterface[],
  pieces: PieceInterface[],
  srm: boolean,
  accountID?: string,
  shipmentAccountId?: string
): Promise<ListResponse<ItemsRateDTO>> => {
  const uri = `${REACT_APP_URL_PATH_API}/GetShipmentRateV2Async`;
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const body = JSON.stringify({
    srm,
    accountID,
    countryID: 236,
    serviceID: parseInt(service),
    paymentModeID: parseInt(paymentMode),
    deliveryTypeID: parseInt(deliveryType),
    shippingMethodID: 10,
    buCodeConsignee,
    latitudeSource: sourceLocation.coordinates.lat,
    longitudeSource: sourceLocation.coordinates.lng,
    latitudeDestination: consigneeLocation.coordinates.lat,
    longitudeDestination: consigneeLocation.coordinates.lng,
    itemList: items.map((item) => ({
      itemID: item.id,
      itemName: item.name,
    })),
    pieceList: pieces.map((piece) => ({
      weight: piece.weight,
      width: piece.width,
      height: piece.height,
      length: piece.length,
      declaratedValue: piece.declaredValue ?? 0,
    })),
    ShipperAccountID: shipmentAccountId,
  });

  try {
    const response = await axios.post(uri, body, {
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return {
        message: '',
        didError: false,
        errorMessage: '',
        model: {
          distance: response.data.distance,
          chargedWeight: response.data.chargedWeight,
          physicalWeight: response.data.physicalWeight,
          dimensionalWeight: response.data.dimensionalWeight,
          items: items.map((item) => {
            const itemRate = response.data.items.find(
              (rate: any) => +rate.itemID === +item.id
            );
            return {
              ...item,
              // TODO: Refactor mapping to include amounts in base currency and tusd
              rate: {
                value: itemRate ? itemRate.rateResult.amountBaseCurr : 0,
                iva: itemRate ? itemRate.rateResult.itemTaxAmountBaseCurr : 0,
                ipostel: itemRate
                  ? itemRate.rateResult.postalTaxAmountBaseCurr
                  : 0,
                isPercentage: itemRate
                  ? itemRate.rateResult.isPercentage
                  : false,
                distance: itemRate ? itemRate.rateResult.distance : 0,
                tierCode: itemRate ? itemRate.rateTier : '',
                tierName: itemRate ? itemRate.tierName : '',
              },
            };
          }),
        },
      };
    }
  } catch (error) {
    const response = (error as any)?.response?.data;

    if (typeof response?.errorMessage === 'string') {
      return response;
    }
  }

  return {
    model: null,
    didError: true,
    message: 'Hubo un error al obtener los precios de los servicios.',
    errorMessage: 'Hubo un error al obtener los precios de los servicios.',
  };
};

export const getShipmentItems = async (
  service: ServiceEnum,
  paymentMode: PaymentModeId,
  deliveryType: DeliveryEnum,
  srm: boolean,
  shipmentAccountId?: string
): Promise<ListResponse<ItemInterface[]>> => {
  const uri = `${REACT_APP_URL_PATH_API}/SearchItemList?ServiceID=${service}&PaymentModeID=${paymentMode}&DeliveryTypeID=${deliveryType}&SRM=${srm}&ShipperAccountID=${
    shipmentAccountId ?? ''
  }`;

  try {
    const token = await AuthenticationServices();
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

    const response = await axios.get(uri);

    if (response.status === 200) {
      return {
        ...response.data,
        model: response.data.model.map((item: ItemDTO) => ({
          id: item.itemID.toString(),
          code: item.itemCode,
          name: item.itemDescription,
          order: item.itemOrder,
          mandatory: item.mandatory,
          rate: {
            value: 0,
            isPercentage: false,
            tax: 0,
          },
        })),
      };
    }
  } catch (error) {
    return (error as any)?.response?.data;
  }

  return {
    model: null,
    didError: true,
    message: 'Hubo un error al obtener los servicios del envío.',
    errorMessage: 'Hubo un error al obtener los servicios del envío.',
  };
};
