import { FC, useState, useEffect } from 'react';
import { XMarkIcon,PrinterIcon } from "@heroicons/react/24/outline";
import { PrimaryButton } from "../Atoms/Buttons";
import { ModalWidthFull } from "../Atoms/ModalWidthFull";
import { DowloadDispatch } from '../../services/DispatchServices';
import { useAppSelector } from '../../store/hooks';
import { ConfirmModalStatus } from '../Preshipment/ModalSuccess';
import { ModalLoading } from '../Atoms/ModalLoading';

interface DispatchPrintModalProps {
  open: boolean;
  numberDispatch: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DispatchPrintModal: FC<DispatchPrintModalProps> = ({
  open,
  numberDispatch,
  setOpen,
}) => {


  const user = useAppSelector((state) => state.user);
  const [typeUrl, setTypeUrl] = useState("1");    
  let [loading, setLoading] = useState(false);
  let [loadingStatus, setLoadingStatus] = useState(ConfirmModalStatus.PROCESSING);
  let [loadingTitle, setLoadingTitle] = useState("Cargando...");



  useEffect(() =>{   
    const fetchShipments =  () => {
      if(numberDispatch !== null && numberDispatch !== "" && typeUrl!=="") {
        setLoadingStatus(ConfirmModalStatus.PROCESSING);
        setLoadingTitle("Cargando impresión...");
        setLoading(true);
  
        DowloadDispatch(numberDispatch,typeUrl, (user?.user?.login ?? "ccw"))
        .then((response) => response.blob())
        .then((blob) => {              
              const file = window.URL.createObjectURL(blob);
              const iframe = document.querySelector("iframe");
              if (iframe?.src) iframe.src = file;
              setLoading(false);
        })
        .catch((error) => {
          setLoadingStatus(ConfirmModalStatus.ERROR);
          setLoadingTitle("Error: intente mas tarde, si persiste contacte a soporte tecnico  ");
          setLoading(true);
  
        });
      }   
    };   
    fetchShipments();
  },[typeUrl,open]);  

  return (
    <div>
       <ModalWidthFull
          open={open}
          setOpen={setOpen}
          className="flex flex-1 mx-4 my-8"
          style={{ maxWidth: "90rem", height: "50rem"}}
        >
          <div className="flex flex-1 flex-col">
            {/* Header */}
            <div className="flex flex-1 items-center justify-between">
              <h2 className="text-xl font-bold">
                <span className="text-gray-700 hidden sm:inline">
                  Impresión de despacho {typeUrl=="1"?"agrupado":"detallado"} :{" "}
                </span>
                <span className="text-main-500">
                  {numberDispatch}
                </span>
              </h2>

              <div className="ml-3 flex h-7 items-center">
                  <button
                    type="button"
                    className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-main-500"
                    onClick={() => setOpen(false)}
                  >

                  <span className="absolute -inset-2.5" />
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>

            <hr className="border-gray-300 my-3" />


            {/* Shipment Data */}

            <iframe src="" width="100%" height="100%"></iframe>
            <hr className="border-gray-300 my-6" />

            {/* Footer */}
            <div className="flex flex-1 items-center">
              <div className='flex flex-1 justify-start'>
                <PrimaryButton onClick={() => {
                  setTypeUrl("1")
                }} disabled={typeUrl=="2"?false:true}>
                  Despacho Agrupado <PrinterIcon className="h-3 w-3 ml-2 mt-1" aria-hidden="true" />
                </PrimaryButton>    

                <PrimaryButton onClick={() => {
                  setTypeUrl("2")
                
                }} className='ml-5' disabled={typeUrl=="1"?false:true}>
                  Despacho Detallado <PrinterIcon className="h-3 w-3 ml-2 mt-1" aria-hidden="true" />
                </PrimaryButton>            
              </div>
              <div className='flex flex-1 justify-end'>
                <PrimaryButton onClick={() => setOpen(false)}>
                  <span className="w-20">Cerrar</span>
                </PrimaryButton>            
              </div>
            </div>
          </div>
        </ModalWidthFull>
        <ModalLoading open={loading} title={loadingTitle} status={loadingStatus} setOpen={setLoading} onPrint={() => {}}/>
    </div>
   
  );
};
