import { FC, useEffect, useState } from "react";
import { Modal } from "../Atoms/Modal";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { PrimaryButton } from "../Atoms/Buttons";
import LoadingIcon from "../LoadingIcon";
import { ConfirmModalStatus } from "../Preshipment/ModalSuccess";
import logo from "../../assets/images/TealcaLogo.png";
import classNames from "classnames";

interface ModalLoadingProps {
  open: boolean;
  title: string;
  status: ConfirmModalStatus;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onPrint: () => void;
  classNames?: string;
  timeOut?: number;
}

export const ModalLoading: FC<ModalLoadingProps> = ({
  open,
  title,
  setOpen,
  status,
  classNames,
  onPrint,
  timeOut,
}) => {
  let [configData, setConfigData] = useState({
    maxWidth: "12rem",
    height: "12rem",
  });

  useEffect(() => {
    if (
      status == ConfirmModalStatus.ERROR ||
      status == ConfirmModalStatus.SUCCESS
    ) {
      setTimeout(() => {
        setOpen(false);
      }, timeOut ?? 5000);
    }

    if (
      status == ConfirmModalStatus.PRINT ||
      (status == ConfirmModalStatus.ERROR && title.length > 15)
    ) {
      setConfigData({ maxWidth: "15rem", height: "12rem" });
    }

    if (status == ConfirmModalStatus.SUCCESS && title.length > 60) {
      setConfigData({ maxWidth: "40rem", height: "12rem" });
    }

    if (status == ConfirmModalStatus.WARNING && title.length > 120) {
      setConfigData({ maxWidth: "50rem", height: "12rem" });
    }
  }, [status]);

  return (
    <Modal
      open={open}
      setOpen={() => {}}
      className="flex flex-1 mx-4 my-4 pt-10"
      style={configData}
    >
      {/* Data */}

      {status == ConfirmModalStatus.ERROR && (
        <div className="flex flex-1 flex-col items-center justify-center content-center">
          <div className="flex flex-col items-center justify-center w-full">
            <XCircleIcon className="text-red-600 w-20 h-20" />
          </div>
          <p className="mt-2 text-center text-red-600 font-bold ">{title}</p>
        </div>
      )}

      {status == ConfirmModalStatus.SUCCESS && (
        <div className="flex flex-1 flex-col items-center justify-center content-center">
          <div className="flex flex-col items-center justify-center w-full">
            <CheckCircleIcon className="text-green-600 w-20 h-20" />
          </div>
          <p className="mt-2 text-center font-bold ">{title}</p>
        </div>
      )}

      {status == ConfirmModalStatus.PRINT && (
        <div className="flex flex-1 flex-col items-center justify-center content-center">
          <div className="flex flex-col items-center justify-center w-full">
            <CheckCircleIcon className="text-green-600 w-20 h-20" />
          </div>
          <p className="mt-2 text-center font-bold ">{title}</p>
          <div className="mt-4 flex flex-row justify-center gap-4">
            <PrimaryButton
              className="px-4"
              onClick={() => {
                onPrint();
              }}
            >
              Imprimir
            </PrimaryButton>
            <PrimaryButton
              className="px-4"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cerrar
            </PrimaryButton>
          </div>
        </div>
      )}

      {status == ConfirmModalStatus.WARNING && (
        <div className="flex flex-1 flex-col items-center justify-center content-center">
          <div className="flex flex-col items-center justify-center w-full">
            <CheckCircleIcon className="text-green-600 w-20 h-20" />
          </div>
          <p className="mt-2 text-center font-bold ">{title}</p>
          <div className="mt-4 flex flex-row justify-center gap-4">
            <PrimaryButton
              className="px-4"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cerrar
            </PrimaryButton>
          </div>
        </div>
      )}

      {status == ConfirmModalStatus.PROCESSING && (
        <div className="flex flex-1 flex-col items-center justify-center content-center">
          <img
            src={logo}
            className="animate-pulse -mt-11 -ml-1"
            alt="Tealca logo"
            width="60"
            height="60"
            style={{ position: "absolute" }}
          />
          <LoadingIcon size="7rem" />
          <label className="mt-4 text-center animate-pulse font-bold ">
            {title}
          </label>
        </div>
      )}
    </Modal>
  );
};

export default ModalLoading;
