import axios from 'axios';
import { AuthenticationServices } from "./AuthenticationServices";
import { TransportInterface } from '../interfaces';
const {REACT_APP_URL_PATH_API} = process.env;

export const getTransport = async (
  buCodeSource: string
): Promise<TransportInterface[] | undefined> => {
  const token = await AuthenticationServices();    
  axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
  var response = await axios.get(`${REACT_APP_URL_PATH_API}/Transports?BUCode=${buCodeSource}`,) 
  .then(function (response) {
    var responsedto = response.data ;
    return responsedto as TransportInterface[];
  })
  .catch(function (error) {
    if (error.response) {
      console.log(error.message ?? "")
      return [] as unknown as TransportInterface[];      
    } else {
      console.log(error.message ?? "")
    }

    return [] as unknown as TransportInterface[];
  });
  return response;
};