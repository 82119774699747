import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { MainPage, PageTitle, PrimaryButton } from "../../components";

const ConsultPOD: FC = () => {
  const navigate = useNavigate();

  return (
    <MainPage>
      <PageTitle title="POD" />

      <div className="flex flex-col items-center px-4 py-6 mt-4 bg-white rounded-lg border shadow-sm">
        <p className="text-center text-sm text-gray-600">No posee entregas registradas</p>
        <p className="text-center text-sm text-gray-600">
          ¿Desea registrar una nueva entrega?
        </p>
        <PrimaryButton
          onClick={() => navigate("/pod/load", { state: { back: true } })}
          className="mt-4"
        >
          Generar Entrega
        </PrimaryButton>
      </div>
    </MainPage>
  );
};

export default ConsultPOD;
