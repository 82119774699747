export enum ShipmentStatusEnum {
  ACTIVE = "Activo",
  INACTIVE = "Inactivo",
  DELETED = "Eliminado",
  PAID = "Pagado",
  PARTIAL_PAID = "Pagado Parcialmente",
  PENDING = "Pendiente",
  RECEIVED = "Recibido",
  PROCESSED = "Procesado",
  DELIVERED = "Entregado",
}

export enum SStatusEnum {
  ACTIVE = "1",
  INACTIVE = "2",
  DELETED = "3",
  PAID = "10",
  PARTIAL_PAID = "19",
  PENDING = "18",
  RECEIVED = "20",
  PROCESSED = "21",
  DELIVERED = "22",
}

