import { FC, useCallback, useEffect, useState } from 'react';
import { UserAccountsInterfase, UserSearchInterface } from '../../interfaces';
import { formatName } from '../../utils/format';
import { FormGroupSearch } from '../FormFields/FormFile';
import { UserPlusIcon } from '@heroicons/react/20/solid';
import { getFilteredUsers } from '../../services/UserServices';

interface UserAccountSearchProps {
  title: string;
  selected?: UserAccountsInterfase;
  disabled?: boolean;
  error?: string;
  placeholder?: string;
  hideCreate?: boolean;
  onChange?: (search: string) => void;
  onSelectClient: (option: UserAccountsInterfase) => void;
  openCreationModal: (open: boolean) => void;
}

export const UsersSearchHistoric: FC<UserAccountSearchProps> = ({
  title,
  error,
  selected,
  disabled,
  placeholder,
  onSelectClient,
  openCreationModal,
}) => {
  const [search, setSearch] = useState(' ');
  const [loader, setLoader] = useState(false);
  const [clients, setClients] = useState<UserAccountsInterfase[]>([]);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

  const getClientsData = useCallback(async (criteria: string) => {
    setLoader(true);
    const clients = await getFilteredUsers(criteria);
    if (!!clients) {
      setClients(clients);
    }
    setLoader(false);
  }, []);

  // Handle search by debouncing the event
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setSearch(inputValue);
  };

  // Trigger search on enter key
  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      getClientsData(search);
    }
  };

  useEffect(() => {
    setSearch(formatName(selected?.userName ?? ''));
  }, [selected]);

  useEffect(() => {
    if (timerId) {
      clearTimeout(timerId);
    }
    if (search) {
      const newTimerId = setTimeout(() => {
        getClientsData(search);
      }, 1000);

      setTimerId(newTimerId);
    } else {
      getClientsData('');
    }
  }, [search]);

  return (
    <div className="flex flex-1 flex-col">
      <div className="relative w-full">
        <FormGroupSearch<Partial<UserSearchInterface>>
          clickeable
          value={search}
          id={`search-favorite-historic-account-${title}`}
          label={title}
          error={error}
          disabled={disabled}
          placeholder={placeholder}
          optionGroups={[
            {
              loader,
              title: 'Usuarios',
              options: clients.map((account) => ({
                account,
              })),
            },
          ]}
          onChange={handleChange}
          onClick={() => {
            getClientsData(search);
          }}
          onSelectOption={(option) => {
            if (!option) return;

            if (!!option.account) {
              onSelectClient(option.account);
              setSearch(option.account.userLogin);
            }
          }}
          onChangeFocus={(focus) => {
            if (!focus) setSearch(formatName(selected?.userLogin ?? ''));
          }}
          RenderOption={({ option }) => <UsersOption {...option} />}
          LastOption={() => <AddUser onClick={() => openCreationModal(true)} />}
          onKeyDown={handleKeyDown}
          autoComplete="off"
        />
      </div>
    </div>
  );
};

export const filterDestination = (
  search: string,
  client?: UserSearchInterface
) => {
  const criteria = search.toLowerCase();

  return (
    client?.account.userName?.toLowerCase().includes(criteria) ||
    client?.account.userLogin?.toLowerCase().includes(criteria) ||
    client?.account.userIdentificationNumber?.toLowerCase().includes(criteria)
  );
};

const UsersOption: FC<Partial<UserSearchInterface>> = ({ account }) => {
  return (
    <div className="flex items-center">
      <div className="flex flex-col w-full">
        <p className="text-sm font-semibold leading-5 truncate">
          {account?.userName} {account?.userLastName}
        </p>
        <p className="text-sm leading-5">{account?.userLogin}</p>
        <p className="text-sm leading-5">{account?.profileName}</p>
      </div>
    </div>
  );
};

const AddUser: FC<{ onClick: React.MouseEventHandler<HTMLDivElement> }> = ({
  onClick,
}) => {
  return (
    <div className="flex flex-1 py-3 items-center opacity-75" onClick={onClick}>
      <UserPlusIcon className="h-5 w-5 mr-2 shrink-0" />

      <p className="text-sm truncate">Crear nuevo</p>
    </div>
  );
};
