import axios from 'axios';
import { AuthenticationServices } from "./AuthenticationServices";
import { ListResponse } from "../interfaces/Shipment/ListResponseDto";
import { RouteInterface } from '../interfaces';
const {REACT_APP_URL_PATH_API} = process.env;

export const getRoute = async (
  buCodeSource: string
): Promise<ListResponse<RouteInterface[]> | undefined> => {
  const token = await AuthenticationServices();    
  axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
  var response = await axios.post(`${REACT_APP_URL_PATH_API}/Routes/GetAll?BUCodeSource=${buCodeSource}`,{BUCodeSource: buCodeSource}) 
  .then(function (response) {
    var responsedto = response.data ;
    return responsedto as ListResponse<RouteInterface[]>;
  })
  .catch(function (error) {
    //dirError= true;
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //let  errorData = error.response.data;
      //errorMessage = errorData.message ?? "";
      console.log(error.message ?? "")
      return error.response as ListResponse<RouteInterface[]>;
    } else {
      // Something happened in setting up the request that triggered an Error
      //errorMessage = error.message ?? "";
      console.log(error.message ?? "")
    }
    return [] as unknown as ListResponse<RouteInterface[]>;
  });

  return response;
};