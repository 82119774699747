import { ReactNode, FC, ButtonHTMLAttributes } from "react";
import classNames from "classnames";

export const buttonClassname =
  "inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold " +
  "shadow-sm focus-visible:outline focus-visible:outline-2 " +
  "focus-visible:outline-offset-2 border-2 border-main-500";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
}
export const PrimaryButton: FC<ButtonProps> = ({
  children,
  className,
  disabled,
  ...props
}) => {
  return (
    <button
      className={classNames(
        buttonClassname,
        "bg-main-500 hover:bg-main-400 hover:border-main-400 text-white focus-visible:outline-main-400",
        className,
        disabled ? "pointer-events-none opacity-50" : ""
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export const SecondaryButton: FC<ButtonProps> = ({
  children,
  className,
  disabled,
  ...props
}) => {
  return (
    <button
      className={classNames(
        buttonClassname,
        className,
        disabled ? "pointer-events-none opacity-50" : "",
        "bg-white focus-visible:outline-main-400 text-main-500"
      )}
      {...props}
    >
      {children}
    </button>
  );
};

interface LinkTextProps {
  text: string;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}
export const LinkText: FC<LinkTextProps> = ({
  text,
  disabled,
  className,
  onClick,
}) => {
  return (
    <span
      onClick={onClick}
      className={classNames(
        "text-blue-500 focus:outline-none",
        disabled
          ? "opacity-50 pointer-events-none"
          : "hover:underline hover:cursor-pointer",
        className
      )}
    >
      {text}
    </span>
  );
};


export const LinkTextIcon: FC<ButtonProps> = ({
  children,
  className,
  disabled,
  ...props
}) => {
  return (
    <button
    className={classNames(
      "text-blue-500 focus:outline-none",
      disabled
        ? "opacity-50 pointer-events-none"
        : "text-blue-500 focus:outline-none hover:underline hover:cursor-pointer focus-visible:outline-main-400 text-main-500 group inline-flex items-center py-4 px-1 ",
      className
    )}
      {...props}
    >
      {children}
    </button>
  );
};

