import Login from "../modules/Auth/Login";
import Dashboard from "../modules/Dashboard";
import LoadPOD from "../modules/POD/LoadPOD";
import ConsultPOD from "../modules/POD/ConsultPOD";
import SentMessages from "../modules/Messages/SentMessages";
import Traceability from "../modules/Reports/Traceability";
import CreateShipment from "../modules/Shipments/CreateShipment";
import ShipmentImport from "../modules/Integration/ShipmentImport";
import RecievedMessages from "../modules/Messages/ReceivedMessages";
import ConsultShipments from "../modules/Shipments/ConsultShipments";
import EditDestination from "../modules/Destinations/EditDestination";
import CreatePreShipment from "../modules/PreShipments/CreatePreShipment";
import CreateDestination from "../modules/Destinations/CreateDestination";
import ConsultDestinations from "../modules/Destinations/ConsultDestinations";
import ConsultPreShipments from "../modules/PreShipments/ConsultPreShipments";
import ResetPassword from "../modules/Auth/ResetPassword";
import CreateShipmentV2 from "../modules/ShipmentV2/CreateShipmentV2";
import ConsultShipmentsV2 from "../modules/ShipmentV2/ConsultShipmentsV2";
import ConsultDispatch from "../modules/Dispatch/ConsultDispatch";
import CreateDispatch from "../modules/Dispatch/CreateDispatch";
import UserPasswordUpdate from "../modules/Auth/UserPasswordUpdate";
import ConsultFavoriteDestination from "../modules/FavoriteDestination/ConsultFavoriteDestination";
import CreationFavoriteDestination from "../modules/FavoriteDestination/CreationFavoriteDestination";
import ConsultAccountAuth from "../modules/AccountAuth/ConsultAccountAuth";
import CreationAccountAuth from "../modules/AccountAuth/CreationAccountAuth";

import ConsultUserAdministrator from "../modules/UserAdministrator/ConsultUserAdministrator";
import CreationUserAdministratos from "../modules/UserAdministrator/CreationUserAdministrador";
import ConsultUserClient from "../modules/UserAdministrator/ConsultUserClient";

interface AppRoute {
  /**
   * URL path
   */
  path: string;
  /**
   * If true, the route is only accessible if the user is authenticated
   */
  auth: boolean;
  /**
   * Template to render
   */
  element: React.ReactNode;
}

const AppRoutes: AppRoute[] = [
  { path: "/login", element: <Login />, auth: false },
  { path: "/*", element: <Login />, auth: false },

  { path: "/reset-password", element: <ResetPassword />, auth: true },
  {
    path: "/user-password-reset/:userLoginEmailToken?/:userToken?",
    element: <UserPasswordUpdate />,
    auth: false,
  },
  { path: "/dashboard", element: <Dashboard />, auth: true },

  { path: "/messages/sent", element: <SentMessages />, auth: true },
  { path: "/messages/recieved", element: <RecievedMessages />, auth: true },

  { path: "/destinations/edit", element: <EditDestination />, auth: true },
  { path: "/destinations/create", element: <CreateDestination />, auth: true },
  {
    path: "/destinations/consult",
    element: <ConsultDestinations />,
    auth: true,
  },

  { path: "/pre-shipments/create", element: <CreatePreShipment />, auth: true },
  {
    path: "/pre-shipments/consult",
    element: <ConsultPreShipments />,
    auth: true,
  },

  { path: "/shipmentV2/create", element: <CreateShipment />, auth: true },
  { path: "/shipmentV2/consult", element: <ConsultShipments />, auth: true },

  { path: "/shipments/create", element: <CreateShipmentV2 />, auth: true },
  { path: "/shipments/consult", element: <ConsultShipmentsV2 />, auth: true },

  { path: "/integration/import", element: <ShipmentImport />, auth: true },

  { path: "/pod/load", element: <LoadPOD />, auth: true },
  { path: "/pod/consult", element: <ConsultPOD />, auth: true },

  { path: "/reports/traceability", element: <Traceability />, auth: true },

  { path: "/*", element: <Dashboard />, auth: true },

  { path: "/Dispatch/consult", element: <ConsultDispatch />, auth: true },
  { path: "/Dispatch/create", element: <CreateDispatch />, auth: true },
  {
    path: "/FavoriteDestination/consult",
    element: <ConsultFavoriteDestination />,
    auth: true,
  },
  {
    path: "/FavoriteDestination/create/:id?",
    element: <CreationFavoriteDestination />,
    auth: true,
  },

  { path: "/AccountAuth/consult", element: <ConsultAccountAuth />, auth: true },
  { path: "/AccountAuth", element: <ConsultAccountAuth />, auth: true },
  {
    path: "/AccountAuth/create/:id?",
    element: <CreationAccountAuth />,
    auth: true,
  },
  {
    path: "/user/consult/:id?/:accountId?/:accountSiteID?",
    element: <ConsultUserAdministrator />,
    auth: true,
  },
  {
    path: "/user/:accountId?/:accountSiteID?",
    element: <ConsultUserAdministrator />,
    auth: true,
  },
  {
    path: "/user/create/:id?/:accountId?/:accountSiteID?",
    element: <CreationUserAdministratos />,
    auth: true,
  },
  {
    path: "/user/createAccount/:accountId?/:accountSiteID?",
    element: <CreationUserAdministratos />,
    auth: true,
  },
  {
    path: "Administrator/user/",
    element: <ConsultUserClient />,
    auth: true,
  },
];

export default AppRoutes;
