import { FC, useEffect, useMemo, useRef, useState } from "react";
import "moment/locale/es";
import { graphic } from "echarts";
import * as echarts from "echarts";
import classNames from "classnames";
import moment, { Moment } from "moment";
import { getUserShipments } from "../../services";
import { useAppSelector } from "../../store/hooks";
import { FormSelect } from "../FormFields/FormSelect";
import { renderToStaticMarkup } from "react-dom/server";
import { ShipmentInterface, ShipmentStatusEnum } from "../../interfaces";
import {
  ClockIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  CheckCircleIcon,
  ClipboardDocumentListIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/24/outline";

moment.locale("es");

interface Stat {
  name: string;
  stat: number;
  previousStat: number;
  isPercentage?: boolean;
  icon: React.ElementType;
}
enum StatPeriod {
  LAST_WEEK = "Última Semana",
  LAST_MONTH = "Último Mes",
  LAST_QUARTER = "Último Trimestre",
  LAST_SEMESTER = "Último Semestre",
  LAST_YEAR = "Último Año",
}
const periodToDays = (period: StatPeriod, formToday: boolean = true) => {
  if (!formToday) {
    if (period == StatPeriod.LAST_WEEK) return 7;
    if (period == StatPeriod.LAST_MONTH) return 30;
    if (period == StatPeriod.LAST_QUARTER) return 90;
    if (period == StatPeriod.LAST_SEMESTER) return 180;
    if (period == StatPeriod.LAST_YEAR) return 365;
  } else {
    if (period == StatPeriod.LAST_WEEK) {
      const today = moment();
      return today.day();
    }
    if (period == StatPeriod.LAST_MONTH) {
      const today = moment();
      return today.date();
    }
    if (period == StatPeriod.LAST_QUARTER) {
      const today = moment();
      return (
        today.date() +
        today.subtract(1, "month").daysInMonth() +
        today.subtract(2, "month").daysInMonth()
      );
    }
    if (period == StatPeriod.LAST_SEMESTER) {
      const today = moment();
      return (
        today.date() +
        new Array(5).fill(0).reduce((acc, _, i) => {
          return acc + today.subtract(i + 1, "month").daysInMonth();
        }, 0)
      );
    }
    if (period == StatPeriod.LAST_YEAR) {
      const today = moment();
      return today.dayOfYear();
    }
  }
  return 30;
};

export const LastPeriodStats: FC = () => {
  const user = useAppSelector((state) => state.user);

  // const barChartRef = useRef(null);
  const lineChartRef = useRef(null);
  const [period, setPeriod] = useState(StatPeriod.LAST_MONTH);
  const [shipments, setShipments] = useState<ShipmentInterface[]>([]);

  const stats = useMemo(() => {
    // Get last and previous period
    const lastPeriod = moment().subtract(periodToDays(period, true), "days");
    const previousPeriod = moment(lastPeriod).subtract(periodToDays(period), "days");

    // Filter shipments by period
    const lastPeriodShipments = shipments.filter((shipment) =>
      moment(shipment.date).isBetween(lastPeriod, moment())
    );
    const previousPeriodShipments = shipments.filter((shipment) =>
      moment(shipment.date).isBetween(previousPeriod, lastPeriod)
    );

    // Get stats
    const lastPeriodShipmentsProcessed = lastPeriodShipments.filter(
      (shipment) => !!shipment.requestProcessedDate
    ).length;
    const previousPeriodShipmentsProcessed = previousPeriodShipments.filter(
      (shipment) => !!shipment.requestProcessedDate
    ).length;
    const lastPeriodShipmentsDelivered = lastPeriodShipments.filter(
      (shipment) => shipment.status == ShipmentStatusEnum.DELIVERED
    ).length;
    const previousPeriodShipmentsDelivered = previousPeriodShipments.filter(
      (shipment) => shipment.status == ShipmentStatusEnum.DELIVERED
    ).length;

    const stats: Stat[] = [
      {
        name: "Guías Generadas",
        stat: lastPeriodShipments.length,
        previousStat: previousPeriodShipments.length,
        icon: ClockIcon,
      },
      {
        name: "Guías Procesadas",
        stat: lastPeriodShipmentsProcessed,
        previousStat: previousPeriodShipmentsProcessed,
        icon: ClipboardDocumentListIcon,
      },
      {
        name: "Envíos Entregados",
        stat: lastPeriodShipmentsDelivered,
        previousStat: previousPeriodShipmentsDelivered,
        icon: CheckCircleIcon,
      },
      {
        name: "Efectividad",
        stat: !!lastPeriodShipmentsProcessed
          ? (lastPeriodShipmentsDelivered / lastPeriodShipmentsProcessed) * 100
          : 100,
        previousStat: !!previousPeriodShipmentsProcessed
          ? (previousPeriodShipmentsDelivered / previousPeriodShipmentsProcessed) * 100
          : 100,
        isPercentage: true,
        icon: PresentationChartLineIcon,
      },
    ];
    return stats;
  }, [shipments, period]);

  const allChartsData = useMemo(() => {
    let data = new Map();

    const getPeriodData = (
      dates: Moment[],
      format: (date: Moment) => string,
      momentCompare: (a: Moment, b: Moment) => boolean
    ) => {
      const values = dates.map((date) => {
        const dayShipments = shipments.filter(
          (shipment) =>
            shipment.status == ShipmentStatusEnum.DELIVERED &&
            momentCompare(moment(shipment.date), date)
        );

        return {
          value: dayShipments.length,
          weight: dayShipments.reduce(
            (acc, shipment) => acc + (shipment.totalWeight ?? 0),
            0
          ),
        };
      });

      return {
        values: values.map((value) => value.value),
        weights: values.map((value) => value.weight),
        dates: dates.map((date) => format(date)),
      };
    };

    for (const period of Object.values(StatPeriod)) {
      let dates: Moment[];
      let format: (date: Moment) => string;
      let momentCompare: (a: Moment, b: Moment) => boolean;

      // Last week
      if (period == StatPeriod.LAST_WEEK) {
        dates = new Array(7).fill(0).map((_, i) => {
          return moment().add(i - moment().day() + 1, "day");
        });
        format = (date) => {
          return (
            date.format("dddd").charAt(0).toUpperCase() + date.format("dddd").slice(1)
          );
        };
        momentCompare = (a, b) => moment(a).isSame(b, "day");
      }

      // Last month
      else if (period == StatPeriod.LAST_MONTH) {
        dates = new Array(moment().daysInMonth()).fill(0).map((_, i) => {
          return moment().add(i - moment().date() + 1, "day");
        });
        format = (date) => {
          return date.format("DD/MM");
        };
        momentCompare = (a, b) => moment(a).isSame(b, "day");
      }

      // Last quarter
      else if (period == StatPeriod.LAST_QUARTER) {
        const firstWeek = moment()
          .subtract(2, "month")
          .startOf("month")
          .startOf("isoWeek")
          .week();
        const lastWeek = moment().endOf("month").endOf("isoWeek").week();
        const totalWeeks = lastWeek - firstWeek - 1;

        dates = new Array(totalWeeks).fill(0).map((_, i) => {
          return moment(lastWeek)
            .add(firstWeek + i + 1, "week")
            .startOf("isoWeek");
        });
        format = (date) => {
          return `${
            date.format("MMMM").charAt(0).toUpperCase() + date.format("MMMM").slice(1)
          } (Sem ${date.week() - date.startOf("month").week()})`;
        };
        momentCompare = (a, b) => a.week() == b.week();
      }

      // Last semester
      else if (period == StatPeriod.LAST_SEMESTER) {
        dates = new Array(6).fill(0).map((_, i) => {
          return moment().subtract(5 - i, "month");
        });
        format = (date) => {
          return (
            date.format("MMMM").charAt(0).toUpperCase() + date.format("MMMM").slice(1)
          );
        };
        momentCompare = (a, b) => moment(a).isSame(b, "month");
      }

      // Last year
      else {
        const yearMonth = moment().month();
        dates = new Array(12).fill(0).map((_, i) => {
          return moment().add(i - yearMonth, "month");
        });
        format = (date) => {
          return (
            date.format("MMMM").charAt(0).toUpperCase() + date.format("MMMM").slice(1)
          );
        };
        momentCompare = (a, b) => moment(a).isSame(b, "month");
      }

      data.set(period, getPeriodData(dates, format, momentCompare));
    }

    return data;
  }, [shipments]);

  const chartData = useMemo(() => {
    return allChartsData.get(period)!;
  }, [period, allChartsData]);

  useEffect(() => {
    const id = user?.client?.id;
    if (!id) return;

    const fetchShipments = async () => {
      const shipments = await getUserShipments(id,id, 5000, 6500);
      shipments.sort((a, b) => moment(a.date).diff(moment(b.date)));
      setShipments(shipments);
    };

    fetchShipments();
  }, [user?.client?.id]);

  useEffect(() => {
    if (!lineChartRef.current) return;
    let chartInstance = echarts.getInstanceByDom(lineChartRef.current);

    if (!chartInstance) {
      chartInstance = echarts.init(lineChartRef.current);
    }
    chartInstance.setOption({
      visualMap: [
        {
          show: false,
          type: "continuous",
          seriesIndex: 0,
          min: 0,
          max: 400,
        },
      ],
      title: [
        {
          color: "black",
          left: "center",
        },
      ],
      tooltip: {
        trigger: "axis",
        formatter: function (params: any[]): HTMLElement {
          const tooltip = (
            <div className="flex flex-col items-center text-xs">
              <span className="font-semibold text-gray-500">{params[0].name}</span>
              <span className="text-main-500">Envíos: {params[0].value}</span>
              <span className="text-gray-600">
                Peso: {chartData.weights[params[0].dataIndex].toFixed(2)} Kg
              </span>
            </div>
          );

          const output = document.createElement("div");
          const staticElement = renderToStaticMarkup(tooltip);
          output.innerHTML = staticElement;

          return output;
        },
      },
      xAxis: [
        {
          data: chartData.dates,
        },
      ],
      yAxis: [{}],
      series: [
        {
          type: "line",
          showSymbol: false,
          data: chartData.values,
          emphasis: {
            itemStyle: {
              color: "#0090f9",
              borderColor: "#0090f9",
            },
          },
          lineStyle: {
            color: new graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#0090f9",
              },
              {
                offset: 1,
                color: "#003860",
              },
            ]),
          },
        },
      ],
    });
  }, [chartData]);

  return (
    <div className="flex flex-1 flex-col">
      <FormSelect
        id="stats-period"
        name="stats-period"
        selected={period}
        options={Object.values(StatPeriod)}
        className="w-full sm:w-64"
        labelClassname="text-xs font-semibold"
        containerClassname="flex flex-1 flex-col"
        optionString={(period) => period}
        onSelectOption={(period) => setPeriod(period)}
      />

      <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-2 2xl:grid-cols-4 md:divide-x 2xl:divide-y-0">
        {stats.map((item) => (
          <div key={item.name} className="p-3 sm:p-5 flex gap-4 items-center">
            <div className="rounded-md bg-main-500 p-2">
              <item.icon className="h-7 w-7 text-white" aria-hidden="true" />
            </div>

            <div>
              <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
              <dd className="flex items-baseline">
                <p className="text-2xl font-semibold text-gray-900">
                  {!item.isPercentage ? item.stat : `${item.stat.toFixed(2)}%`}
                </p>
                <p
                  className={classNames(
                    item.stat >= item.previousStat ? "text-green-600" : "text-red-600",
                    "ml-2 flex items-baseline text-sm font-semibold"
                  )}
                >
                  {item.stat >= item.previousStat ? (
                    <ArrowUpIcon
                      className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <ArrowDownIcon
                      className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                      aria-hidden="true"
                    />
                  )}

                  <span className="sr-only">
                    {" "}
                    {item.stat >= item.previousStat ? "Increased" : "Decreased"} by{" "}
                  </span>
                  {!item.isPercentage
                    ? Math.abs(item.stat - item.previousStat)
                    : `${Math.abs(item.stat - item.previousStat).toFixed(2)}%`}
                </p>
              </dd>
            </div>
          </div>
        ))}
      </dl>

      <div className="flex flex-1 gap-6">
        <div ref={lineChartRef} style={{ width: "100%", height: "400px" }} />
      </div>
    </div>
  );
};
