import { FC } from 'react';
import {
  AccountInterface,
  BusinessUnitInterface,
  DepartmentInterfase,
  DestinationInterface,
  PieceCategoryInterface,
  PieceInterface,
  ProfileInterfase,
  TaxIdentificationTypeInterface,
} from '../../interfaces';
import { LocationDto } from '../../interfaces/Dto/LocationDto';

export const AccountOption: FC<AccountInterface> = ({
  accountCode,
  accountFullName,
  abbreviationName,
  identificationNumber,
}) => {
  return (
    <div className="flex flex-col">
      <p className="text-xs font-semibold truncate">
        {accountCode} - {accountFullName}
      </p>
      <p className="text-xs truncate">
        {abbreviationName}
        {identificationNumber}
      </p>
    </div>
  );
};

export const BusinessUnitOption: FC<BusinessUnitInterface> = ({
  buCode,
  location,
}) => {
  return (
    <div className="flex flex-col">
      <p className="text-sm font-semibold leading-5 truncate">
        {buCode} - {location.name}
      </p>
    </div>
  );
};

export const PiecesOption: FC<PieceCategoryInterface> = ({ name }) => {
  return (
    <div className="flex flex-col">
      <p className="text-sm font-semibold leading-5 truncate">{name}</p>
    </div>
  );
};

export const DestinationOption: FC<DestinationInterface> = ({
  businessUnit,
  account,
}) => {
  return (
    <div className="flex flex-col">
      <p className="text-xs font-semibold truncate">
        {account.accountCode} - {account.accountFullName}
      </p>
      <p className="text-xs truncate">
        {account.abbreviationName}
        {account.identificationNumber}
      </p>
      <p className="text-xs truncate">Tienda: {businessUnit?.buCode}</p>
    </div>
  );
};

export const GenericoOption: FC<{ text: string }> = ({ text }) => {
  return (
    <div className="flex flex-col">
      <p className="text-sm font-semibold leading-5 truncate">{text}</p>
    </div>
  );
};

export const CityOption: FC<LocationDto> = ({ locationCode, locationName }) => {
  return (
    <div className="flex flex-col">
      <p className="text-sm font-semibold leading-5 truncate">{locationName}</p>
    </div>
  );
};

export const IdentificationTypeOption: FC<TaxIdentificationTypeInterface> = ({
  abbreviationName,
}) => {
  return (
    <div className="flex flex-col">
      <p className="text-sm font-semibold leading-5 truncate">
        {abbreviationName}
      </p>
    </div>
  );
};

export const DepartmentOption: FC<DepartmentInterfase> = ({
  departamentName,
}) => {
  return (
    <div className="flex flex-col">
      <p className="text-sm font-semibold leading-5 truncate">
        {departamentName}
      </p>
    </div>
  );
};

export const ProfileOption: FC<ProfileInterfase> = ({ profileName }) => {
  return (
    <div className="flex flex-col">
      <p className="text-sm font-semibold leading-5 truncate">{profileName}</p>
    </div>
  );
};

export const AccountSiteOption: FC<AccountInterface> = ({
  accountCode,
  accountFullName,
}) => {
  return (
    <div className="flex flex-col">
      <p className="text-sm font-semibold leading-5 truncate">
        {accountCode !== undefined && accountCode !== null
          ? accountCode + ' -'
          : ''}{' '}
        {accountFullName}
      </p>
    </div>
  );
};
