import {
  PreShipmentInterface,
  PreshipmentDto,
  PreshipmentResponseDto,
} from '../interfaces';
import axios from 'axios';
import { PreshipmentMapper, PreshipmentMapperDto } from '../utils/mappers';
import { AuthenticationServices } from './AuthenticationServices';
const { REACT_APP_URL_PATH_API } = process.env;

export const getUserPreShipments = async (
  AccountCode: string,
  id: string
): Promise<PreShipmentInterface[]> => {
  try {
    const token = await AuthenticationServices();
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    const response = await axios.post(
      `${REACT_APP_URL_PATH_API}/v1/PreShipment/SearchPreShipmentCustomer`,
      { AccountCode: AccountCode, ShipperAccountID: id, NumDays: 770 }
    );
    if (response.status === 200) {
      var datamap = response.data.map((dto: PreshipmentDto) =>
        PreshipmentMapper(dto)
      );
      return datamap;
    }
  } catch (error) {}
  return [];
};

export const DowloadPreShipments = async (
  PreshipmentNumber: string
): Promise<any> => {
  const token = await AuthenticationServices();
  const request = new Request(
    `${REACT_APP_URL_PATH_API}/v1/PreShipment/DownloadLabel?preshipmentNumber=` +
      PreshipmentNumber,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return fetch(request);
};

export const createPreShipment = async (
  preShipment: PreShipmentInterface[]
): Promise<PreShipmentInterface> => {
  let shipmentId = '';
  let shipmentNumber = '';
  let dirError = false;
  let errorMessage = '';
  try {
    let datamap = preShipment.map((dto: PreShipmentInterface) =>
      PreshipmentMapperDto(dto)
    );
    const token = await AuthenticationServices();
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    await axios
      .post(`${REACT_APP_URL_PATH_API}/v1/PreShipment/CreateCore/1`, datamap[0])
      .then(function (response) {
        var data = response.data as PreshipmentResponseDto;
        shipmentId = data.preShipmentID ?? '';
        shipmentNumber = data.preShipmentNumber ?? '';
      })
      .catch(function (error) {
        dirError = true;
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          let errorData = error.response.data as PreshipmentResponseDto;
          errorMessage = errorData.message ?? '';
        } else {
          // Something happened in setting up the request that triggered an Error
          errorMessage = error.message ?? '';
        }
      });
  } catch (error) {}

  return {
    ...preShipment[0],

    id: shipmentId,
    number: Math.floor(100 + Math.random() * 900),
    shipmentNumber: shipmentNumber,
    didError: dirError,
    errorMessage: errorMessage,
    date: new Date().toISOString(),
  };
};
