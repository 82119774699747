import { FC } from "react";
import moment from "moment";
import classNames from "classnames";
import { ShipmentInterface } from "../../interfaces";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

export enum TraceabilityField {
  CLIENT,
  SHIPMENT,
  ORIGIN,
  DESTINATION,
  STATUS,
  WEIGHT,
  VOLUME,
  PIECES,
  CREATION_DATE,
  PROCESSED_DATE,
  DELIVERY_DATE,
  DIFFERENCE,
  CONSIGNEE,
}

interface TraceabilityTableProps {
  shipments: ShipmentInterface[];
  sortBy: { field: TraceabilityField; asc: boolean };
  onChangeSortBy: (field: TraceabilityField) => void;
}
export const TraceabilityTable: FC<TraceabilityTableProps> = ({
  shipments,
  sortBy,
  onChangeSortBy,
}) => {
  return (
    <div className="relative w-full table-fix-head mt-4 overflow-auto">
      <table className="table-auto w-full text-gray-600 text-xs text-left border border-gray-300">
        <thead className="select-none font-semibold border-b border-gray-300 bg-gray-50">
          <tr>
            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(TraceabilityField.CLIENT)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Client</span>
                <div className="h-4 w-4">
                  {sortBy.field == TraceabilityField.CLIENT && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(TraceabilityField.SHIPMENT)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Nº de Guía</span>
                <div className="h-4 w-4">
                  {sortBy.field == TraceabilityField.SHIPMENT && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(TraceabilityField.ORIGIN)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Origen</span>
                <div className="h-4 w-4">
                  {sortBy.field == TraceabilityField.ORIGIN && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(TraceabilityField.DESTINATION)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Destino</span>
                <div className="h-4 w-4">
                  {sortBy.field == TraceabilityField.DESTINATION && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(TraceabilityField.STATUS)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Estatus</span>
                <div className="h-4 w-4">
                  {sortBy.field == TraceabilityField.STATUS && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(TraceabilityField.WEIGHT)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Peso</span>
                <div className="h-4 w-4">
                  {sortBy.field == TraceabilityField.WEIGHT && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(TraceabilityField.VOLUME)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Volumen</span>
                <div className="h-4 w-4">
                  {sortBy.field == TraceabilityField.VOLUME && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(TraceabilityField.PIECES)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Piezas</span>
                <div className="h-4 w-4">
                  {sortBy.field == TraceabilityField.PIECES && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(TraceabilityField.CREATION_DATE)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Fecha de Creación</span>
                <div className="h-4 w-4">
                  {sortBy.field == TraceabilityField.CREATION_DATE && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(TraceabilityField.PROCESSED_DATE)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Fecha de Procesado</span>
                <div className="h-4 w-4">
                  {sortBy.field == TraceabilityField.PROCESSED_DATE && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(TraceabilityField.DELIVERY_DATE)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Fecha de Entrega</span>
                <div className="h-4 w-4">
                  {sortBy.field == TraceabilityField.DELIVERY_DATE && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(TraceabilityField.DIFFERENCE)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Diferencia</span>
                <div className="h-4 w-4">
                  {sortBy.field == TraceabilityField.DIFFERENCE && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(TraceabilityField.CONSIGNEE)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Destinatario</span>
                <div className="h-4 w-4">
                  {sortBy.field == TraceabilityField.CONSIGNEE && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          {shipments.map((shipment, index) => {
            return (
              <tr
                key={shipment.id}
                className={classNames(
                  "hover:bg-gray-200",
                  index % 2 == 0 && "bg-gray-100"
                )}
              >
                <td className="px-2 py-1 font-semibold">
                  {shipment.shipper.accountFullName}
                </td>
                <td className="px-2 py-1">{shipment.number}</td>
                <td className="px-2 py-1">
                  {shipment.businessUnitOrigin.buCode} -{" "}
                  {shipment.businessUnitOrigin.location.name}
                </td>
                <td className="px-2 py-1">
                  {shipment.businessUnitConsignee.buCode} -{" "}
                  {shipment.businessUnitConsignee.location.name}
                </td>
                <td className="px-2 py-1">{shipment.status}</td>
                <td className="px-2 py-1">{shipment.totalWeight}</td>
                <td className="px-2 py-1">{shipment.totalVolumeCm3}</td>
                <td className="px-2 py-1">{shipment.totalPieces}</td>
                <td className="px-4 py-1">
                  {moment(shipment.date).format("DD/MM/YYYY")}
                </td>
                <td className="px-4 py-1">
                  {moment(shipment.requestProcessedDate).format("DD/MM/YYYY")}
                </td>
                <td className="px-4 py-1">
                  {moment(shipment.deliveredDate).format("DD/MM/YYYY")}
                </td>
                <td className="px-4 py-1" />
                <td className="px-2 py-1">{shipment.consignee.accountFullName}</td>
              </tr>
            );
          })}

          {shipments.length == 0 && (
            <tr>
              <td colSpan={13} className="px-4 py-4 text-center">
                No se encontraron registros.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
