import { FormikProps } from "formik";
import { ServiceEnum } from "../interfaces/Services/ServicesEnum";
import {
  DeliveryEnum,
  PaymentModeEnum,
  PaymentModeId,
  SStatusEnum,
  ShippingMethodEnum,
} from "../interfaces";
import { PackageTypeEnum } from "../interfaces/Shipment/PackageTypeEnum";
import { WeightTypeEnum } from "../interfaces/Shipment/WeightTypeEnum";
import { ShipmentTypeEnum } from "../interfaces/Shipment/ShipmentTypeEnum";

/**
 * Format a float string value
 *
 * @param value The input value to be formatted
 * @param setValue A function that takes the formatted value and assigns it to
 *  a variable
 * @param min The minimum allowed value (default is -Infinity)
 * @param max The maximum allowed value (default is Infinity)
 * @returns A boolean indicating whether the formatting was successful or not
 */
export const formatFloat = (
  value: string,
  setValue: (f: string) => void,
  min?: number,
  max?: number
) => {
  let formatted = value;

  let regex = /^(\d*\.\d{0,3}|\d*)$/;

  if (!regex.test(value)) {
    return false;
  }
  if (formatted.length > 1) {
    formatted = formatted.replace(/^0+/, "");
  }
  if (formatted === "") {
    formatted = "0";
  } else if (formatted[0] === ".") {
    formatted = "0" + formatted;
  }

  let result = Number(formatted);
  if (!!min) {
    result = Math.max(min, result);
  }
  if (!!max) {
    result = Math.min(max, result);
  }

  if (!!min || !!max) {
    if (value[value.length - 1] === ".") {
      setValue(`${result}.`);
    } else {
      setValue(`${result}`);
    }
  } else {
    if (value[value.length - 1] === ".") {
      setValue(`${formatted.toString().padStart(2, "0")}`);
    } else {
      setValue(`${formatted.toString().padStart(2, "0")}`);
    }
  }

  return true;
};

export const handleNumberChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  formik: FormikProps<any>,
  allowFloats = true
) => {
  if (event.target.value.length > 1) {
    event.target.value = event.target.value.replace(/^0+/, "");
  }
  const setValue = (value: string) => {
    event.target.value = value;
  };

  if (!allowFloats) {
    event.target.value = event.target.value.replace(/[^0-9]/g, "");
  } else if (!formatFloat(event.target.value, setValue)) {
    return;
  }

  formik.handleChange(event);
};

// Format name for display
export const formatName = (name: string): string => {
  name = name.toLowerCase();
  let words = name.split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  let formattedText = words.join(" ");

  return formattedText;
};

export const formantServices = (service: ServiceEnum) => {
  switch (service) {
    case ServiceEnum.COD:
      return "COD";
    case ServiceEnum.CREDITO:
      return "CREDITO";
    case ServiceEnum.CONTADO:
      return "CONTADO";
    default:
      return "No existe";
  }
};

export const formantPaymentMode = (payment: PaymentModeEnum) => {
  switch (payment) {
    case PaymentModeEnum.COD:
      return "COD";
    case PaymentModeEnum.CREDIT:
      return "CREDITO";
    case PaymentModeEnum.CONTADO:
      return "CONTADO";
    default:
      return "No existe";
  }
};

export const formantPaymentModeId = (payment: PaymentModeId) => {
  switch (payment.toString()) {
    case PaymentModeId.COD:
      return "COD";
    case PaymentModeId.CREDIT:
      return "CREDITO";
    case PaymentModeId.CONTADO:
      return "CONTADO";
    default:
      return "No existe";
  }
};

export const formantShippingMethod = (method: ShippingMethodEnum) => {
  switch (method) {
    case ShippingMethodEnum.SEA:
      return "Marítimo";
    case ShippingMethodEnum.LAND:
      return "Terrestre";
    case ShippingMethodEnum.AIR:
      return "Aéreo";
    default:
      return "No existe";
  }
};

export const formantPackageType = (type: PackageTypeEnum) => {
  switch (type) {
    case PackageTypeEnum.ABOUT:
      return "Sobre";
    case PackageTypeEnum.BOX:
      return "Caja";
    default:
      return "No existe";
  }
};

export const formantWeightTypeEnum = (type: WeightTypeEnum) => {
  switch (type) {
    case WeightTypeEnum.KG:
      return "Kg";
    case WeightTypeEnum.LIBRA:
      return "Libra";
    default:
      return "No existe";
  }
};

export const formantDeliveryEnum = (type: DeliveryEnum) => {
  switch (type) {
    case DeliveryEnum.OFFICE:
      return "Tienda";
    case DeliveryEnum.DELIVERY:
      return "Domicilio";
    default:
      return "No existe";
  }
};

export const formantShipmentTypeEnum = (type: ShipmentTypeEnum) => {
  switch (type) {
    case ShipmentTypeEnum.STORE:
      return "Tienda";
    case ShipmentTypeEnum.DELIVERY:
      return "Domicilio";
    default:
      return "No existe";
  }
};

export const formantStatusTypeEnum = (type: SStatusEnum) => {
  switch (type) {
    case SStatusEnum.ACTIVE:
      return "Activo";
    case SStatusEnum.INACTIVE:
      return "Inactivo";
    default:
      return "No existe";
  }
};

export const formatAmount = (amount: number) => {
  return (+amount.toFixed(2)).toLocaleString("de-DE", {
    minimumFractionDigits: 2,
  });
};

export const obtenerCodigoArea = (numero: string) => {
  const codigosMoviles = ["412", "424", "414", "426", "416"];
  const codigoArea = numero.substring(0, 3);
  const codigosMoviles0 = ["0412", "0424", "0414", "0426", "0416"];
  const codigoArea0 = numero.substring(0, 4);

  if (codigosMoviles.includes(codigoArea)) {
    return codigoArea;
  } else if (codigosMoviles0.includes(codigoArea0)) {
    return codigoArea0.substring(1, 4);
  } else {
    return "FIJO";
  }
};

export const obtenerNumero = (numero: string) => {
  const codigosMoviles = ["412", "424", "414", "426", "416"];
  const codigoArea = numero.substring(0, 3);
  const codigosMoviles0 = ["0412", "0424", "0414", "0426", "0416"];
  const codigoArea0 = numero.substring(0, 4);

  if (codigosMoviles.includes(codigoArea)) {
    return numero.substring(3);
  } else if (codigosMoviles0.includes(codigoArea0)) {
    return numero.substring(3);
  } else {
    return numero;
  }
};
