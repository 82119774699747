import {
  LocationInterface,
  DestinationInterface,
  BusinessUnitInterface,
  AccountInterface,
  TaxIdentificationTypeInterface,
  PieceCategoryInterface,
  DepartmentInterfase,
  ProfileInterfase,
} from '../interfaces';
import { LocationDto } from '../interfaces/Dto/LocationDto';

export const filterLocation = (value: LocationInterface, filter: string) => {
  const tokens = filter.toLowerCase().split(' ');

  return tokens.every((token) => {
    return (
      value.name.toLowerCase().includes(token) ||
      value.code?.toLowerCase().includes(token) ||
      value.address.toLowerCase().includes(token)
    );
  });
};

export const filterAccountUnit = (value: AccountInterface, filter: string) => {
  const tokens = filter.toLowerCase().split(' ');

  return tokens.every((token) => {
    return (
      token === '-' ||
      value.accountFullName.toLowerCase().includes(token) ||
      (value.abbreviationName + value.identificationNumber)
        .toLowerCase()
        .includes(token)
    );
  });
};

export const filterBusinessUnit = (
  value: BusinessUnitInterface,
  filter: string
) => {
  const tokens = filter?.toLowerCase()?.split(' ') ?? [];
  return tokens.every((token) => {
    return (
      token === '-' ||
      value.buName.toLowerCase().includes(token) ||
      value.buCode.toLowerCase().includes(token) ||
      filterLocation(value.location, token)
    );
  });
};

export const filterPieces = (value: PieceCategoryInterface, filter: string) => {
  const tokens = filter?.toLowerCase()?.split(' ') ?? [];
  return tokens.every((token) => {
    return token === '-' || value.name.toLowerCase().includes(token);
  });
};

export const filterIdentificationType = (
  value: TaxIdentificationTypeInterface,
  filter: string
) => {
  const tokens = filter?.toLowerCase()?.split(' ') ?? [];
  return tokens.every((token) => {
    return (
      token === '-' || value.abbreviationName.toLowerCase().includes(token)
    );
  });
};

export const filterDestination = (
  value: DestinationInterface,
  filter: string
) => {
  const tokens = filter.toLowerCase().split(' ');

  return tokens.every((token) => {
    return (
      filterAccountUnit(value.account, token) ||
      filterBusinessUnit(value.businessUnit, token) ||
      filterLocation(value.location, token)
    );
  });
};

export const filterGeneral = (value: string, filter: string) => {
  const tokens = filter?.toLowerCase().split(' ');
  return tokens?.every((token) => {
    return value.toLowerCase().includes(token);
  });
};

export const filterCity = (value: LocationDto, filter: string) => {
  const tokens = filter?.toLowerCase().split(' ');
  return tokens?.every((token) => {
    return (
      token === '-' ||
      value.locationCode.toLowerCase().includes(token) ||
      value.locationName.toLowerCase().includes(token)
    );
  });
};

export const filterDepartament = (
  value: DepartmentInterfase,
  filter: string
) => {
  const tokens = filter?.toLowerCase()?.split(' ') ?? [];
  return tokens.every((token) => {
    return token === '-' || value.departamentName.toLowerCase().includes(token);
  });
};

export const filterProfile = (value: ProfileInterfase, filter: string) => {
  const tokens = filter?.toLowerCase()?.split(' ') ?? [];
  return tokens.every((token) => {
    return token === '-' || value.profileName.toLowerCase().includes(token);
  });
};

export const filterAccountBusinessUnit = (
  value: AccountInterface,
  filter: string
) => {
  const tokens = filter?.toLowerCase()?.split(' ') ?? [];
  return tokens.every((token) => {
    return (
      token === '-' ||
      value.accountCode?.toLowerCase().includes(token) ||
      value.accountFullName?.toLowerCase().includes(token) ||
      value.identificationNumber?.toLowerCase().includes(token)
    );
  });
};
