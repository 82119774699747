import { FC, useEffect, useMemo, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import { Icon } from "@iconify/react";
import { getUserShipments } from "../../services";
import { useAppSelector } from "../../store/hooks";
import filePdfO from "@iconify/icons-fa/file-pdf-o";
import { ShipmentInterface } from "../../interfaces";
import fileTextO from "@iconify/icons-fa/file-text-o";
import fileExcelO from "@iconify/icons-fa/file-excel-o";
import {
  PrinterIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import {
  exportShipmentsPDF,
  exportShipmentsTXT,
  exportShipmentsXLSX,
} from "../../utils/exports";
import {
  Popover,
  FormText,
  MainPage,
  PageTitle,
  FormSelect,
  ShipmentField,
  ShipmentTable,
  buttonClassname,
  ShipmentFilters,
  PaginationFooter,
  ShipmentFilterEnum,
} from "../../components";

const ExportButton = (
  <div
    className={classNames(
      buttonClassname,
      "bg-main-500 hover:bg-main-400 hover:border-main-400 text-white focus-visible:outline-main-400"
    )}
  >
    <div className="flex gap-1 items-center">
      <span>Exportar</span>

      <ChevronDownIcon className="w-4 h-4" />
    </div>
  </div>
);

const ConsultShipments: FC = () => {
  const user = useAppSelector((state) => state.user);

  const [filterSearchApi, setFilterSearchApi] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [registersNumber, setRegistersNumber] = useState(10);
  const [shipments, setShipments] = useState<ShipmentInterface[]>([]);
  const [selecteds, setSelecteds] = useState<ShipmentInterface[]>([]);
  const [filters, setFilters] = useState<Map<ShipmentFilterEnum, string>>(
    new Map()
  );
  const [sortBy, setSortBy] = useState({
    field: ShipmentField.SHIPMENT,
    asc: true,
  });

  const filteredShipments = useMemo(() => {
    setPage(0);
    setSelecteds([]);
    const tokens = search.toLowerCase().split(" ");

    const userFilter = filters.get(ShipmentFilterEnum.USER);
    const cityFilter = filters.get(ShipmentFilterEnum.CITY);
    const endDateFilter = filters.get(ShipmentFilterEnum.END_DATE);
    const shipmentFilter = filters.get(ShipmentFilterEnum.SHIPMENT);
    const trackingFilter = filters.get(ShipmentFilterEnum.TRACKING);
    const consigneeFilter = filters.get(ShipmentFilterEnum.CONSIGNEE);
    const startDateFilter = filters.get(ShipmentFilterEnum.START_DATE);

    return shipments.filter((shipment) => {
      return (
        tokens.every((token) => {
          return (
            shipment.number.toString().toLowerCase().includes(token) ||
            shipment.consignee.accountFullName.toLowerCase().includes(token) ||
            (
              shipment.consignee.abbreviationName +
              shipment.consignee.identificationNumber
            )
              .toLowerCase()
              .includes(token) ||
            shipment.shippingLocation.address.toLowerCase().includes(token) ||
            shipment.totalPieces?.toString().toLowerCase().includes(token) ||
            shipment.totalWeight?.toString().toLowerCase().includes(token)
          );
        }) &&
        (!cityFilter || shipment.shippingLocation.name.includes(cityFilter)) &&
        (!endDateFilter ||
          moment(shipment.date) <= moment(endDateFilter).add(1, "days")) &&
        (!startDateFilter ||
          moment(shipment.date) >= moment(startDateFilter)) &&
        (!shipmentFilter ||
          shipment.number.toString().includes(shipmentFilter)) &&
        (!trackingFilter || shipment.tracking.includes(trackingFilter)) &&
        (!consigneeFilter ||
          shipment.consignee.accountFullName.includes(consigneeFilter)) &&
        (!userFilter || shipment.creationUser.includes(userFilter))
      );
    });
  }, [shipments, search, filters]);

  const paginatedShipments = useMemo(() => {
    const orderedShipments = filteredShipments.sort((a, b) => {
      if (sortBy.field == ShipmentField.SHIPMENT) {
        return sortBy.asc ? a.number - b.number : b.number - a.number;
      } else if (sortBy.field == ShipmentField.PIECES) {
        return sortBy.asc
          ? (a.totalPieces ?? 0) - (b.totalPieces ?? 0)
          : (b.totalPieces ?? 0) - (a.totalPieces ?? 0);
      } else if (sortBy.field == ShipmentField.WEIGHT) {
        return sortBy.asc
          ? (a.totalWeight ?? 0) - (b.totalWeight ?? 0)
          : (b.totalWeight ?? 0) - (a.totalWeight ?? 0);
      } else if (sortBy.field == ShipmentField.ALIAS) {
        return sortBy.asc
          ? a.consignee.accountFullName.localeCompare(
              b.consignee.accountFullName
            )
          : b.consignee.accountFullName.localeCompare(
              a.consignee.accountFullName
            );
      } else if (sortBy.field == ShipmentField.PHONE) {
        return sortBy.asc
          ? a.consignee.listAccountPhone[0]?.phoneNumber.localeCompare(
              b.consignee.listAccountPhone[0]?.phoneNumber
            )
          : b.consignee.listAccountPhone[0]?.phoneNumber.localeCompare(
              a.consignee.listAccountPhone[0]?.phoneNumber
            );
      } else if (sortBy.field == ShipmentField.CITY) {
        return sortBy.asc
          ? a.shippingLocation.name.localeCompare(b.shippingLocation.name)
          : b.shippingLocation.name.localeCompare(a.shippingLocation.name);
      } else if (sortBy.field == ShipmentField.DATE) {
        return (
          (sortBy.asc
            ? a.date?.localeCompare(b.date ?? "")
            : b.date?.localeCompare(a.date ?? "")) ?? 0
        );
      } else if (sortBy.field == ShipmentField.TRACKING) {
        return sortBy.asc
          ? a.tracking.localeCompare(b.tracking)
          : b.tracking.localeCompare(a.tracking);
      }

      return 0;
    });

    const start = page * registersNumber;
    const end = start + registersNumber;

    return orderedShipments.slice(start, end);
  }, [filteredShipments, sortBy, page, registersNumber]);

  const handleSortByChange = (field: ShipmentField) => {
    if (sortBy.field == field) {
      setSortBy({ ...sortBy, asc: !sortBy.asc });
    } else {
      setSortBy({ field, asc: true });
    }
  };

  const ActionsButton = useMemo(
    () => (
      <div
        className={classNames(
          buttonClassname,
          "bg-main-500 hover:bg-main-400 hover:border-main-400 text-white focus-visible:outline-main-400"
        )}
      >
        <div className="flex gap-1 items-center">
          <span>Acciones ({selecteds.length} seleccionados)</span>

          <ChevronDownIcon className="w-4 h-4" />
        </div>
      </div>
    ),
    [selecteds]
  );

  useEffect(() => {
    const id = user?.client?.id;
    if (!id) return;

    const fetchShipments = async () => {
      const shipments = await getUserShipments(id);
      setShipments(shipments);
    };

    fetchShipments();
  }, [user?.client?.id]);

  return (
    <MainPage>
      <PageTitle title="Envios" />

      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 flex-col mb-4">
          <div className="flex items-center text-sm mb-2 space-x-6 divide-x divide-gray-300">
            <div
              className="group flex items-center cursor-pointer"
              onClick={() => setShowFilters((f) => !f)}
            >
              <span className="font-medium text-gray-600 group-hover:text-gray-800">
                Filtros
              </span>

              <span
                className={classNames(
                  "ml-1.5 rounded bg-main-500 bg-opacity-50 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700",
                  filters.size == 0 && "hidden"
                )}
              >
                {filters.size}
              </span>

              {showFilters && (
                <ChevronUpIcon className="ml-1.5 h-5 w-5 flex-none text-gray-500 group-hover:text-main-500" />
              )}
              {!showFilters && (
                <ChevronDownIcon className="ml-1.5 h-5 w-5 flex-none text-gray-500 group-hover:text-main-500" />
              )}
            </div>

            <div className="pl-6">
              <button
                type="button"
                className="text-gray-500 hover:text-gray-800"
                onClick={() => setFilters(new Map())}
              >
                Limpiar
              </button>
            </div>
          </div>

          <hr className="border-gray-300" />

          <div className={classNames(!showFilters && "hidden")}>
            <ShipmentFilters
              filters={filters}
              setFilters={setFilters}
              setSearchFilter={setFilterSearchApi}
            />
          </div>
        </div>

        <div className="flex flex-1 justify-between gap-2 md:gap-6 flex-col-reverse md:flex-row">
          <div className="flex flex-1" style={{ maxWidth: "45rem" }}>
            <FormText
              id="search"
              name="search"
              value={search}
              placeholder="Buscar guía..."
              containerClassname="flex flex-1"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="flex flex-1 justify-between sm:items-center flex-col-reverse gap-6 sm:gap-2 sm:flex-row">
            <div className="flex items-center text-xs">
              <span>Mostrar</span>
              <div className="inline mx-1">
                <FormSelect
                  id="registersNumber"
                  name="registersNumber"
                  label=""
                  selected={registersNumber}
                  options={[10, 25, 50, 100]}
                  className="!w-20 !h-8 !px-2"
                  optionString={(option) => option.toString()}
                  onSelectOption={(option) => setRegistersNumber(option)}
                />
              </div>
              <span>registros</span>
            </div>

            <Popover button={ExportButton}>
              <div className="flex flex-col font-semibold cursor-pointer">
                <div
                  onClick={() =>
                    exportShipmentsPDF(user.user!, filteredShipments)
                  }
                  className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                >
                  <div className="flex  gap-2 py-2 px-4 w-32">
                    <Icon icon={filePdfO} className="w-5 h-5" />
                    <span>PDF</span>
                  </div>
                </div>

                <hr />

                <div
                  onClick={() => exportShipmentsXLSX(filteredShipments)}
                  className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                >
                  <div className="flex gap-2 py-2 px-4 w-32">
                    <Icon icon={fileExcelO} className="w-5 h-5" />
                    <span>EXCEL</span>
                  </div>
                </div>

                <hr />

                <div
                  onClick={() => exportShipmentsTXT(filteredShipments)}
                  className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                >
                  <div className="flex gap-2 py-2 px-4 w-32">
                    <Icon icon={fileTextO} className="w-5 h-5" />
                    <span>TXT</span>
                  </div>
                </div>
              </div>
            </Popover>
          </div>
        </div>

        <div className="flex flex-1 flex-col">
          <div className="h-6 my-3">
            <div className={classNames(selecteds.length == 0 && "hidden")}>
              <Popover button={ActionsButton}>
                <div className="flex flex-col font-semibold cursor-pointer text-xs text-gray-700">
                  <div
                    onClick={() => {}}
                    className="flex flex-1 flex-col truncate hover:bg-main-500 hover:text-white"
                  >
                    <div className="flex  gap-2 py-2 px-4">
                      <PrinterIcon className="w-5 h-5" />
                      <span>Imprimir guías</span>
                    </div>
                  </div>

                  <hr />

                  <div
                    onClick={() => {}}
                    className="flex flex-1 flex-col truncate hover:bg-main-500 hover:text-white"
                  >
                    <div className="flex gap-2 py-2 px-4">
                      <PrinterIcon className="w-5 h-5" />
                      <span>Imprimir guías con etiquetas</span>
                    </div>
                  </div>

                  <hr />

                  <div
                    onClick={() => {}}
                    className="flex flex-1 flex-col truncate hover:bg-main-500 hover:text-white"
                  >
                    <div className="flex gap-2 py-2 px-4">
                      <PrinterIcon className="w-5 h-5" />
                      <span>Imprimir guías con etiquetas PDF</span>
                    </div>
                  </div>

                  <hr />

                  <div
                    onClick={() => {}}
                    className="flex flex-1 flex-col truncate hover:bg-main-500 hover:text-white"
                  >
                    <div className="flex gap-2 py-2 px-4">
                      <PrinterIcon className="w-5 h-5" />
                      <span>Imprimir guías con piezas</span>
                    </div>
                  </div>
                </div>
              </Popover>
            </div>
          </div>

          <ShipmentTable
            sortBy={sortBy}
            shipments={paginatedShipments}
            selectedShipments={selecteds}
            selectedAll={
              selecteds.length == filteredShipments.length &&
              selecteds.length > 0
            }
            onChangeSortBy={handleSortByChange}
            onSelectShipment={(shipment) => {
              if (selecteds.includes(shipment)) {
                setSelecteds(selecteds.filter((s) => s !== shipment));
              } else {
                setSelecteds([...selecteds, shipment]);
              }
            }}
            onSelectAll={() => {
              if (selecteds.length == filteredShipments.length) {
                setSelecteds([]);
              } else {
                setSelecteds([...filteredShipments]);
              }
            }}
          />

          <PaginationFooter
            currentPage={page}
            itemsPerPage={registersNumber}
            totalItems={filteredShipments.length}
            onPageChange={setPage}
          />
        </div>
      </div>
    </MainPage>
  );
};

export default ConsultShipments;
