import { FC } from "react";
import { Modal } from "../Atoms/Modal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { DestinationInterface } from "../../interfaces";
import { PrimaryButton, SecondaryButton } from "../Atoms/Buttons";

interface DestinationDetailsModalProps {
  open: boolean;
  destination: DestinationInterface;
  onEdit: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const DestinationDetailsModal: FC<DestinationDetailsModalProps> = ({
  open,
  destination,
  onEdit,
  setOpen,
}) => {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className="flex flex-1 mx-4 my-8"
      style={{ maxWidth: "55rem" }}
    >
      <div className="flex flex-1 flex-col">
        {/* Header */}
        <div className="flex flex-1 items-center justify-between">
          <h2 className="text-xl text-main-500 font-bold">
            {destination.location.code} - {destination.location.name}
          </h2>

          <div className="ml-3 flex h-7 items-center">
            <button
              type="button"
              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-main-500"
              onClick={() => setOpen(false)}
            >
              <span className="absolute -inset-2.5" />
              <span className="sr-only">Close panel</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>

        <hr className="border-gray-300 my-3" />

        {/* Body */}
        <div className="flex flex-1 flex-col text-gray-800 leading-0 text-sm">
          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold">
              Descripción
            </span>

            <span style={{ flex: 3 }}>{destination.location.name}</span>
          </div>

          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold">
              Dirección
            </span>

            <span style={{ flex: 3 }}>{destination.location.address}</span>
          </div>

          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold">Ciudad</span>

            <span style={{ flex: 3 }}>CCS</span>
          </div>

          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold">País</span>

            <span style={{ flex: 3 }}>Venezuela</span>
          </div>

          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold">Tienda</span>

            <span style={{ flex: 3 }}>{destination.businessUnit?.buCode}</span>
          </div>

          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold">
              Teléfono
            </span>

            <span style={{ flex: 3 }}>
              {destination.account.listAccountPhone[0]?.countryPhoneAccessCode}
              {destination.account.listAccountPhone[0]?.phoneNumber}
            </span>
          </div>

          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold">
              Teléfono 2
            </span>

            <span style={{ flex: 3 }}>
              {destination.account.listAccountPhone[1]?.countryPhoneAccessCode}
              {destination.account.listAccountPhone[1]?.phoneNumber}
            </span>
          </div>

          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold">Email</span>

            <span style={{ flex: 3 }}></span>
          </div>

          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold">
              Persona de contacto
            </span>

            <span style={{ flex: 3 }}>{destination.account.accountFullName}</span>
          </div>
        </div>

        <hr className="border-gray-300 my-6" />

        {/* Footer */}
        <div className="flex flex-1 justify-between items-center">
          <SecondaryButton onClick={onEdit}>
            <span className="w-20">Editar</span>
          </SecondaryButton>

          <PrimaryButton onClick={() => setOpen(false)}>
            <span className="w-20">Cerrar</span>
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};
