import axios from 'axios';
import {
  ListResponse,
  SimpleResponse,
  SingleResponse,
} from '../interfaces/Shipment/ListResponseDto';
import { AuthenticationServices } from './AuthenticationServices';
import {
  DepartmentInterfase,
  Nullable,
  ProfileInterfase,
  UpdStarStandUserInterfase,
  UserAccountsCountInterfase,
  UserAccountsExitsInterfase,
  UserAccountsInterfase,
} from '../interfaces';
import { UserAccountMapperDto } from '../utils/mappers';

const { REACT_APP_URL_PATH_API } = process.env;

export const getAllUser = async (
  accountID: string,
  accountSiteID?: string,
  userAccountID?: string
): Promise<ListResponse<UserAccountsInterfase[]> | undefined> => {
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  var response = await axios
    .get(
      `${REACT_APP_URL_PATH_API}/users/GetUserAccount?AccountID=${accountID}&ApplicationID=2&AccountSiteID=${
        accountSiteID ?? ''
      }&UserAccountID=${userAccountID ?? ''}`
    )
    .then(function (response) {
      var responsedto = response.data as ListResponse<UserAccountsInterfase[]>;
      return responsedto;
    })
    .catch(function (error) {
      //dirError= true;
      if (error.response) {
        console.log(error.message ?? '');
        return error.response as ListResponse<UserAccountsInterfase[]>;
      } else {
        console.log(error.message ?? '');
      }
      return [] as unknown as ListResponse<UserAccountsInterfase[]>;
    });

  return response;
};

export const getAllDepartament = async (): Promise<
  ListResponse<DepartmentInterfase[]> | undefined
> => {
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  var response = await axios
    .get(`${REACT_APP_URL_PATH_API}/users/GetDepartament`)
    .then(function (response) {
      var responsedto = response.data;
      return responsedto as ListResponse<DepartmentInterfase[]>;
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.message ?? '');
        return error.response as ListResponse<DepartmentInterfase[]>;
      } else {
        console.log(error.message ?? '');
      }
      return [] as unknown as ListResponse<DepartmentInterfase[]>;
    });

  return response;
};

export const getAllProfile = async (): Promise<
  ListResponse<ProfileInterfase[]> | undefined
> => {
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  var response = await axios
    .get(
      `${REACT_APP_URL_PATH_API}/users/GetProfile?ApplicationID=2&StatusID=1`
    )
    .then(function (response) {
      var responsedto = response.data;
      return responsedto as ListResponse<ProfileInterfase[]>;
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.message ?? '');
        return error.response as ListResponse<ProfileInterfase[]>;
      } else {
        console.log(error.message ?? '');
      }
      return [] as unknown as ListResponse<ProfileInterfase[]>;
    });

  return response;
};

export const getFilteredUsers = async (
  criteria: string
): Promise<Nullable<UserAccountsInterfase[]>> => {
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  const uri = `${REACT_APP_URL_PATH_API}/users/DynamicUserAccount?query=${criteria}&MaxQuery=50`;
  try {
    const token = await AuthenticationServices();
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    const response = await axios.get(uri);

    if (response.status === 200) {
      return response.data.model;
    }
  } catch (error) {}

  return null;
};

export const getExitsUser = async (
  userID?: string,
  userName?: string,
  email?: string
): Promise<SimpleResponse<UserAccountsExitsInterfase> | undefined> => {
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  var response = await axios
    .get(
      `${REACT_APP_URL_PATH_API}/users/ExitsUser?UserID=${
        userID ?? ''
      }&UserName=${userName ?? ''}&Email=${email ?? ''}`
    )
    .then(function (response) {
      var responsedto = response.data;
      return responsedto as SimpleResponse<UserAccountsExitsInterfase>;
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.message ?? '');
        return error.response as SimpleResponse<UserAccountsExitsInterfase>;
      } else {
        console.log(error.message ?? '');
      }
      return {} as unknown as SimpleResponse<UserAccountsExitsInterfase>;
    });

  return response;
};

export const createUserApi = async (
  userAccount: UserAccountsInterfase[]
): Promise<UpdStarStandUserInterfase> => {
  let userAccountId = '';
  let userId = '';
  let dirError = false;
  let errorMessage = '';

  let datamap = userAccount.map((dto: UserAccountsInterfase) =>
    UserAccountMapperDto(dto)
  );

  try {
    const token = await AuthenticationServices();
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    await axios
      .post(
        `${REACT_APP_URL_PATH_API}/users/UpsertUserStandar?nameApplication=MiTealca&withOnBoardingMail=${
          datamap[0].userID === undefined ? 'true' : 'false'
        }`,
        datamap
      )
      .then(function (response) {
        var data = response.data as SimpleResponse<UpdStarStandUserInterfase>[];
        if (!data[0].didError) {
          userAccountId = data[0].model?.UserAccountID ?? '';
          userId = data[0].model?.userID ?? '';
        } else {
          dirError = true;
          errorMessage = data[0].errorMessage;
        }
      })
      .catch(function (error) {
        dirError = true;
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          let errorData = error.response
            .data as SimpleResponse<UpdStarStandUserInterfase>[];
          errorMessage = errorData[0].errorMessage ?? '';
          console.log('Error generando guia:', errorData[0].errorMessage);
        } else {
          // Something happened in setting up the request that triggered an Error
          errorMessage = error.message ?? '';
          console.log('Error generando guia:', error.message);
        }
      });
  } catch (error) {
    console.log('Error generando guia:', error);
  }

  return {
    ...datamap[0],
    UserAccountID: userAccountId,
    userID: userId,
    didError: dirError,
    errorMessage: errorMessage,
  };
};

export const getAllUserClients = async (): Promise<
  ListResponse<UserAccountsCountInterfase[]> | undefined
> => {
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  var response = await axios
    .get(`${REACT_APP_URL_PATH_API}/users/GetUserAccountCount?ApplicationID=2`)
    .then(function (response) {
      var responsedto = response.data;
      return responsedto as ListResponse<UserAccountsCountInterfase[]>;
    })
    .catch(function (error) {
      //dirError= true;
      if (error.response) {
        console.log(error.message ?? '');
        return error.response as ListResponse<UserAccountsCountInterfase[]>;
      } else {
        console.log(error.message ?? '');
      }
      return [] as unknown as ListResponse<UserAccountsCountInterfase[]>;
    });

  return response;
};

export const DeleteUser = async (
  userId?: string,
  userAccountId?: string
): Promise<SingleResponse> => {
  let dirError = false;
  let errorMessage = '';

  try {
    const token = await AuthenticationServices();
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    await axios
      .post(
        `${REACT_APP_URL_PATH_API}/users/DeleteUserAccount?UserID=${
          userId ?? ''
        }&UserAccountID=${userAccountId ?? ''}`
      )
      .then(function (response) {
        var data = response.data as SingleResponse;
        if (data.didError) {
          dirError = true;
          errorMessage = data.errorMessage;
        }
      })
      .catch(function (error) {
        dirError = true;
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          let errorData = error.response.data as SingleResponse;
          errorMessage = errorData.errorMessage ?? '';
          console.log('Error generando guia:', errorData.errorMessage);
        } else {
          // Something happened in setting up the request that triggered an Error
          errorMessage = error.message ?? '';
          console.log('Error generando guia:', error.message);
        }
      });
  } catch (error) {
    console.log('Error generando guia:', error);
  }

  return {
    didError: dirError,
    errorMessage: errorMessage,
  } as SingleResponse;
};

export const ChangeUserPassword = async (
  userId?: string,
  UserLogin?: string,
  UserPassword?: string,
  Reset?: boolean
): Promise<SingleResponse> => {
  let dirError = false;
  let errorMessage = '';

  try {
    const token = await AuthenticationServices();
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    await axios
      .post(
        `${REACT_APP_URL_PATH_API}/users/ChangeUserPassword?UserID=${
          userId ?? ''
        }&UserLogin=${UserLogin ?? ''}&UserLogin=${
          UserLogin ?? ''
        }&UserPassword=${UserPassword ?? ''}&Reset=${Reset ?? 'false'}`
      )
      .then(function (response) {
        var data = response.data as SingleResponse;
        if (data.didError) {
          dirError = true;
          errorMessage = data.errorMessage;
        }
      })
      .catch(function (error) {
        dirError = true;
        if (error.response) {
          let errorData = error.response.data as SingleResponse;
          errorMessage = errorData.errorMessage ?? '';
          console.log('Error generando guia:', errorData.errorMessage);
        } else {
          errorMessage = error.message ?? '';
          console.log('Error generando guia:', error.message);
        }
      });
  } catch (error) {
    console.log('Error generando guia:', error);
  }

  return {
    didError: dirError,
    errorMessage: errorMessage,
  } as SingleResponse;
};
