import { FC } from 'react';
import { AccountInterface } from '../../interfaces';

export const AccountDetails: FC<AccountInterface> = ({
  accountFullName,
  abbreviationName,
  identificationNumber,
  fiscalAddress,
  listAccountPhone,
  cityName,
  cityCode,
  BuCodeSource,
  businessUnit,
}) => {
  return (
    <div className="flex flex-1 flex-col text-xs gap-0.5">
      <div className="flex flex-1 gap-4">
        <div className="flex justify-end" style={{ minWidth: '4.5rem' }}>
          <span className="font-bold text-gray-600 text-right">Nombre:</span>
        </div>
        <span className="text-gray-800">{accountFullName}</span>
      </div>
      <div className="flex flex-1 gap-4">
        <div className="flex justify-end" style={{ minWidth: '4.5rem' }}>
          <span className="font-bold text-gray-600 text-right">Documento:</span>
        </div>
        <span className="text-gray-800">
          {abbreviationName}
          {identificationNumber}
        </span>
      </div>
      <div className="flex flex-1 gap-4">
        <div className="flex justify-end" style={{ minWidth: '4.5rem' }}>
          <span className="font-bold text-gray-600 text-right">Dirección:</span>
        </div>
        <span className="text-gray-800">
          {fiscalAddress ?? businessUnit?.location.address}
        </span>
      </div>
      <div className="flex flex-1 gap-4">
        <div className="flex justify-end" style={{ minWidth: '4.5rem' }}>
          <span className="font-bold text-gray-600 text-right">Tienda:</span>
        </div>
        <span className="text-gray-800">
          {businessUnit?.buCode} - {businessUnit?.buName}{' '}
        </span>
      </div>
      <div className="flex flex-1 gap-4">
        <div className="flex justify-end" style={{ minWidth: '4.5rem' }}>
          <span className="font-bold text-gray-600 text-right">Ciudad:</span>
        </div>
        <span className="text-gray-800">
          {cityName} ({cityCode})
        </span>
      </div>
      <div className="flex flex-1 gap-4">
        <div className="flex justify-end" style={{ minWidth: '4.5rem' }}>
          <span className="font-bold text-gray-600 text-right">Teléfono:</span>
        </div>
        <span className="text-gray-800">
          {listAccountPhone[0]?.phoneNumber}
        </span>
      </div>
    </div>
  );
};
