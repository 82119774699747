import { FC, useMemo, useState } from "react";
import { Popover } from "../Atoms/Popover";
import { logout, setClient } from "../../store/slices";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/TEALCA-logo.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  KeyIcon,
  BellIcon,
  UserIcon,
  ArrowLeftOnRectangleIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { AccountInterface } from "../../interfaces";
import { AccountChangeClientModal } from "../Account/AccountChangeClientModal";
import { PrintLabel } from "../../services/printerServices";
import {
  getAccount,
  getBusinessUnits,
  getBusinessUnitsPolly,
} from "../../services";

export const Header: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [openModal, setOpenModal] = useState(false);

  const user = useAppSelector((state) => state.user)!;
  const notifications = useMemo(
    () => ["Notificación 1", "Notificación 2", "Notificación 3"],
    []
  );

  async function handleAccountSiteSelected(newAccountSite: AccountInterface) {
    let accountSiteInfo = await getAccount(newAccountSite.id);
    const businessUnits = await getBusinessUnits();
    var businessUnitsSelected = businessUnits!.find(
      (t) => t.buCode == newAccountSite?.BuCodeSource
    )!;

    newAccountSite = {
      ...newAccountSite,
      businessUnit: businessUnitsSelected,
      abbreviationName: accountSiteInfo?.abbreviationName ?? "",
      identificationNumber: accountSiteInfo?.identificationNumber ?? "",
      listAccountPhone: accountSiteInfo?.listAccountPhone ?? [],
      listAccountEmail: accountSiteInfo?.listAccountEmail ?? [],
    };

    dispatch(
      setClient({
        ...newAccountSite,
        listAuthorizingAccount: user.client
          ?.listAuthorizingAccount as AccountInterface[],
      })
    );
    setOpenModal(false);
    navigate("/dashboard");
  }

  const handlePrintLabel = async () => {
    const printSucceed = await PrintLabel(4759124, 60, "1120B", [1]);
    if (!!printSucceed) {
      alert("La etiqueta se imprimió correctamente.");
    }
  };

  const NotificationButton = useMemo(() => {
    return (
      <div>
        <BellIcon className="w-7 h-7 text-gray-500" />

        {notifications.length > 0 && (
          <div className="absolute bottom-0 -right-1 h-4 w-4 bg-main-500 rounded-full text-white text-xs flex items-center justify-center">
            {notifications.length > 9 ? "9+" : notifications.length}
          </div>
        )}
      </div>
    );
  }, [notifications]);

  return (
    <div className="flex flex-1 py-3 px-2 sm:px-6 justify-between bg-gray-100 border fixed lg:static bg-gray-50 z-20 w-full">
      {/* Logo */}
      <div
        onClick={() => navigate("/dashboard")}
        className="flex justify-center cursor-pointer"
      >
        <img src={logo} alt="Tealca logo" width="75" height="75" />
      </div>

      {/* User data */}
      <div className="flex flex-col gap-1">
        <div className="flex items-center justify-end">
          {/* <div className="relative px-1 mr-4 cursor-pointer">
            <Popover button={NotificationButton}>
              <div className="flex flex-col">
                {notifications.map((notification, index) => (
                  <div
                    key={index}
                    className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                  >
                    <div className="py-2 px-4">
                      <span>{notification}</span>
                    </div>

                    {index < notifications.length - 1 && (
                      <hr className="w-full text-gray-400" />
                    )}
                  </div>
                ))}

                {notifications.length == 0 && (
                  <div className="flex flex-1 flex-col text-sm text-gray-700 truncate">
                    <div className="p-4 text-gray-500 font-semibold">
                      <span>No hay notificaciones</span>
                    </div>
                  </div>
                )}
              </div>
            </Popover>
          </div> */}

          {/* <div className="border h-full border-gray-300 mx-2" /> */}

          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-700 truncate">
              <span className="hidden sm:inline">Buenas tardes, </span>
              <b>
                {user.user?.name} {user.user?.lastName}
              </b>
            </span>

            <div className="cursor-pointer">
              <Popover button={<UserIcon className="w-7 h-7 text-gray-500" />}>
                <div className="flex flex-col">
                  <div
                    onClick={() => dispatch(logout())}
                    className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                  >
                    <div className="flex gap-2 py-2 px-4">
                      <ArrowLeftOnRectangleIcon className="w-5 h-5" />
                      <span>Cerrar Sesión</span>
                    </div>

                    <hr className="w-full text-gray-400" />
                  </div>

                  <div
                    onClick={() => navigate("/reset-password")}
                    className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                  >
                    <div className="flex gap-2 py-2 px-4">
                      <KeyIcon className="w-5 h-5" />
                      <span>Cambiar Contraseña</span>
                    </div>
                  </div>
                  {/* 
                  <div
                    onClick={handlePrintLabel}
                    className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                  >
                    <div className="flex gap-2 py-2 px-4">
                      <KeyIcon className="w-5 h-5" />
                      <span>imprimir prueba</span>
                    </div>
                  </div> */}

                  {(user.client?.listAuthorizingAccount.length ?? 1) > 1 && (
                    <div
                      onClick={() => setOpenModal(true)}
                      className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                    >
                      <div className="flex gap-2 py-2 px-4">
                        <UsersIcon className="w-5 h-5" />
                        <span>Cambiar Cliente</span>
                      </div>
                    </div>
                  )}
                </div>
              </Popover>
            </div>
          </div>
        </div>

        <div className="flex flex-1 gap-2 text-sm justify-end">
          <span className="text-gray-700">Cliente:</span>
          <span className="text-gray-900 font-semibold">
            {user.client?.accountFullName}
          </span>
        </div>

        {!!openModal && (
          <AccountChangeClientModal
            accounList={
              user.client?.listAuthorizingAccount as AccountInterface[]
            }
            account={user.client as AccountInterface}
            open={openModal}
            setOpen={setOpenModal}
            onSaveChange={handleAccountSiteSelected}
          />
        )}
      </div>
    </div>
  );
};
