import axios from "axios";
import { CCWIntegracionV2 } from "../interfaces";
import { ListResponse } from "../interfaces/Shipment/ListResponseDto";
import { AuthenticationServices } from "./AuthenticationServices";
const { REACT_APP_URL_PATH_API } = process.env;

export const searchManifiestInternational = async (
  code: string,
  cutomerCode: string
): Promise<ListResponse<CCWIntegracionV2[]>> => {  
  try {
    const token = await AuthenticationServices();
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    var response = await axios
      .post(
        `${REACT_APP_URL_PATH_API}/Integration/GetDownloadV2?manifiestId=${code}&customerId=${cutomerCode}`,
        {}
      )
      .then(function (response) {
        var data = response.data as ListResponse<CCWIntegracionV2[]>;
        return data;
      })
      .catch(function (error) {
        if (error.response) {
          let errorData = error.response.data as ListResponse<CCWIntegracionV2>;
          console.log(
            "Error obteniendo data de stephy Tracking:",
            errorData.errorMessage
          );
        } else {
          console.log(
            "Error obteniendo data de stephy Tracking:",
            error.message
          );
        }
      });

    return response as ListResponse<CCWIntegracionV2[]>;
  } catch (error) {
    console.log("Error obteniendo data de stephy Tracking:", error);
  }

  return {} as ListResponse<CCWIntegracionV2[]>;
};

export const searchManifiestInternationalExcel = async (
  code: string,
  cutomerCode: string
): Promise<any> => {
  const token = await AuthenticationServices();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(
    `${REACT_APP_URL_PATH_API}/Integration/GetDownloadV2?manifiestId=${code}&customerId=${cutomerCode}&typeResponse=2`,
    requestOptions
  );
};
