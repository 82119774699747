import { FC, useEffect } from "react";
import "./App.css";
import AppRoutes from "./utils/AppRoutes";
import { LogoutTimer } from "./components";
import { Route, Routes } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { getBusinessUnitsPolly } from "./services/BusinessUnitServices";
import {
  alertService,
  getCities,
  getCitiesByBu,
  getPieceCategories,
  getTaxIdentificationTypes,
} from "./services";
import Alert from "./components/Alert/Alert";
import {
  setInmutableCities,
  setInmutableBusinessUnits,
  setInmutablePieceCategories,
  setInmutableTaxIdentificationTypes,
  setInmutableCitiesByBu,
  setInmutableRangeDeclareList,
  setInmutableExchangeRate,
} from "./store/slices/inmutable";
import { getBaseAppData } from "./services/applicationDataService";

const App: FC = () => {
  const dispatch = useAppDispatch();
  const logged = useAppSelector((state) => state.user.logged);
  useEffect(() => {
    const findTaxIdentificationTypes = async () => {
      const taxIdTypes = await getTaxIdentificationTypes();
      if (!!taxIdTypes) {
        dispatch(setInmutableTaxIdentificationTypes(taxIdTypes));
      }
    };

    if (logged) findTaxIdentificationTypes();
  }, [logged]);

  useEffect(() => {
    const findPiecesCategories = async () => {
      const categories = await getPieceCategories();
      if (!!categories) {
        dispatch(setInmutablePieceCategories(categories));
      }
    };

    if (logged) findPiecesCategories();
  }, [logged]);

  useEffect(() => {
    const findBusinessUnits = async () => {
      const businessUnits = await getBusinessUnitsPolly();

      if (!!businessUnits) {
        dispatch(setInmutableBusinessUnits(businessUnits));
      }
    };
    findBusinessUnits();
  }, []);

  useEffect(() => {
    const findCities = async () => {
      const cities = await getCities();
      if (!!cities) dispatch(setInmutableCities(cities));
    };

    if (logged) findCities();
  }, [logged]);

  useEffect(() => {
    const findCitiesBybu = async () => {
      const cities = await getCitiesByBu();
      if (!!cities) dispatch(setInmutableCitiesByBu(cities));
    };

    if (logged) findCitiesBybu();
  }, [logged]);

  useEffect(() => {
    const getAppData = async () => {
      const response = await getBaseAppData(null);
      if (!response.didError && !!response.model) {
        const appDataDto = response.model;
        // Set Range Declare List
        if (!!appDataDto.rangeDeclareList) {
          dispatch(setInmutableRangeDeclareList(appDataDto.rangeDeclareList));
        }
        // Set Exchange Rate
        if (!!appDataDto.exchangeRate) {
          dispatch(setInmutableExchangeRate(appDataDto.exchangeRate));
        }
      } else {
        alertService.error(
          "Error al cargar los datos de la aplicación",
          response.errorMessage,
          { autoClose: false }
        );
      }
    };

    getAppData();
  }, [logged]);

  return (
    <>
      <Routes>
        {AppRoutes.filter((r) => r.auth == !!logged).map((route, index) => {
          const { element, ...rest } = route;
          return <Route key={index} {...rest} element={element} />;
        })}
      </Routes>
      <LogoutTimer />
    </>
  );
};
export default App;
