import { v4 } from "uuid";
import {
  DeliveryEnum,
  TrackingEnum,
  PieceInterface,
  PaymentModeEnum,
  SenderInterface,
  LocationInterface,
  AccountInterface,
  ShipmentInterface,
  ShipmentStatusEnum,
  ShippingMethodEnum,
  PreShipmentInterface,
  AccountEmailInterface,
  AccountPhoneInterface,
  BusinessUnitInterface,
  DestinationInterface,
} from "../interfaces";

const SERVICES = ["Regular", "Sobre", "Internacional"];
const PHONE_OPTIONS = ["0414", "0416", "0424", "0426", "0412"];
const NAMES = [
  "Andres Gago",
  "SAMUEL SANCHEZ",
  "EUKARIS DEL SOCORRO VELIZ PADILLA",
  "Ernesto Campos",
  "Juan Carlos Guerrero cáceres",
  "Aixa Milagro Herrera",
  "Enmanuel Boston",
  "José Ramos",
  "Danilo Fuenmayor",
  "ESTEBAN CASTILLO",
  "MILAGROS RODRIGUEZ",
  "NICOLAS JOSE TINEO GARCIA",
  "Jesus Javier",
  "Raúl Vasquez",
  "Moisés Blanco",
  "Osman Chahnazaroff",
  "Alejandro Borrero",
  "Jesus Montiel",
  "Alfredo Alejandro Corredor Olaizola",
  "Armando pereira",
  "Oneirys Vasquez",
  "Noel Jose Torrealba Farfan",
  "Kenneth Mathison",
  "JESSIKA TONG CARRASQUERO",
  "Luis millan",
  "Jorge Chávez Cordero",
  "Alejandro Melim",
  "alexander jose carrizo del toro",
  "Fabian Jesus Gonzalez Bravo",
  "GERARDO JOSE ABREU ARANDIA",
  "Jesus Rafael Rondón González",
  "Alexander carruyo",
  "Miguel Angel Sumoza Guillen",
  "Rosa Jaidy Ucategui Rojas",
  "Argenis Eduardo Peñaranda Perdomo",
  "Tomas mujica",
  "Alberto Hernandez",
  "Mizraim Ortiz Tovar",
  "Antonio Jose Blanco",
  "Hector Rafael Romero Rodriguez",
  "Kassem Montiel",
  "Leonardo Enrique",
  "Fernando rey",
  "Naika Darling Salazar Amador",
  "DIEGO VERGEL",
  "Lorena Torres",
  "Luis Sucre",
  "Noel Enrique Olivero Vargas",
  "Daniel Martinez",
  "Miguel mccarthy",
  "Wellingron la rosa",
  "Daisy maria jaimes",
  "Ronald Guillén",
  "trisguel labrador",
  "Lucio Bastidas",
  "Jose Rafael Rosales Torrealba",
  "pablo mendez",
  "Luis Fernando Castillo Coronado",
  "JOSE CORREIA",
  "Anthony Farias",
  "Diego Vasquez",
  "Felix Miguel Martinez Aray",
  "Jose gonzalez",
  "Yeison schweizer",
  "RICHARD JOSE GUEVARA GOMEZ",
  "Jorge carrillo",
  "Gesner Gruber",
  "Gilberto esteva",
  "carlos rogelio gil becerra",
  "Jose Sanchez",
  "Jhonathan Diego De Freitas",
];
const ADDRESS = [
  "Carrera 40 de Pirineos, Conjunto Residencial Terrazas de Pirineos, Casa 40",
  "Av Ramón Narváez, parroquia las tacariguas, diagonal al parque santos michelena",
  "Av. Bicentenario Edif. Zamora, P.b. Al Lado De La Linea De Taxi Lo Mejor De Lo Mejor",
  "Calle Apure c/c Cajigal  s/n, Sector Pueblo. Anaco",
  "Calle San Carlos Urb Urdaneta Centro de profesionales casa garpi Barcelona estado Anzoategui",
  "Barrio los cortijos",
  "ENVIAR A TEALCA , UBICADO EN EL CENTRO COMERCIAL VASCONIA DEL TAMBOR, LOS TEQUES ESTADI MIRANDA",
  "PORTUGUESA",
  "Calle Regularización, Centro comercial Géminis Center",
  "Urb. Las Brisas de Cúa, Conjunto Res. Colina Apamate, Calle 6, casa N° 59, Cúa",
  "Agencia Tealca Maracay Oeste Av. Ramón Narváez, Local No. 3, Sector La Coromoto",
  "Domesa, cc. Luz eléctrica, calle Miquilen con esquina, calle carabobo",
  "Tecnosat Electronica c.a",
  "Calle El servicio, Parque Residencial Valle Arriba, Edificio Campo Elias",
  "AV.LIBERTADOR #28 BARRIO AJURO",
  "CIUDAD BOLIVAR",
  "Chamita calle 13 los Bucares casa 1-189",
  "Realizar envío a la oficina 5210 Tealca San Diego Valencia Carabobo",
  "Av Vollmer San Bernardino",
  "los caobos",
  "8va transversal de mariperez",
  "Av. Luis Beltrán Prieto Figueroa, entre calles Los Rosales y Monagas, Edif. Mirimire, Parroquia Carirubana, Municipio Carirubana, Punto Fijo, Estado Falcón.",
  "Av josefa camejo edf miranda",
  "AV FUERZAS ARMADAS, CENTRO COMERCIAL LOS CHAGUARAMOS",
  "Av independencia sector la vereda casa #233",
  "Calle Carabobo Entre Madariaga E Independencia,local N°2,sector Centro,san Carlos Estado Cojedes.",
  "la morita 2 sector santa ines barrio la caridad del cobre",
  "mturin",
  "Unare 2, Sector 2, bloque 11, apto 01-09, primer piso ( frente a la clínica unare)",
  "Cabimas",
  "Avenida de Montecristo , los dos caminos, oficina de tealca",
  "OFICINA PRINCIPAL TEALCA MUNICIPIO PAEZ 3301",
  "Urb los APAMATES calle 3 n 41 zona industrial",
  "MARACAY",
  "4ta av entre calles 25 y 26, sector el Cementerio, Edificio s/n, local pb s/n municipio Independencia",
  "AV. FCO. DE MIRANDA CON CALLE 18 SUR. EDIF. LOS GERANIOS # 2 Y 3 FRENTE A LA PANADERIA SIRIA",
  "CALLE AZCUE CC VICTORIA LOCAL 10 STIL MUEBLES",
  "4 Av de Prebo",
  "Av. Las Palmas, Calle Santa Ana, Boleita Sur",
  "AV LA PATRIA, SAN FELIPE YARACUY",
  "Calle 16. Barrio la arenosa",
  "Carrera 27 entre calles 15 y 16 núm 15-78",
  "Urbanización Coromoto",
  "Maracaibo",
  "El mojan Residencia Villa Castellis casa 8",
  "Calle 5 entre Av Bolivar y 9",
  "ciudad bolivar",
  "Urb las garzas",
  "Av Jose Antonio Anzoategui  Centro Comercial Unicasa Anaco",
  "Tealca AV Staudium C.C cardon Pto. La Cruz, Anzoategui",
  "AV. ANTONIO DE BERRIOS",
  "Av boyacá entre calle Plaza y calle Sucre casa con piedras #86-43",
  "AGENCIA TEALCA PUERTO LA CRUZ  C,C CARDON NR 2909 ANZOATEGUI",
  "TEALCA Carretera H, C.C. Borjas (Centro 99), Local PB, Parroquia Carmen Herrera, Municipio Cabimas, Cabimas, Estado Zulia. CODIGO POSTAL 8405 cabimas8405@tealca.com",
  "El mojan Calle 25 entre avenidas 6 y 7",
  "Urbanización la Coromoto calle 165",
  "Av 1, Sector 2 De Unare II, Edificio IPECA Local 2, Pb",
  "MRW 07010 ALTA VISTA",
  "Nueva Casarapa, Sector Los Portales II, Calle E9, Casa E9-188",
  "Mrw, cc cristal ubicado en naguanagua el mrw de naguanagua valencia, Edo carabobo.",
  "Carretera h centro comercial Borjas tealca cabimas",
  "AV CUMANA CARUPANO  ZONA INDUSTRIAL EL PEÑÓN ENTRADA BRISAS DEL GOLFO SECTOR POLIGONO DETIROS GALPON TRANSPORTE EL GUÁCHARO LOCAL 1",
  "oficina de tealca avenida miranda, maturin monagas",
  "av rotaria n 68",
  "av los cedros, local 164, maracay,  municipio girardos, MAXIPARTS",
  "Urbanización altos de Carvajal apartamento 1-1-3 Valera edo trujilo",
  "Av Miranda Cumaná Edo sucre",
  "Urb. El encantado, Edif. Las Haciendas, Torre 11",
  "Enviar ala oficina zoom delicias mcbo-zulia   frente al hotel gran delicia.",
  "Villa manamo calle 2",
  "Calle Principal El Rosario. Tocuyito, Municipio Libertador",
  "Calle 3 cruce con carrera 5, casa 35 sector barrio obrero",
  "Alto barinas",
  "Urb los saltos calle 8 manz 18 casa 9",
];
const EMAILS = [
  "jramire.mzv5h7+2-ogqzdombxguztamjy@mail.mercadolib",
  "elinor.chavez.chavezelinor@mercadolibre.com.ve",
  "vcarolinashernandez@gmail.com",
  "jrubal.2xts00+2-ogiytsnjwgy4danzz@mail.mercadolibr",
  "enderalexander.labradormoreno.enderalexanderlabrad",
  "joserolando.herreraperez.joserolandoherreraperez@m",
  "victorhenderson.motabancampos.vmotabancampos05289@",
  "wespino.qb55wc+2-ogq3dmmzshaztcnzq@mail.mercadolib",
  "jmilano.td8fqn+2-ogqytkmbxha3damjs@mail.mercadolib",
  "jsolorz.rczjm5+2-ogiztgmbwg42dmnbw@mail.mercadolib",
  "fabina.cardenas.fabinacardenas@mercadolibre.com.ve",
  "mfraga.nyfjjl+2-ogqzdsmzxgi3diobx@mail.mercadolibr",
  "pnunez.945ysn+2-ogiztemrqgm3tgojv@mail.mercadolibr",
  "dvasque.7tjv0z+2-ogizdgobqha2dqmjx@mail.mercadolib",
  "felzog.y1lryl+2-oguzdcnrugm4doobv@mail.mercadolibr",
  "klimacco.cabrera.klimaccocabrera@mercadolibre.com.",
  "mpapa.hq8rcd+2-ogq3tkobuga2dqmjq@mail.mercadolibre",
  "corozco.662rjx+2-ogq3tqnzzgu2tanbv@mail.mercadolib",
  "ventasvillacostvenezuela@gmail.com",
  "douglisber.hernndez.douglisberhernandez91350@merca",
  "mrepues.dd7lg8+2-ogi2tsmjqg42demby@mail.mercadolib",
  "omarjsalazarr2@gmail.com",
  "desiree166_4@hotmail.com",
  "vtoledo.h9sw1m+2-ogi2dsnrxgi3dqmrx@mail.mercadolib",
  "lrodrig.fmnqgd+2-ogq4dcmbxgiztmnby@mail.mercadolib",
  "wjaimes.6dc3pn+2-ogi3dembrhe3dsnjz@mail.mercadolib",
  "sruiz.7jww3b+2-ogqzdenjqga4dgmrx@mail.mercadolibre",
  "theshaggy1989@gmail.com",
  "lsosa.kcq2b8z+2-ogqztimzzga3tqobw@mail.mercadolibr",
  "rey.ortega.orre2096838@mercadolibre.com.ve",
  "nmontil.93thzfq+2-ogq2dimzvgeydenrs@mail.mercadoli",
  "cmiquil.rj71k8+2-ogq4dsnztgyzdgojr@mail.mercadolib",
  "alejandro.perez.alejandrojesusperez@mercadolibre.c",
  "armandojose.cedeoramos.acedenoramos53998@mercadoli",
  "gabriel.camachobressan.gabrielacho12@mercadolibre.",
  "info@gabarito.com.ve",
  "cpadill.6hg9xh+2-ogq2tonzxge3tomrz@mail.mercadolib",
  "abrigno.2whqds6+2-ogqzdiojxgy2tqmbv@mail.mercadoli",
  "imartin.0vpfhc+2-ogi2dsnbrgqydcnjt@mail.mercadolib",
  "wgonzal.4w66wf+2-ogi2daobvgq4dmojr@mail.mercadolib",
  "jcoto.hn9sj5+2-ogq2tkmjqg42timbr@mail.mercadolibre",
  "arodrig.bnvp8d+2-ogq4tmnjwg4ytinbs@mail.mercadolib",
  "ydemald.724t1ns+2-ogq4tonbqgmzdgnbv@mail.mercadoli",
  "jamvenegrado@yahoo.es",
  "mmouawa.0qpkkm+2-ogizdgobvgy2tonzq@mail.mercadolib",
  "aperez.q3rhdq6+2-ogqydomrwge2tsmbv@mail.mercadolib",
  "yhon.diaz.diyh8640277@mercadolibre.com.ve",
  "yherrer.fysr5s+2-ogqzdkojxgy2dqnjz@mail.mercadolib",
  "aseijas.7xn19b+2-ogizdknzzgy3tkmjr@mail.mercadolib",
  "alunat.tht6y3k+2-ogu2dgobugezdkmjq@mail.mercadolib",
  "gcesca.8m5c9t+2-ogqytgmrvguytiojy@mail.mercadolibr",
  "daraque.fmygy9+2-ogizdonjrheytqnbx@mail.mercadolib",
  "reinaldo.rzemien.reinaldorzemien@mercadolibre.com.",
  "carmen.prez.carmesi17@mercadolibre.com.ve",
  "hbrizue.ssvx5y+2-ogqztsojsgm2tkojw@mail.mercadolib",
  "luminarteca@hotmail.com",
  "daily.suarez.suda1196873@mercadolibre.com.ve",
  "olivia.molero.oliviamolero@mercadolibre.com.ve",
  "oaguila.c0s336+2-ogqytqmrvhaydanjq@mail.mercadolib",
  "jruiz.v1nbxx+2-ogizdiobrgizdknru@mail.mercadolibre",
  "walter.gallo.gawa2529693@mercadolibre.com.ve",
  "luigi.pizzica.pizzicaluigi@mercadolibre.com.ve",
  "otorres.ccvkz0+2-ogqydsnrugq2tsmry@mail.mercadolib",
  "tbellor.g15l77+2-ogq3tinzzga2tgnrr@mail.mercadolib",
  "ycolmen.m3yc06+2-oguztcobvha4tamrx@mail.mercadolib",
  "pannitto12@gmail.com",
  "carlos.chirinos.cchirinos6326@mercadolibre.com.ve",
  "ediley.chourio.edikarina@mercadolibre.com.ve",
  "agente_11_11@hotmail.com",
  "abarrio.qwyzr90+2-ogq2dsmjrha3tqmbs@mail.mercadoli",
  "yuleixy0727@gmail.com",
  "katiuska.gonzlez.goka5977276@mercadolibre.com.ve",
  "jmejias.q03wb2+2-ogiztcnzvgy3timjt@mail.mercadolib",
  "jgonzal.9vq5pn+2-ogq4tonbvgu4domry@mail.mercadolib",
  "amesa.grz9lg+2-ogqydcnrtha2tgnbz@mail.mercadolibre",
  "josegabrieldaboingarcia@hotmail.com",
  "ricardoandres.gonzalezgarfides.ricardoandresgonzal",
  "sixto.reyesmendoza.sixreymen@mercadolibre.com.ve",
  "rvilori.1128743593+2-ogiydambqgaztkojwgmydimzthe==",
  "wuzcate.b6nrrj+2-ogizdembsgq2dknjz@mail.mercadolib",
  "ventasgimagi@hotmail.com",
  "jhonny.perdigon.lavidafacil@mercadolibre.com.ve",
  "celenia.tuarez.ctuarez35008@mercadolibre.com.ve",
  "rafael.martinez.segundo76@mercadolibre.com.ve",
  "darwinsalazar183@gmail.com",
  "saul.guevara.gusa206901@mercadolibre.com.ve",
  "leonardo.nava.invleomotos@mercadolibre.com.ve",
  "autoparteseurov12@gmail.com",
  "ronyalberto.castillocastillo.ronyalbertocastilloca",
  "arlet.mora.amdecobabyshop@mercadolibre.com.ve",
  "yohniel.jimnez.yohnieljimnez@mercadolibre.com.ve",
  "lquinon.q254kb+2-ogq3danzyha2dkmzw@mail.mercadolib",
  "gaguile.y7jvh8+2-ogq2tenjxge2dsoju@mail.mercadolib",
  "mariu.64@hotmail.com",
  "a.osorio@multientregapanama.com",
  "iinvloo.l9syxl+2-ogqzdsojxgi2dqmzw@mail.mercadolib",
  "psalla.27lnhw+2-ogqydgmrwgu2tgmzy@mail.mercadolibr",
  "ventasizuparts@gmail.com",
  "inversionesjn3030ca@gmail.com",
  "dcarran.sln17f7+2-ogq2dgnjsg4ztcojw@mail.mercadoli",
  "nina.quaranta.nination@mercadolibre.com.ve",
  "gabriel.martinez.gabrielmartinez91@mercadolibre.co",
  "fgarcia.8c5fnl+2-ogi3dgobqgm2dcobt@mail.mercadolib",
  "ral.arocha.rear5@mercadolibre.com.ve",
  "cagsdesing@gmail.com",
  "scontre.42wqrnk+2-ogqztemjsg43dmnby@mail.mercadoli",
  "fdiaz.191df6+2-ogqytemrqha2tsmjs@mail.mercadolibre",
  "yolimar.valero.valeroyolimar@mercadolibre.com.ve",
];
const BU_NAMES = [
  "TEALCA MIAMI",
  "TEALCA MADRID",
  "TEALCA PANAMA",
  "TIENDA VIRTUAL QUINTA CRESPO",
  "MENDEZ Y FUENTES C.A",
  "CORPORACIÓN ELO-ONE, C.A",
  "CORPORACIÓN  ELO-ONE, C.A.",
  "TIED EXPRESS, C.A",
  "C & C EXPRESS, C.A",
  "MASTER SERVICE 1345, C.A",
  "MASTER SERVICE 1345, C.A",
  "MASTER SERVICE 1345, C.A",
  "VEN & LATIN , C.A.",
  "CORPORACIÓN CCFG, C.A.",
  "ENVÍOS SAGA EXPRESS, C.A",
  "COMERCIAL 23 EL PARAÍSO, C.A.",
  "SPEED CARGO 4010, C.A.",
  "INVERSIONES MILDASI, C.A.",
  "LA CANDELARIA",
  "INVERSIONES MILDASI C.A.",
  "ENCOMIENDAS G2N, C.A.",
  "CADENA EXPRESS, C.A",
  "YEPMEN TRANSPORTE, C.A.",
  "ENCOMIENDAS CH EXPRESS, C.A.",
  "IPG 2000 EXPRESS, C.A",
  "ENCOMIENDAS PAULA BELL, C.A",
  "GRUPO YTA, C.A",
  "SERVICIO DE TRANSPORTE ADAIA ",
  "ALTAMIRA",
  "MM SERVICIOS INTEGRALES, C.A",
  "J&J HERMANOS PARISCA REYES",
  "INVERSIONES PAHIES 77, C.A.",
  "ENVÍOS EXPRESS LOS SALIAS, C.A",
  "ENVÍOS EXPRESS LOS SALIAS, C.A",
  "ENCOMIENDAS JULIKAR, C.A. ",
  "INVERSIONES AURA 2006, C.A",
  "JUAN GRIEGO",
  "ENCOMIENDAS SUCRE, C.A",
  "TRADISER, C.A",
  "TRADISER, C.A",
  "VENEZOLANA PG & G, C.A",
  "JAG EXPRESS, C.A",
  "POWER SERVICE 100, C.A.",
  "ENCOMIENDAS REGAR, C.A.",
  "SERVICIOS DE ENCOMIENDAS CALEXMA C.A.",
  "STERLING EXPRESS, C.A",
  "LA DILIGENCIA EXPRESS, C.A",
  "TOM´S EXPRESS, C.A",
  "REPRESENTACIONES ZORCO, C.A.",
  "REPRESENTACIONES ZORCO, C.A.",
  "INVERSIONES ORIVEN, C.A.",
  "KAMOY, C.A.",
  "KAMOY, C.A.",
  "TRANSPORTES Y SERVICIO CEAMSO, C.A.",
  "TUCUPITA",
  "INVERSIONES PANP 69, C.A.",
  "INVERSIONES PANP 69, C.A.",
  "RAPIDEZCA, C.A.",
  "MARACAY OESTE",
  "INVERSIONES PADI 2823, C.A.",
  "INVERSIONES ESTRADA BARRETO, C.A. ",
  "INVERSIONES ESTRADA BARRETO, C.A.",
  "MULTISERVICIOS EXPRESOS 2013, C.A",
  "MULTISERVICIOS EXPRESOS 2013, C.A",
  "RECORD 2017, C.A.",
  "INVERSIONES PVS, C.A.",
  "EPG CARGO SERVICES EXPRESS C.A",
  "INVERSIONES JAMYR 5, C.A.",
  "VENENCOMIENDA, C.A.",
  "VENENCOMIENDA, C.A.",
];
const BUSINESS_UNITS = [
  "MERCADOLIBRE VENEZUELA S.R.L",
  "TAUREL & CIA. SUCRS, C.A.",
  "LABORATORIOS ELMOR S.A.",
  "TARSUS REPRESENTACIONES, C.A.",
  "CONTINENTAL DE SISTEMAS Y MAQUINAS C.A. CONTIMACA ",
  "TIED EXPRESS, C.A.",
  "C & C EXPRESS C.A.",
  "MASTER SERVICES 1345, C.A. ",
  "CORPORACION CCFG, C.A.",
  "CADENAS EXPRESS MF, C.A",
  "CASA DE REPRESENTACION JERICO, C.A.",
  "RADIO MOVIL DIGITAL RMD VENEZUELA, C.A.",
  " VEHICLE SECURITY RESOURCES DE VENEZUELA C.A",
  "CORP. ISC BUNKER RAMO DE VENEZUELA C.A.",
  "SALVADOR CUPELLO, C.A.",
  "DISTRIBUIDORA LEKLEPAR, C.A.",
  "RONTARCA WILLIS, C.A. SOCIEDAD DE CORRETAJE DE SEGUR",
  "ACO ALQUILER, S.A.",
  "GRUPO B.L., C.A.",
  "DROGUERIA DEL CARIBE, C.A.",
  "CORPORACION EMCETA, C.A.",
  "ADELCA MI TIENDA VETERINARIA, C.A.",
  "INVERSORA ISLAMAR,C.A.",
  "DISTRIBUCION DE TECNOEQUIPOS, C.A.",
  "CAMOPLA INVERSIONES, C.A.",
  "B.T.N. TIME, C.A.",
  "INVERSIONES 201017, C.A.",
  "MANUFACTURA JFK-BG, C.A.",
  "LOGICPLUS ALIADOS ESTRATEGICOS, C.A.",
  "DISTRIBUIDORA SUGONEG, C.A ",
  "DISTRIBUIDORA GLOBAL, C.A.",
  "ASCIIDUOS, C.A",
  "DISTRIBUIDORA PUNTERAL, S.A",
  "CRISTALES OFTALMICOS DE OCCIDENTE, C.A",
  "INVERSIONES ZENSILLAS, CA.",
  "YOMA SERVICIOS, C.A",
  "BANESCO BANCO UNIVERSAL, C.A.",
  "INVERSIONES 170161, C.A ",
  "AEROHELICOPTEROS, C.A",
  "INSTITUTO UNIVERSITARIO DE TECNOLOGIA RODOLFO LOERO ",
  "BIOTRICAB, C.A",
  "INGEONAV, C.A",
  "INNOVA VISION, C.A ",
  "ASESORIAS GOMEZ ORTEGA, C.A ",
  "GRUPO 77 IMPORT, C.A ",
  "AYAGAR, C.A ",
  "SUMINISTROS INDUSTRIALES Y MANUFACTURAS TEXTILES, C.A",
  "SAMAN ASESORES 17, C.A ",
  "BANPLUS BANCO UNIVERSAL, C.A",
  "SETECSA DE VENEZUELA, C.A ",
  "GLOBAL WORLD PARTS, C.A",
  "SERVIMEDIC HLM, C.A ",
  "FACE COSMETICS, C.A ",
  "INVERSIONES SUMINISTROS Y SERVICIOS FLUYE, C.A",
  "NOW DE VENEZUELA, C.A.",
  "AUTO PARTES MUNDIAL, C.A.",
  "INDUSTRIAS MAROS, C.A.",
  "REPRESENTACIONES ARTURO, C.A.",
  "INVERSIONES MAXI 2005, C.A.",
  "REPRESENTACIONES IMPORTACIONES RC, C.A.",
  "ASESORIA POSTAL Y DE COMUNICACIONES, C.A.",
  "PROIMCO SOLUCIONES DIGITALES, C.A.",
  "INVERSIONES R.M.R 2007, C.A.",
  "GRUPO YAK, C.A.",
  "SIMAL, C.A ",
  "DISTRIBUIDORA PUNTERAL DEL CENTRO, C.A.",
  "DISTRIBUIDORA PUNTERAL MARA, C.A.",
  "RECTIPARTES PUNTERAL (RECTIPUNCA), C.A",
  "RECTIPARTES PUNTERAL ORIENTE, C.A.",
  "MAQUINARIAS Y HERRAMIENTAS PUNTERAL MAHEPCA, C.A.",
];
const PIECE_CATEGORIES = [
  "ACCESORIOS DEL HOGAR",
  "ACCESORIOS PARA ELECTRONICOS",
  "ALIMENTOS",
  "ALIMENTOS PARA MASCOTAS",
  "ARTICULOS DE ASEO",
  "ARTICULOS DE BEBE",
  "ARTICULOS DE BELLEZA",
  "ARTICULOS DE FIESTA",
  "ARTICULOS DE OFICINA",
  "ARTICULOS DEPORTIVOS",
  "ARTICULOS ESCOLARES",
  "ARTICULOS MEDICOS",
  "ARTICULOS PARA MASCOTAS",
  "CALZADO",
  "CORRESPONDENCIA",
  "ELECTRODOMESTICOS",
  "ELECTRONICOS",
  "EQUIPOS PROFESIONALES",
  "HERRAMIENTAS",
  "HIGIENE Y ASEO PERSONAL",
  "JUGUETES",
  "MAQUILLAJE",
  "MEDICINAS Y SUPLEMENTOS",
  "MISCELANEOS",
  "PARTES ELECTRONICAS",
  "REPUESTOS ELECTRONICOS",
  "REPUESTOS VEHICULOS",
  "ROPA",
  "PREPARED BY CUSTOMER",
];

export const randomAccountPhone = (accountId: string): AccountPhoneInterface => {
  return {
    id: v4(),
    accountId,
    countryId: "236",
    countryPhoneAccessCode: "+58",
    phoneTypeId: "2",
    phoneNumber: `${
      PHONE_OPTIONS[Math.floor(Math.random() * PHONE_OPTIONS.length)]
    }${Math.floor(1000000 + Math.random() * 9000000)}`,
  };
};

export const randomAccountEmail = (accountId: string): AccountEmailInterface => {
  return {
    id: v4(),
    emailTypeId: "1",
    accountId,
    email: EMAILS[Math.floor(Math.random() * EMAILS.length)],
  };
};

export const randomAccount = (): AccountInterface => {
  const accountCode = `${Math.floor(100 + Math.random() * 900)}`;
  return {
    id: v4(),
    accountCode,
    abbreviationName: "V-",
    identificationNumber: `${Math.floor(1000000 + Math.random() * 9000000)}`,
    accountFullName: NAMES[Math.floor(Math.random() * NAMES.length)],
    fiscalAddress: ADDRESS[Math.floor(Math.random() * ADDRESS.length)],
    listAccountPhone: new Array(Math.floor(Math.random() * 3))
      .fill(null)
      .map(() => randomAccountPhone(accountCode)),
    listAccountEmail: new Array(Math.floor(Math.random() * 3))
      .fill(null)
      .map(() => randomAccountEmail(accountCode)),
    listAuthorizingAccount: [],
  };
};

export const randomLocation = (isBussinessUnit: boolean = false): LocationInterface => {
  return {
    id: v4(),
    name: isBussinessUnit
      ? BUSINESS_UNITS[Math.floor(Math.random() * BUSINESS_UNITS.length)]
      : "",
    code: "",
    address: ADDRESS[Math.floor(Math.random() * ADDRESS.length)],
    reference: "",
    postalCode: "",
    coordinates:{
      lat:0,
      lng:0
    }
  };
};

export const randomBusinessUnit = (): BusinessUnitInterface => {
  return {
    buid: Math.floor(100 + Math.random() * 900),
    buCode: `${Math.floor(100 + Math.random() * 900)}`,
    buName: BU_NAMES[Math.floor(Math.random() * BU_NAMES.length)],
    location: randomLocation(true),
    polygonList: [],
  };
};

export const randomDestination = (): DestinationInterface => {
  return {
    id: v4(),
    account: randomAccount(),
    location: randomLocation(),
    businessUnit: randomBusinessUnit(),
    isHomeDelivery: Math.random() > 0.5,
  };
};

export const randomSender = (): SenderInterface => {
  return {
    fullName: NAMES[Math.floor(Math.random() * NAMES.length)],
    abbreviationName: NAMES[Math.floor(Math.random() * NAMES.length)],
    identificationNumber: `${Math.floor(1000000 + Math.random() * 9000000)}`,
  };
};

export const randomPiece = (): PieceInterface => {
  return {
    id: v4(),
    category: {
      id: v4(),
      name: PIECE_CATEGORIES[Math.floor(Math.random() * PIECE_CATEGORIES.length)],
      code:PIECE_CATEGORIES[Math.floor(Math.random() * PIECE_CATEGORIES.length)]
    },
    height: Math.floor(Math.random() * 200),
    width: Math.floor(Math.random() * 200),
    length: Math.floor(Math.random() * 200),
    weight: Math.floor(Math.random() * 50),
    declaredValue: Math.floor(Math.random() * 100000),
  };
};

export const randomPreShipment = (): PreShipmentInterface => {
  const pieces = new Array(Math.floor(Math.random() * 10))
    .fill(null)
    .map(() => randomPiece());

  return {
    id: v4(),
    number: Math.floor(1000 + Math.random() * 9000),
    shipmentNumber: Math.floor(1000 + Math.random() * 9000).toString(),

    observations: "",
    totalPieces: pieces.length,
    totalWeight: pieces.reduce((acc, piece) => acc + piece.weight, 0),
    declaredValue: pieces.reduce((acc, piece) => acc + piece.declaredValue, 0),
    pieces,
    
    service: SERVICES[Math.floor(Math.random() * SERVICES.length)],
    deliveryType:
      Object.values(DeliveryEnum)[
        Math.floor(Math.random() * Object.values(DeliveryEnum).length)
      ],
    shippingMethod:
      Object.values(ShippingMethodEnum)[
        Math.floor(Math.random() * Object.values(ShippingMethodEnum).length)
      ],

    sender: randomSender(),
    shipper: randomAccount(),
    consignee: randomAccount(),
    shippingLocation: randomLocation(),
    businessUnitOrigin: randomBusinessUnit(),
    businessUnitConsignee: randomBusinessUnit(),

    isSED: Math.random() > 0.5,
    isToHold: Math.random() > 0.5,
    isFragile: Math.random() > 0.5,
    isRepacke: Math.random() > 0.5,
    isPreAlerted: Math.random() > 0.5,
    isSafeKeeping: Math.random() > 0.5,

    // random date
    status:
      Object.values(ShipmentStatusEnum)[
        Math.floor(Math.random() * Object.values(ShipmentStatusEnum).length)
      ],
    date: new Date(
      Date.now() - Math.floor(Math.random() * 1000 * 60 * 60 * 24 * 30 * 24) // 24 months
    ).toISOString(),
  };
};

export const randomShipment = (): ShipmentInterface => {
  return {
    ...randomPreShipment(),

    tracking:
      Object.values(TrackingEnum)[
        Math.floor(Math.random() * Object.values(TrackingEnum).length)
      ],
    trackingDetails: "",
    paymentMode:
      Object.values(PaymentModeEnum)[
        Math.floor(Math.random() * Object.values(PaymentModeEnum).length)
      ],

    creationUser: `usuario${Math.floor(Math.random() * 10)}`,
    isHighValueCargo: false,
    requestProcessedDate: Math.random() > 0.5 ? new Date().toISOString() : undefined,
  };
};
