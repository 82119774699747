import { FC, useState, useEffect } from 'react';
import { PrimaryButton } from '../Atoms/Buttons';
import { ChevronLeftIcon,TruckIcon } from '@heroicons/react/20/solid';
import { FormSelect } from '../FormFields/FormSelect';
import { useAppSelector } from '../../store/hooks';
import { getRoute } from '../../services/RouteServices';
import { FormText } from '../FormFields/FormText';
import { AccountInterface, DriversInterface, RouteInterface, TransportInterface } from '../../interfaces';
import { getDrivers } from '../../services/DriversServices';
import { getTransport } from '../../services/TransportServices';
import { ModalLoading } from '../Atoms/ModalLoading';
import { ConfirmModalStatus } from '../Preshipment/ModalSuccess';

interface DispatchTableProps {
    saveDispath:boolean,    
    setSelectNext: React.Dispatch<React.SetStateAction<boolean>>
    setSave: React.Dispatch<React.SetStateAction<boolean>>
    setSale1Dispath:  React.Dispatch<React.SetStateAction<string>>
    setSale2Dispath:  React.Dispatch<React.SetStateAction<string>>
    setSale3Dispath:  React.Dispatch<React.SetStateAction<string>>
    setRoute: React.Dispatch<React.SetStateAction<string>>
    setTransport: React.Dispatch<React.SetStateAction<string>>
    setDriver: React.Dispatch<React.SetStateAction<string>>
}

  export const CreateDataDispatch: FC<DispatchTableProps> = ({
    saveDispath,
    setSelectNext,
    setSave,
    setRoute,
    setTransport,
    setSale1Dispath,
    setSale2Dispath,
    setSale3Dispath,
    setDriver
  }) => {
    const user = useAppSelector((state) => state.user);

    const [listRouteDispath, setListRouteDispath] =  useState<RouteInterface[]>([]);
    const [listDriversDispath, setListDriversDispath] =  useState<DriversInterface[]>([]);
    const [listTransportDispath, setListTransportDispath] =  useState<TransportInterface[]>([]);
    
    const [saleDispath1, setSaleDispath1] = useState("");
    const [saleDispath2, setSaleDispath2] = useState("");
    const [saleDispath3, setSaleDispath3] = useState("");
    const [routeDispath, setRouteDispath] =  useState<RouteInterface>();
    const [driversDispath, setdriversDispath] =  useState<DriversInterface>();
    const [transportDispath, setTransportDispath] =  useState<TransportInterface>();
    const [saveDispathInput, setSaveDispathInput] =  useState(false);

    const [errorRoute, setErrorRoute] = useState("");
    const [errorTransport, setErrorTransport] = useState("");    
    const [errorDriver, setErrorDriver] = useState("");

    const [errorsaleDispath2, setErrorsaleDispath2] = useState("");
    const [errorsaleDispath3, setErrorsaleDispath3] = useState("");

    let [loading, setLoading] = useState(false);
    let [loadingStatus, setLoadingStatus] = useState(ConfirmModalStatus.PROCESSING);
    let [loadingTitle, setLoadingTitle] = useState("Cargando...");

    useEffect(() => {
        const buCodeSource = ''+user?.client?.BuCodeSource;
        if (!buCodeSource) return;

        const fetchRoute = async () => {

          setLoadingStatus(ConfirmModalStatus.PROCESSING);
          setLoadingTitle("Cargando...");
          setLoading(true);

          const shipments = await getRoute(buCodeSource); 
          var listDispatch = shipments?.model as RouteInterface[];
           if(!shipments?.didError)
           {setListRouteDispath(listDispatch);setLoading(false);}else{
            setLoadingStatus(ConfirmModalStatus.ERROR);
            setLoadingTitle("Error: "+shipments?.errorMessage);
            setLoading(true);
          }
        };
        const fetchDrivers = async () => {

            setLoadingStatus(ConfirmModalStatus.PROCESSING);
            setLoadingTitle("Cargando...");
            setLoading(true);

            const shipments = await getDrivers(); 
            var listDispatch = shipments?.model as DriversInterface[];
             if(!shipments?.didError)
             {setListDriversDispath(listDispatch);setLoading(false);}else{
                setLoadingStatus(ConfirmModalStatus.ERROR);
                setLoadingTitle("Error: "+shipments?.errorMessage);
                setLoading(true);
              }
          };        
        const fetchTransport = async () => {
            setLoadingStatus(ConfirmModalStatus.PROCESSING);
            setLoadingTitle("Cargando...");
            setLoading(true);

            const shipments = await getTransport(buCodeSource); 
            var listDispatch = shipments as TransportInterface[];
             if(listDispatch?.length >0)
             {setListTransportDispath(listDispatch);setLoading(false);}
        };
        fetchTransport();
        fetchDrivers();
        fetchRoute();
      }, [user?.client?.id]);

      useEffect(() =>{
        let error = 0;
        setSave(false);        

            if(routeDispath == undefined){
                setErrorRoute("La ruta es obligatorio")
                error++;
              }
              else
                setErrorRoute("")
      
              if(driversDispath == undefined){
                setErrorDriver("El conductor es obligatorio")
                error++;
              }
              else
                setErrorTransport("")
      
              if(transportDispath == undefined){
                setErrorTransport("El transportista es obligatorio")
                error++;
              }
              else
                setErrorDriver("")   
              
              if(saleDispath2 == saleDispath1 && saleDispath2.length >0){
                setErrorsaleDispath2("El precinto debe ser unico")
                error++;
              }else
                setErrorsaleDispath2("");

              if((saleDispath3 == saleDispath1 && saleDispath3.length >0) || saleDispath2 == saleDispath3 && saleDispath3.length >0){
                setErrorsaleDispath3("El precinto debe ser unico")
                error++;
              }
              else
                setErrorsaleDispath3("");

        
        if(error==0)
            setSaveDispathInput(true); 

      },);
    
    return (
        
        <div>
            <div  className="flex flex-1 justify-between gap-2 md:gap-6 flex-col-reverse md:flex-row">
                <div  className="flex flex-1" style={{ maxWidth: "45rem" }}>                            
                                <PrimaryButton type="button" className="px-4"
                                onClick={() =>{  
                                    setSelectNext(false);
                                    setSave(false);
                                }}>                  
                                    <ChevronLeftIcon className="h-5 w-5 ml-0 mr-2" aria-hidden="true" /> Regresar 
                                </PrimaryButton>                               
                </div>
                <div className="flex flex-1 flex-col gap-12 items-end">
                       <PrimaryButton type="button"  className="px-4"
                                    onClick={() =>{  
                                        setSave(true);
                                    }}
                                    disabled={!saveDispathInput}
                                                            >                  
                                    Guardar <TruckIcon className="h-5 w-5 ml-2 mr-0" aria-hidden="true" /> 
                        </PrimaryButton>                              
                </div>                
            </div>
            <div>
                <div  className="flex flex-1 flex-col pt-6 gap-12  h-full">
                <hr className="-mt-2" />
                </div>
                <div className="flex flex-1 flex-col border rounded mt-8 px-4 pt-2 pb-6 gap-4 shadow bg-white">
                        <div className="flex flex-1 font-semibold text-sm text-gray-700">
                        Detalle despacho
                        </div>

                        <hr className="-mt-2" />
                        <div className='flex w-full flex-col sm:flex-row gap-4'>
                            <div  className="w-full sm:w-90">                                
                                <FormSelect
                                    id="route"
                                    name="route"
                                    label="Rutas"
                                    selected={routeDispath}
                                    options={listRouteDispath}           
                                    error={errorRoute}                         
                                    className="!h-7"
                                    labelClassname="!text-xs"
                                    labelContainerClassname="flex sm:!justify-end sm:w-20"
                                    containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                                    style={{ maxWidth: "40rem" }}                                   
                                    optionString={(item) => `${item.routeName}`}
                                    onSelectOption={(option) => {setRoute(option.routeID); setRouteDispath(option)}}
                                />      
                            </div> 
                            <div className="w-full sm:w-90">
                                <FormText
                                    id="seal1"
                                    name="seal1"
                                    label="Precinto"
                                    autoComplete="off"     
                                    className="!h-7"
                                    labelClassname="!text-xs"
                                    labelContainerClassname="flex sm:!justify-end sm:w-15"
                                    containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                                    style={{ maxWidth: "40rem" }}  
                                    value={saleDispath1}
                                    onChange={(e) => {
                                        setSaleDispath1(e.target.value??"");
                                        setSale1Dispath(e.target.value??"")
                                    } }
                                />
                            </div>           
                        </div>
                        <div className='flex w-full flex-col sm:flex-row gap-4'>                           
                            <div  className="w-full sm:w-90">                                
                                    <FormSelect
                                        id="drivers"
                                        name="drivers"
                                        label="Choferes"
                                        selected={driversDispath}
                                        options={listDriversDispath}     
                                        error={errorDriver}                               
                                        className="!h-7"
                                        labelClassname="!text-xs"
                                        labelContainerClassname="flex sm:!justify-end sm:w-20"
                                        containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                                        style={{ maxWidth: "40rem" }}
                                        optionString={(option) =>`${option.accountFullName}`}
                                        onSelectOption={(option) => {                                            
                                            setDriver(option.accountID?.toString()??"");
                                            setdriversDispath(option)}}
                                    />      
                                </div>  
                            <div className="w-full sm:w-90">
                                <FormText
                                    id="seal2"
                                    name="seal2"
                                    label="Precinto"
                                    autoComplete="off"
                                    error={errorsaleDispath2}
                                    value={saleDispath2}        
                                    className="!h-7"
                                    labelClassname="!text-xs"
                                    labelContainerClassname="flex sm:!justify-end sm:w-15"
                                    containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                                    style={{ maxWidth: "40rem" }}
                                    onChange={(e) =>{
                                        setSaleDispath2(e.target.value??"");
                                        setSale2Dispath(e.target.value??"")
                                    }}
                                />
                            </div>             
                        </div>
                        <div className='flex w-full flex-col sm:flex-row gap-4'>                           
                            <div  className="w-full sm:w-90">                                
                                    <FormSelect
                                        id="transport"
                                        name="transport"
                                        label="Transportistas"
                                        selected={transportDispath}
                                        options={listTransportDispath}
                                        error={errorTransport}                                    
                                        className="!h-7"
                                        labelClassname="!text-xs"
                                        labelContainerClassname="flex sm:!justify-end sm:w-20"
                                        containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                                        style={{ maxWidth: "40rem" }}                                        
                                        optionString={(option) =>`${option.transportPlate}`}
                                        onSelectOption={(option) => {
                                            setTransport(option.transportID?.toString()??"");
                                            setTransportDispath(option)}}
                                    />      
                                </div>  
                            <div className="w-full sm:w-90">
                                <FormText
                                    id="seal3"
                                    name="seal3"
                                    label="Precinto"
                                    autoComplete="off"
                                    value={saleDispath3}    
                                    error={errorsaleDispath3}    
                                    className="!h-7"
                                    labelClassname="!text-xs"
                                    labelContainerClassname="flex sm:!justify-end sm:w-15"
                                    containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                                    style={{ maxWidth: "40rem" }}
                                    onChange={(e) =>{
                                        setSaleDispath3(e.target.value??"");
                                        setSale3Dispath(e.target.value??"")
                                    }}
                                />
                            </div>             
                        </div>
                </div>                     
            </div>
            <ModalLoading open={loading} title={loadingTitle} status={loadingStatus} setOpen={setLoading} onPrint={() => {  }}/>                       
        </div>
        
     );
  };