export enum PaymentModeEnum {
  COD = "COD",
  CONTADO = "Contado",
  CREDIT = "Crédito",
}

export enum PaymentModeId {
  COD = "2",
  CONTADO = "1",
  CREDIT = "3",
}
