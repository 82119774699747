import { PieceForm, PieceFormValues } from './PieceForm';
import { PieceTable } from './PieceTable';
import { PrimaryButton } from '../Atoms/Buttons';
import { FormText } from '../FormFields/FormText';
import { FormCheckbox } from '../FormFields/FormCheckbox';
import { FormTextArea } from '../FormFields/FormTextArea';
import { FormRadioGroup } from '../FormFields/FormRadioGroup';
import { FormikErrors, FormikProps, FormikTouched } from 'formik';
import {
  DeliveryEnum,
  ItemInterface,
  LocationInterface,
  PaymentModeId,
  PieceInterface,
  ShippingMethodEnum,
  discountItem,
} from '../../interfaces';
import { useEffect, useRef, useState } from 'react';
import {
  formantPackageType,
  formantPaymentModeId,
  formantShippingMethod,
  formantWeightTypeEnum,
  handleNumberChange,
} from '../../utils/format';
import { ServiceEnum } from '../../interfaces/Services/ServicesEnum';
import { PackageTypeEnum } from '../../interfaces/Shipment/PackageTypeEnum';
import { WeightTypeEnum } from '../../interfaces/Shipment/WeightTypeEnum';
import { useAppSelector } from '../../store/hooks';
import { v4 } from 'uuid';
import { ConsigneeFormValues } from './ConsigneeForm';
import { getShipmentItems, getShipmentRates } from '../../services';
import ShipmentItemTable from '../ShipmentV2/ShipmentItemTable';

export interface PreShipmentFormValues {
  service: ServiceEnum;
  paymentModeID: PaymentModeId;
  shippingMethod: ShippingMethodEnum;
  isSafeKeeping: boolean;
  packageType: string;
  weightUnit: string;
  pieces: PieceInterface[];
  observations: string;
  valueDeclarate: string;
  items?: ItemInterface[];
  deliveryDistance?: number;
  total?: number;
  totalChargedWeight?: number;
  totalPhysicalWeight?: number;
  totalDimensionalWeight?: number;
  totalPostalTaxBaseCurr?: number;
}
interface PreShipmentField {
  newPiece: PieceFormValues;
  preShipment: PreShipmentFormValues;
  consignee: ConsigneeFormValues;
}
interface PreShipmentFormProps<T> {
  formik: FormikProps<T & PreShipmentField>;
}

export const PreShipmentForm = <T extends any>({
  formik,
}: PreShipmentFormProps<T>) => {
  const errors = formik.errors.preShipment as
    | FormikErrors<PreShipmentFormValues>
    | undefined;
  const touched = formik.touched.preShipment as
    | FormikTouched<PreShipmentFormValues>
    | undefined;

  const [selectValueDeclared, setSelectValueDeclared] =
    useState<boolean>(false);
  const onSelectSafeKeeping = () => {
    setSelectValueDeclared(!selectValueDeclared);
    formik.setFieldValue('preShipment.isSafeKeeping', !selectValueDeclared);
  };
  const [baseShipmentItems, setBaseShipmentItems] = useState<ItemInterface[]>(
    []
  );

  const [shipmentItems, setShipmentItems] = useState<ItemInterface[]>([]);
  const rateKey = useRef<string>('');
  const itemsKey = useRef<string>('');

  const user = useAppSelector((state) => state.user)!;

  useEffect(() => {
    if (
      formik.values.preShipment.paymentModeID?.toString() ===
      PaymentModeId.COD.toString()
    ) {
      setSelectValueDeclared(true);
      formik.setFieldValue('preShipment.isSafeKeeping', true);
    } else onSelectSafeKeeping();
  }, [formik.values.preShipment.paymentModeID]);

  useEffect(() => {
    let piecesList = formik.values.preShipment.pieces;
    let countPieces = piecesList.length;
    piecesList.map((pieces) => {
      let piecesData = formik.values.preShipment.valueDeclarate;
      piecesData = piecesData === undefined ? '0' : piecesData;
      pieces.declaredValue = parseFloat(piecesData ?? '0') / countPieces;
    });
    formik.setFieldValue('preShipment.pieces', piecesList);
  }, [formik.values.preShipment.valueDeclarate]);

  useEffect(() => {
    const { pieces } = formik.values.preShipment;
    if (
      !pieces ||
      // !shipper ||
      // !buSource ||
      // !consigneeAddress ||
      pieces.length === 0 ||
      baseShipmentItems.length === 0
    ) {
      setShipmentItems([]);
      formik.setFieldValue('preShipment.items', []);
      formik.setFieldValue('preShipment.totalChargedWeight', 0);
      formik.setFieldValue('preShipment.totalPhysicalWeight', 0);
      formik.setFieldValue('preShipment.totalDimensionalWeight', 0);
      return;
    }

    if (
      formik.values.preShipment.paymentModeID?.toString() !==
      PaymentModeId.COD.toString()
    )
      return;
    const getAllShipmentItems = async () => {
      const key = v4();
      rateKey.current = key;
      let countItem = pieces.reduce((acc, p) => acc + p.declaredValue, 0);

      if (
        user!.client?.businessUnit?.location === undefined ||
        formik.values.consignee.businessUnit?.location === undefined
      )
        return;

      let response = await getShipmentRates(
        ServiceEnum.STANDARD,
        formik.values.preShipment.paymentModeID,
        formik.values.consignee.ShipmentType ?? DeliveryEnum.OFFICE,
        formik.values.consignee.businessUnit?.buCode ?? '',
        user!.client?.businessUnit?.location ?? ({} as LocationInterface),
        formik.values.consignee.businessUnit?.location ??
          ({} as LocationInterface),
        baseShipmentItems,
        pieces,
        countItem > 0 ? true : false,
        user!.client.id ?? undefined
      );

      if (!response || key !== rateKey.current) {
        return;
      }
      if (response.didError || !response.model) {
        console.log(response.errorMessage);
        return;
      }

      setShipmentItems(response.model.items);

      formik.setFieldValue(
        'preShipment.deliveryDistance',
        response.model.distance
      );
      formik.setFieldValue(
        'preShipment.items',
        response.model.items
          .filter(
            (item) =>
              item.mandatory ||
              formik.values.preShipment.items?.some((s) => s.id === item.id)
          )
          .sort((a, b) => a.order - b.order)
      );
      formik.setFieldValue(
        'preShipment.totalChargedWeight',
        response.model.chargedWeight
      );
      formik.setFieldValue(
        'preShipment.totalPhysicalWeight',
        response.model.physicalWeight
      );
      formik.setFieldValue(
        'preShipment.totalDimensionalWeight',
        response.model.dimensionalWeight
      );
    };
    getAllShipmentItems();
  }, [
    formik.values.preShipment.service,
    formik.values.preShipment.paymentModeID,
    formik.values.preShipment.valueDeclarate,
    formik.values.consignee.ShipmentType,
    formik.values.preShipment.pieces,
    formik.values.preShipment.valueDeclarate,
    formik.values.consignee,
  ]);

  // Get shipment items
  useEffect(() => {
    const getAllShipmentItems = async () => {
      const key = v4();
      itemsKey.current = key;
      const response = await getShipmentItems(
        ServiceEnum.STANDARD,
        formik.values.preShipment.paymentModeID,
        formik.values.consignee.ShipmentType ?? DeliveryEnum.OFFICE,
        formik.values.preShipment.pieces?.reduce(
          (acc, p) => acc + p.declaredValue,
          0
        ) > 0
      );

      if (!response || key !== itemsKey.current) return;
      if (response.didError || !response.model) {
        return;
      }
      itemsKey.current = '';

      // Filter ipostel and discount
      setBaseShipmentItems(
        response.model.filter(
          (item) =>
            !item.name.toLowerCase().includes('ipostel') &&
            item.id !== discountItem.id
        )
      );
    };

    if (
      formik.values.preShipment.paymentModeID?.toString() ===
      PaymentModeId.COD?.toString()
    )
      getAllShipmentItems();
  }, [
    formik.values.preShipment.service,
    formik.values.preShipment.paymentModeID,
    formik.values.consignee.ShipmentType,
    formik.values.preShipment.valueDeclarate,
    formik.values.consignee,
    formik.values.preShipment.pieces,
  ]);

  useEffect(() => {
    formik.setFieldValue('preShipment.valueDeclarate', 0);
  }, [formik.values.preShipment.paymentModeID]);

  return (
    <div className="flex flex-1 flex-col gap-16">
      <div className="flex flex-1 flex-col lg:flex-row gap-6 xl:gap-8 lg:gap-16">
        <div className="flex flex-1 h-full flex-col gap-4 px-0 sm:px-8 lg:px-0">
          {user.paymentMethod !== undefined &&
            user.paymentMethod?.length > 1 && (
              <FormRadioGroup
                id="preShipment.paymentModeID"
                name="preShipment.paymentModeID"
                label="Metodo de pago"
                selected={formik.values.preShipment.paymentModeID}
                labelClassname="!text-xs flex sm:!justify-end sm:w-32"
                className="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                wrapperClassName="flex !flex-row"
                optionsClassName="text-xs !ml-1.5"
                options={Object.values(user.paymentMethod).map((method) => ({
                  name: formantPaymentModeId(method.paymentModeID),
                  value: method.paymentModeID,
                }))}
                onSelectOption={(opt) =>
                  formik.setFieldValue('preShipment.paymentModeID', opt)
                }
              />
            )}

          {user.paymentMethod !== undefined &&
            user.paymentMethod?.length === 1 && (
              <FormText
                disabled
                id="preShipment.paymentModeID"
                name="preShipment.paymentModeID"
                label="Metodo de pago"
                value={formantPaymentModeId(
                  formik.values.preShipment.paymentModeID
                )}
                error={
                  touched?.paymentModeID && errors?.paymentModeID
                    ? errors?.paymentModeID
                    : undefined
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="!h-8"
                labelClassname="!text-xs"
                labelContainerClassname="flex sm:!justify-end sm:w-32"
                containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                style={{ maxWidth: '10rem' }}
              />
            )}

          {user.paymentMethod === undefined && (
            <FormText
              disabled
              id="preShipment.paymentModeID"
              name="preShipment.paymentModeID"
              label="Metodo de pago"
              value={formantPaymentModeId(
                formik.values.preShipment.paymentModeID
              )}
              error={
                touched?.paymentModeID && errors?.paymentModeID
                  ? errors?.paymentModeID
                  : undefined
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="!h-8"
              labelClassname="!text-xs"
              labelContainerClassname="flex sm:!justify-end sm:w-32"
              containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
              style={{ maxWidth: '10rem' }}
            />
          )}

          {formik.values.preShipment.paymentModeID.toString() !==
            PaymentModeId.COD.toString() && (
            <FormRadioGroup
              disabled
              id="preShipment.shippingMethod"
              name="preShipment.shippingMethod"
              label="Método de envío"
              selected={formik.values.preShipment.shippingMethod}
              labelClassname="!text-xs flex sm:!justify-end sm:w-32"
              className="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
              wrapperClassName="flex !flex-row"
              optionsClassName="text-xs !ml-1.5"
              options={Object.values(ShippingMethodEnum).map((method) => ({
                name: formantShippingMethod(method),
                value: method,
              }))}
              onSelectOption={(opt) =>
                formik.setFieldValue('preShipment.shippingMethod', opt)
              }
            />
          )}

          <FormCheckbox
            id="preShipment.isSafeKeeping"
            name="preShipment.isSafeKeeping"
            label="¿Viaja asegurado?"
            onChange={() => {
              onSelectSafeKeeping();
            }}
            onBlur={formik.handleBlur}
            checked={formik.values.preShipment.isSafeKeeping}
            labelClassname="!text-xs mb-1"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="justify-end flex flex-1 gap-8 items-center !flex-row-reverse"
            disabled={
              formik.values.preShipment.paymentModeID.toString() ===
              PaymentModeId.COD.toString()
            }
          />

          {selectValueDeclared === true ? (
            <FormText
              id="preShipment.valueDeclarate"
              name="preShipment.valueDeclarate"
              label="Valor declarado en dólares"
              autoComplete="off"
              value={formik.values.preShipment.valueDeclarate}
              error={
                touched?.valueDeclarate && errors?.valueDeclarate
                  ? errors?.valueDeclarate
                  : undefined
              }
              onChange={(e) => handleNumberChange(e, formik, true)}
              onBlur={formik.handleBlur}
              className="!h-8"
              labelClassname="!text-xs"
              labelContainerClassname="flex sm:!justify-end sm:w-32"
              containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            />
          ) : (
            <div /* Este es el div 2 */ className="red2" />
          )}

          {formik.values.preShipment.paymentModeID.toString() !==
            PaymentModeId.COD.toString() && (
            <>
              <FormRadioGroup
                id="preShipment.packageType"
                name="preShipment.packageType"
                label="Tipo de paquete"
                selected={formik.values.preShipment.packageType}
                labelClassname="!text-xs flex sm:!justify-end sm:w-32"
                className="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                wrapperClassName="flex !flex-row"
                optionsClassName="text-xs !ml-1.5"
                options={Object.values(PackageTypeEnum).map((method) => ({
                  name: formantPackageType(method),
                  value: method,
                }))}
                onSelectOption={(opt) =>
                  formik.setFieldValue('preShipment.packageType', opt)
                }
              />

              <FormRadioGroup
                disabled
                id="preShipment.weightUnit"
                name="preShipment.weightUnit"
                label="Tipo de Peso"
                selected={formik.values.preShipment.weightUnit}
                labelClassname="!text-xs flex sm:!justify-end sm:w-32"
                className="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                wrapperClassName="flex !flex-row"
                optionsClassName="text-xs !ml-1.5"
                options={Object.values(WeightTypeEnum).map((method) => ({
                  name: formantWeightTypeEnum(method),
                  value: method,
                }))}
                onSelectOption={(opt) =>
                  formik.setFieldValue('preShipment.weightUnit', opt)
                }
              />
            </>
          )}

          <FormTextArea
            id="preShipment.observations"
            name="preShipment.observations"
            label="Observaciones"
            value={formik.values.preShipment.observations}
            error={
              touched?.observations && errors?.observations
                ? errors?.observations
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32 sm:mt-2"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 flex-col sm:flex-row"
            style={{ maxWidth: '30rem' }}
          />
        </div>

        <div className="flex flex-1 flex-col lg:px-0 xl:px-8">
          <span className="text-sm font-bold text-gray-700 mb-4">Piezas</span>

          <PieceForm formik={formik} />
        </div>
      </div>

      <div className="flex flex-1 overflow-auto">
        <PieceTable
          error={touched?.pieces && !!errors?.pieces}
          pieces={formik.values.preShipment.pieces}
          onDelete={(index) => {
            const newPieces = [...formik.values.preShipment.pieces];
            newPieces.splice(index, 1);
            formik.setFieldValue('preShipment.pieces', newPieces);
          }}
        />
      </div>

      {formik.values.preShipment.paymentModeID.toString() ===
        PaymentModeId.COD.toString() && (
        <div className="flex flex-1 overflow-auto">
          <ShipmentItemTable
            editable
            total={formik.values.preShipment.total}
            items={formik.values.preShipment.items ?? []}
            distance={formik.values.preShipment.deliveryDistance}
            declaredValue={
              +(parseFloat(formik.values.preShipment.valueDeclarate) ?? 0)
            }
            availableItems={shipmentItems.filter(
              (item) =>
                !item.mandatory &&
                !(formik.values.preShipment.items ?? []).some(
                  (i) => i.id === item.id
                )
            )}
          />
        </div>
      )}

      <div className="flex flex-1 justify-center">
        <PrimaryButton className="px-10 w-full sm:w-auto" type="submit">
          Guardar
        </PrimaryButton>
      </div>
    </div>
  );
};
