import {
  DestinationInterface,
  DispatchGuideDto,
  DispatchInterface,
  DispatchResponseInterface,
  DispatcherCCWInterface,
} from '../interfaces';
import axios from 'axios';
import { AuthenticationServices } from './AuthenticationServices';
import {
  ListResponse,
  SimpleResponse,
} from '../interfaces/Shipment/ListResponseDto';
const { REACT_APP_URL_PATH_API } = process.env;

export const getAllDispatchers = async (
  accountCode?: string,
  accountId?: string
): Promise<ListResponse<DispatcherCCWInterface[]> | undefined> => {
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  var response = await axios
    .post(`${REACT_APP_URL_PATH_API}/v1/Dispatchers/GetAllCCW`, {
      CodCustomer: accountCode,
      AccountId: accountId,
    })
    .then(function (response) {
      var responsedto = response.data;
      return responsedto as ListResponse<DispatcherCCWInterface[]>;
    })
    .catch(function (error) {
      //dirError= true;
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //let  errorData = error.response.data;
        //errorMessage = errorData.message ?? "";
        console.log(error.message ?? '');
        return error.response as ListResponse<DispatcherCCWInterface[]>;
      } else {
        // Something happened in setting up the request that triggered an Error
        //errorMessage = error.message ?? "";
        console.log(error.message ?? '');
      }
      return [] as unknown as ListResponse<DispatcherCCWInterface[]>;
    });

  return response;
};

export const getGuideDispatchs = async (
  accountCode: string,
  accountId: string
): Promise<ListResponse<DispatchGuideDto[]> | undefined> => {
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  var response = await axios
    .post(`${REACT_APP_URL_PATH_API}/v1/Dispatchers/GetAllMissingCCW`, {
      CodCustomer: accountCode,
      AccountID: accountId,
    })
    .then(function (response) {
      var responsedto = response.data;
      return responsedto as ListResponse<DispatchGuideDto[]>;
    })
    .catch(function (error) {
      //dirError= true;
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //let  errorData = error.response.data;
        //errorMessage = errorData.message ?? "";
        console.log(error.message ?? '');
        return error.response as ListResponse<DispatchGuideDto[]>;
      } else {
        // Something happened in setting up the request that triggered an Error
        //errorMessage = error.message ?? "";
        console.log(error.message ?? '');
      }
      return [] as unknown as ListResponse<DispatchGuideDto[]>;
    });

  return response;
};

export const saveDispatchs = async (
  dispatch: DispatchInterface
): Promise<SimpleResponse<DispatchResponseInterface> | undefined> => {
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  var response = await axios
    .post(`${REACT_APP_URL_PATH_API}/Manifiest/CreateManifest`, dispatch)
    .then(function (response) {
      var responsedto =
        response.data as SimpleResponse<DispatchResponseInterface>;
      return responsedto;
    })
    .catch(function (error) {
      //dirError= true;
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //let  errorData = error.response.data;
        return error.response as SimpleResponse<DispatchResponseInterface>;
      } else {
        // Something happened in setting up the request that triggered an Error
        //errorMessage = error.message ?? "";
        console.log(error.message ?? '');
      }
      return {} as unknown as SimpleResponse<DispatchResponseInterface>;
    });

  return response;
};

export const getDestinationByCode = async (
  code: string
): Promise<DestinationInterface | undefined> => {
  console.log(`[API CALL] GET Account Contact by code: ${code}`);
  return undefined;
};

export const DowloadDispatch = async (
  dispatchNumber: string,
  type: string,
  userPrint: string
): Promise<any> => {
  const token = await AuthenticationServices();
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(
    `${REACT_APP_URL_PATH_API}/manifiest/DownloadManifiest?ManifestCode=${dispatchNumber}&type=${type}&UserPrint=${userPrint}`,
    requestOptions
  );
};
