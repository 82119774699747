import { FC } from 'react';
import { Modal } from '../Atoms/Modal';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { PreShipmentInterface } from '../../interfaces';
import { PrimaryButton } from '../Atoms/Buttons';

interface PresipmentMessageModalProps {
  open: boolean;
  status: ConfirmModalStatus;
  shipment: PreShipmentInterface;
  onPrint: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum ConfirmModalStatus {
  CONFIRM,
  PROCESSING,
  SUCCESS,
  ERROR,
  CANCEL,
  PRINT,
  WARNING,
}

export const PresipmentMessageModal: FC<PresipmentMessageModalProps> = ({
  open,
  status,
  shipment,
  onPrint,
  setOpen,
}) => {
  return (
    <Modal
      open={open}
      setOpen={() => {}}
      className="flex flex-1 mx-4 my-8 "
      style={{ maxWidth: '25rem', height: '17rem' }}
    >
      <div className="flex flex-1 flex-col ">
        {/* Shipment Data */}
        {status == ConfirmModalStatus.ERROR && (
          <div className="flex flex-col items-center justify-center pb-10">
            <div className="flex flex-col items-center justify-center w-full">
              <XCircleIcon className="text-red-600 w-32 h-32" />
            </div>
            <p className="mt-2 text-2xl font-medium text-gray-900">
              Error al procesar la preguía
            </p>
            <p className="mt-2 text-gray-500 text-center">
              Intente de nuevo, si el problema persiste. Comunicarse con soporte
              tecnico
            </p>
            <div className="mt-4 flex flex-row justify-center gap-4">
              <PrimaryButton className="px-4" onClick={() => setOpen(false)}>
                Cerrar
              </PrimaryButton>
            </div>
          </div>
        )}

        {status == ConfirmModalStatus.SUCCESS && (
          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-col items-center justify-center w-full">
              <CheckCircleIcon className="text-green-600 w-28 h-28" />
            </div>
            <p className="mt-2 text-2xl font-medium text-gray-900 text-center">
              Preguía Nº {shipment.shipmentNumber}. <br />
              Generada con exitosamente
            </p>
            <div className="mt-4 flex flex-row justify-center gap-4">
              <PrimaryButton
                className="px-4"
                onClick={() => {
                  onPrint();
                }}
              >
                Imprimir
              </PrimaryButton>
              <PrimaryButton
                className="px-4"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cerrar
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
