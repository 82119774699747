import { FC, useState } from "react";
import { Modal } from "./Modal";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { PrimaryButton } from "./Buttons";

interface ModalLoadingProps {
  open: boolean;
  title: string;
  setAccepts: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  classNames?: string;
}

export const ModalQuestion: FC<ModalLoadingProps> = ({
  open,
  title,
  setOpen,
  setAccepts,
  classNames,
}) => {
  let configData = {
    maxWidth: "20rem",
    height: "15rem",
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className="flex flex-1 mx-2 my-1 pt-12 pb-4"
      style={configData}
    >
      <div className="flex flex-1 flex-col items-center justify-center content-center">
        <div className="flex flex-col items-center justify-center w-full">
          <ExclamationTriangleIcon className="text-yellow-400 w-20 h-20" />
        </div>
        <p className="mt-2 text-center text-lg font-bold ">{title}</p>
        <div className="mt-6 flex flex-row justify-center gap-4">
          <PrimaryButton
            className="px-4"
            onClick={() => {
              setOpen(false);
              setAccepts(true);
            }}
          >
            Aceptar
          </PrimaryButton>
          <PrimaryButton
            className="px-4"
            onClick={() => {
              setOpen(false);
              setAccepts(false);
            }}
          >
            Cerrar
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};
