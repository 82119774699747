import { FC, useState } from "react";
import classNames from "classnames";
import { Popover } from "../Atoms/Popover";
import { useNavigate } from "react-router-dom";
import { DestinationInterface } from "../../interfaces";
import { DestinationDetailsModal } from "./DestinationDetailsModal";
import { ChevronDownIcon, EllipsisVerticalIcon } from "@heroicons/react/24/outline";

export enum DestinationTableField {
  CODE,
  ALIAS,
  CITY,
  PHONE,
  STORE,
  CONTACT,
}

const TableOptionsButton = (
  <div className="flex w-10 h-10 items-center justify-center">
    <EllipsisVerticalIcon className="w-5 h-5" />
  </div>
);

interface DestinationsTableProps {
  destinations: DestinationInterface[];
  sortBy: { field: DestinationTableField; asc: boolean };
  onChangeSortBy: (field: DestinationTableField) => void;
}
export const DestinationsTable: FC<DestinationsTableProps> = ({
  destinations,
  sortBy,
  onChangeSortBy,
}) => {
  const navigate = useNavigate();

  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selected, setSelected] = useState<DestinationInterface>();

  const handleEdit = (destination: DestinationInterface) => {
    setOpenDetailsModal(false);
    navigate("/destinations/edit", { state: { destination } });
  };

  return (
    <div
      style={{ maxHeight: "20rem" }}
      className="relative w-full overflow-y-auto table-fix-head mt-4 border border-gray-300"
    >
      <table className="table-auto w-full text-gray-600 text-sm text-left">
        <thead className="select-none font-semibold border-b border-gray-300 bg-gray-50">
          <tr>
            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(DestinationTableField.CODE)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Código Destinatario</span>
                <div className="h-4 w-4">
                  {sortBy.field == DestinationTableField.CODE && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(DestinationTableField.ALIAS)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Alias</span>
                <div className="h-4 w-4">
                  {sortBy.field == DestinationTableField.ALIAS && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(DestinationTableField.CITY)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Ciudad</span>
                <div className="h-4 w-4">
                  {sortBy.field == DestinationTableField.CITY && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(DestinationTableField.PHONE)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Teléfono</span>
                <div className="h-4 w-4">
                  {sortBy.field == DestinationTableField.PHONE && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(DestinationTableField.STORE)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Tienda</span>
                <div className="h-4 w-4">
                  {sortBy.field == DestinationTableField.STORE && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(DestinationTableField.CONTACT)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Persona de contacto</span>
                <div className="h-4 w-4">
                  {sortBy.field == DestinationTableField.CONTACT && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th className="px-4 py-2" />
          </tr>
        </thead>
        <tbody>
          {destinations.map((destination, index) => (
            <tr
              key={destination.id}
              className={classNames(
                "hover:bg-gray-200",
                index % 2 == 0 && "bg-gray-100"
              )}
            >
              <td className="px-4 py-4 font-semibold">{destination.location.code}</td>
              <td className="px-4 py-4">{destination.location.name}</td>
              <td className="px-4 py-4">CCS</td>
              <td className="px-4 py-4">
                {destination.account.listAccountPhone[0]?.phoneNumber}
              </td>
              <td className="px-4 py-4">{destination.businessUnit?.buCode}</td>
              <td className="px-4 py-4">{destination.account.accountFullName}</td>
              <td className="px-4">
                <Popover button={TableOptionsButton} containerClassname="!-mt-3">
                  <div className="flex flex-col font-semibold cursor-pointer">
                    <div
                      onClick={() => {
                        setSelected(destination);
                        setOpenDetailsModal(true);
                      }}
                      className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                    >
                      <div className="flex  gap-2 py-2 px-4 w-32">
                        <span>Detalles</span>
                      </div>
                    </div>

                    <hr />

                    <div
                      onClick={() => handleEdit(destination)}
                      className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                    >
                      <div className="flex gap-2 py-2 px-4 w-32">
                        <span>Editar</span>
                      </div>
                    </div>
                  </div>
                </Popover>
              </td>
            </tr>
          ))}

          {destinations.length == 0 && (
            <tr>
              <td colSpan={7} className="px-4 py-4 text-center">
                Parece que aún no tienes destinatarios.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {!!selected && (
        <DestinationDetailsModal
          destination={selected}
          open={openDetailsModal}
          setOpen={setOpenDetailsModal}
          onEdit={() => handleEdit(selected)}
        />
      )}
    </div>
  );
};
