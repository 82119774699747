import { FC, useState } from "react";
import { Modal } from "../Atoms/Modal";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { PreShipmentInterface } from "../../interfaces";
import { PrimaryButton } from "../Atoms/Buttons";
import { ConfirmModalStatus } from "../Preshipment/ModalSuccess";

interface ShipmentMessageModalProps {
  open: boolean;  
  status:ConfirmModalStatus,
  shipment: PreShipmentInterface;
  onPrint: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ShipmentMessageModal: FC<ShipmentMessageModalProps> = ({
  open,
  status,
  shipment,
  onPrint,
  setOpen,
}) => {
  return (
    <Modal
      open={open}
      setOpen={() => {}}
      className="flex flex-1 mx-4 my-8 "
      style={{ maxWidth: "25rem", height: "17rem"}}
    >
      <div className="flex flex-1 flex-col ">
        {/* Shipment Data */}
        {status == ConfirmModalStatus.ERROR && (
          <div className="flex flex-col items-center justify-center pb-10">
            <div className="flex flex-col items-center justify-center w-full">
              <XCircleIcon className="text-red-600 w-20 h-20 mt-6" />
            </div>
            <p className="mt-2 text-2xl font-medium text-gray-900 text-center">
              Error al procesar la guía
            </p>
            <p className="mt-2 text-gray-500 text-center">Intente de nuevo, si el problema persiste. Comunicarse con soporte tecnico</p>
            <div className="mt-4 flex flex-row justify-center gap-4">
              <PrimaryButton
                className="px-4"
                onClick={() => setOpen(false)}
              >
                Cerrar
              </PrimaryButton>
            </div>
          </div>
        )}

        {status == ConfirmModalStatus.SUCCESS && (
          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-col items-center justify-center w-full">
              <CheckCircleIcon className="text-green-600 w-28 h-28" />
            </div>
            <p className="mt-2 text-2xl font-medium text-gray-900 text-center">
            Guía Nº {shipment.shipmentNumber}. <br/>Generada con exitosamente
            </p>
            <div className="mt-4 flex flex-row justify-center gap-4">
            <PrimaryButton
                className="px-4"
                onClick={() =>{
                  onPrint();
                }}
              >
                Imprimir
              </PrimaryButton>
              <PrimaryButton
                className="px-4"
                onClick={() => {
                    setOpen(false);
                }}
              >
                Cerrar
              </PrimaryButton>
            </div>
            
            
          </div>
        )}
      </div>
      
      
    </Modal>
  );
};
