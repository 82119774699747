import axios from "axios";
import { GeneralAppDataDto, Nullable } from "../interfaces";
import { ListResponse } from "../interfaces/Shipment/ListResponseDto";
import { AuthenticationServices } from "./AuthenticationServices";

const {
    REACT_APP_URL_PATH_API,
  } = process.env;

export const getBaseAppData = async (
  countryID: Nullable<number>
): Promise<Promise<ListResponse<GeneralAppDataDto>>> => {
  let uri = `${REACT_APP_URL_PATH_API}/Application/Facturador`;
  if (!!countryID) {
    uri += `?countryID=${countryID}`;
  }
  try {
    const token = await AuthenticationServices();    
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

    const response = await axios.get(uri);

    if (response.status === 200) {
      return response.data;
    }
    return {
      model: null,
      didError: true,
      message: `Server status: ${response.status}`,
      errorMessage: "Server error",
    };
  } catch (error) {
    return {
      model: null,
      didError: true,
      message: `Error: ${error}`,
      errorMessage: "Application error",
    };
  }
};
